const { get } = require('../utils/api/api_caller_utils');

export const fetchReviews = async ({ companyId, page, appId }) => {
    try {
        const response = await get(
            `/companies/${companyId}/apps/${appId}/reviews/page/${page}`
        );
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching reviews', error);
    }
};

export const fetchReviewsAnalysis = async ({ companyId }) => {
    try {
        const response = await get(`/companies/${companyId}/review_analysis`);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching reviews analysis', error);
    }
};

export const fetchMAU = async ({ companyId }) => {
    try {
        const response = await get(`/companies/${companyId}/mau`);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching MAU', error);
    }
};

export const fetchDownloads = async ({ companyId }) => {
    try {
        const response = await get(`/companies/${companyId}/downloads`);
        return JSON.parse(response);
    } catch (error) {
        return []; // Workaround para quando não tem downloads
        // console.error('Error fetching downloads', error);
    }
};
