import { slugify } from '../../helpers';

const TableHeader = ({ children }) => {
    return (
        <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
            <a href="#" className="group inline-flex">
                {children}
            </a>
        </th>
    );
};

const TableRowDefault = ({ data }) => {
    return (
        <tr className="cursor-pointer even:bg-gray-50 hover:bg-gray-100">
            {data.map((d, index) => (
                <td
                    key={`${slugify(data[0])}-${index}-column`}
                    className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 "
                >
                    {d}
                </td>
            ))}
        </tr>
    );
};

export default function ComparisonTable({ data }) {
    if (!data.length) return null;

    // Data example:
    // [
    //     {
    //         "dimension": "Nota app Android",
    //         "Santander": 83,
    //         "BBVA": 82,
    //         "Caixa": 18
    //     },
    //     {
    //         "dimension": "Nota app iOS",
    //         "Santander": 76,
    //         "BBVA": 28,
    //         "Caixa": 39
    //     }
    // ]
    const columnHeaders = data.map((d) => d.dimension);
    const rowHeaders = Object.keys(data[0]).filter((d) => d !== 'dimension');

    const rows = rowHeaders.map((r) => {
        return [
            r,
            ...columnHeaders.map((c) => {
                return data.find((d) => d.dimension === c)[r];
            })
        ];
    });

    return (
        <div className="p-10">
            <table className="w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                        <TableHeader>Instituição</TableHeader>
                        {columnHeaders.map((h) => (
                            <TableHeader key={slugify(h)}>{h}</TableHeader>
                        ))}
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {rows.map((r, index) => {
                        return (
                            <TableRowDefault key={`row-${index}`} data={r} />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
