/* eslint-disable camelcase */
import { XMarkIcon } from '@heroicons/react/20/solid';
import { signal } from '@preact/signals-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Notification } from '..';
import {
    emailRegex,
    getDomainName,
    isValidCorporateEmail,
    isValidEmail,
    isValidUrl
} from '../../helpers';
import { AuthService, CRMService } from '../../services';
import { autocompleteList } from '../../signals';

const showNotification = signal(false);
const userCompany = signal({});

const inputStyleValid =
    'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-idwallDarkPurple-600 sm:text-sm sm:leading-6';

const inputStyleInvalid =
    'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-red-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6';

const LabelInfo = ({ children }) => {
    return (
        <span className="text-gray-400 text-xs italic pt-1 float-right">
            {children}
        </span>
    );
};

export default function SignUpForm() {
    const navigate = useNavigate();
    const {
        register,
        clearErrors,
        setFocus,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm();

    const [step, setStep] = useState(1);

    const roleOptions = [
        'Estagiário(a) ou Aluno(a)',
        'Assistente ou Auxiliar',
        'Autônomo',
        'Analista',
        'Técnico(a) ou Especialista',
        'Coordenador(a)',
        'Gerente',
        'Diretor(a)',
        'Sócio(a) ou C-Level',
        'Fundador(a) ou CEO'
    ];

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const meuidEmail = urlParams.get('meuid_email');
        const phoneNumber = urlParams.get('meuid_telefone');
        setValue('meuid_email', meuidEmail);
        setValue('meuid_telefone', phoneNumber);
    }, []);

    const getCompanyNameFromEmail = (email) => {
        const domain = email.split('@')[1];
        return domain.split('.')[0];
    };

    const findCompanyInAutocompleteList = (companyName) => {
        return autocompleteList.value.find((company) => {
            if (!isValidUrl(company.url)) {
                return false;
            }
            const domain = getDomainName(company.url).toLowerCase();
            const name = company.name.toLowerCase().replace(/\s/g, '');
            return (
                name === companyName.toLowerCase() ||
                domain === companyName.toLowerCase()
            );
        });
    };

    const findCompanyOnIndex = (event) => {
        const name = event.target.name;
        let companyName = '';

        if (name === 'email') {
            const email = getValues('email');
            if (!isValidEmail(email)) return;
            companyName = getCompanyNameFromEmail(email);
        } else if (name === 'company') {
            companyName = getValues('company');
        } else {
            return;
        }

        const company = findCompanyInAutocompleteList(companyName);

        if (company) {
            userCompany.value = company;
            setValue('companyId', company.companyId);
            setValue('company', company.name);
        } else {
            userCompany.value = {};
            setValue('companyId', '');
            setValue(
                'company',
                companyName.charAt(0).toUpperCase() + companyName.slice(1)
            );
        }
        clearErrors('company');
    };

    const removeCompany = () => {
        userCompany.value = {};
        setValue('companyId', '');
        setFocus('company');
    };

    const onSubmit = async (data) => {
        showNotification.value = false;

        try {
            const {
                firstName,
                lastName,
                role,
                email,
                company,
                companyId,
                meuid_email,
                meuid_telefone
            } = data;

            await AuthService.signup({
                email,
                meuid_email,
                meuid_telefone,
                firstName,
                lastName,
                role,
                company,
                companyId
            });

            await CRMService.registerCRMLead({
                first_name: firstName,
                last_name: lastName,
                mobile: meuid_telefone,
                role,
                email,
                company
            });

            navigate('/login/email-enviado?email=' + email, { replace: true });
        } catch (error) {
            showNotification.value = true;
        }
    };

    const nextStep = () => {
        if (step < 3) {
            setStep(step + 1);
        } else {
            handleSubmit(onSubmit)();
        }
    };

    const prevStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <div>
                        <label className={`block text-sm font-medium leading-6 ${errors.email ? 'text-red-500' : 'text-gray-900'}`} htmlFor="email">
                            Email corporativo
                            {errors.email && <LabelInfo>O seu email corporativo é obrigatório.</LabelInfo>}
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                type="email"
                                autoComplete="email"
                                placeholder="nome@empresa.com.br"
                                className={errors.email ? inputStyleInvalid : inputStyleValid}
                                {...register('email', {
                                    required: true,
                                    validate: isValidCorporateEmail,
                                    onChange: findCompanyOnIndex,
                                    pattern: emailRegex
                                })}
                            />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div>
                            <label className={`block text-sm font-medium leading-6 ${errors.firstName || errors.lastName ? 'text-red-500' : 'text-gray-900'}`}>
                                Nome
                                {(errors.firstName || errors.lastName) && <LabelInfo>Por favor, preencha seu nome.</LabelInfo>}
                            </label>
                            <div className="mt-2 mb-2">
                                <div className="relative -space-y-px rounded-md shadow-sm">
                                    <div>
                                        <input
                                            id="firstName"
                                            type="text"
                                            className={`relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${errors.firstName ? 'focus:ring-red-600' : 'focus:ring-idwallDarkPurple-600'}`}
                                            placeholder="Nome"
                                            {...register('firstName', { required: true })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                        <label className={`block text-sm font-medium leading-6 ${errors.firstName || errors.lastName ? 'text-red-500' : 'text-gray-900'}`}>
                                Sobrenome
                                {(errors.firstName || errors.lastName) && <LabelInfo>Por favor, preencha seu sobrenome.</LabelInfo>}
                            </label>
                            <div className="mt-2 mb-2">
                                <div className="relative -space-y-px rounded-md shadow-sm">
                                    <div>
                                        <input
                                            id="lastName"
                                            type="text"
                                            className={`relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${errors.lastName ? 'focus:ring-red-600' : 'focus:ring-idwallDarkPurple-600'}`}
                                            placeholder="Sobrenome"
                                            {...register('lastName', { required: true })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <div>
                            <label className={`block text-sm font-medium leading-6 ${errors.company ? 'text-red-500' : 'text-gray-900'}`} htmlFor="company">
                                Empresa
                                {errors.company && <LabelInfo>O nome da sua empresa é obrigatório.</LabelInfo>}
                                {!errors.company && userCompany.value.companyId && <LabelInfo>Sua empresa está no Index!</LabelInfo>}
                            </label>
                            <div className="mt-2 mb-2">
                                <input
                                    id="company"
                                    autoComplete="organization"
                                    type={userCompany.value.companyId ? 'hidden' : 'text'}
                                    placeholder="Nome da empresa"
                                    className={errors.company ? inputStyleInvalid : inputStyleValid}
                                    {...register('company', {
                                        required: true,
                                        onBlur: findCompanyOnIndex
                                    })}
                                />
                                {userCompany.value.companyId && (
                                    <div
                                        className={`rounded-md border border-gray-300 bg-gray-100 p-4 shadow-sm ${errors.company ? 'text-red-500' : 'text-gray-900'}`}
                                    >
                                        <div className="flex justify-between">
                                            <p>{userCompany.value.name}</p>
                                            <button
                                                type="button"
                                                className="text-red-600"
                                                onClick={removeCompany}
                                            >
                                                <XMarkIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <input
                                type="hidden"
                                {...register('companyId')}
                            />
                        </div>
                        <div>
                            <label className={`block text-sm font-medium leading-6 ${errors.role ? 'text-red-500' : 'text-gray-900'}`} htmlFor="role">
                                Cargo
                                {errors.role && <LabelInfo>Selecione o seu cargo.</LabelInfo>}
                            </label>
                            <div className="mt-2">
                                <select
                                    id="role"
                                    className={errors.role ? inputStyleInvalid : inputStyleValid}
                                    {...register('role', { required: true })}
                                >
                                    <option value="">Selecione o seu cargo</option>
                                    {roleOptions.map((role) => (
                                        <option key={role} value={role}>
                                            {role}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderProgressBar = () => {
        const progressBarWidth = `${(step / 3) * 100}%`;

        return (
            <div className="relative h-1 w-full bg-gray-200 mt-4 mb-6">
                <div
                    className="absolute top-0 h-full bg-idwallDarkPurple-600"
                    style={{ width: progressBarWidth }}
                />
            </div>
        );
    };

    return (
        <div className="space-y-6 sm:px-6 lg:px-0 py-10">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                {renderStepContent()}
                <div className="flex flex-col space-y-4">
                    {step > 1 && (
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-idwallDarkPurple-500 bg-transparent py-2 px-4 text-sm font-medium text-idwallDarkPurple-500 shadow-sm hover:bg-idwallDarkPurple-50 focus:outline-none focus:ring-2 focus:ring-idwallDarkPurple-500 focus:ring-offset-2"
                            onClick={prevStep}
                        >
                            Voltar
                        </button>
                    )}
                    <button
                        type="button"
                        className='text-md inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 font-semibold shadow-sm justify-center w-full mt-3 bg-idwallDarkPurple-500 text-white hover:bg-idwallDarkPurple-600 disabled:bg-idwallDarkPurple-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-idwallDarkPurple-500 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50'
                        onClick={nextStep}
                    >
                        {step === 3 ? 'Criar minha conta' : 'Próximo'}
                    </button>
                </div>
            </form>
            {showNotification.value && (
                <Notification
                    variant="error"
                    title="Algo deu errado"
                    description="Ocorreu um erro ao criar sua conta. Por favor, tente novamente."
                />
            )}
            {renderProgressBar()}
        </div>
    );
}
