import CookieConsent from 'react-cookie-consent';
import { primaryColor } from '../../styles';

export default function Cookie() {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Prosseguir"
            style={{ background: 'rgba(0, 0, 0, 0.8)', fontSize: '13px' }}
            buttonStyle={{ background: primaryColor, color: 'white' }}
        >
            Nós utilizamos cookies para melhorar a sua experiência de navegação.
            Confira nossos{' '}
            <a href="/termos-de-uso" target="_blank">
                <span style={{ fontWeight: 600 }}>Termos de Uso</span>
            </a>
            e{' '}
            <a
                href="https://idwall.co/pt-BR/politica-de-privacidade/"
                target="_blank"
                rel="noreferrer"
            >
                <span style={{ fontWeight: 600 }}>Política de Privacidade</span>
            </a>{' '}
            para saber mais.
        </CookieConsent>
    );
}
