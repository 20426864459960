/* eslint-disable camelcase */
import { user } from '../signals';
const { post, get } = require('../utils/api/api_caller_utils');

export const linkedInGetCredentials = async (code) => {
    try {
        const response = await get(`/auth/linkedin?code=${code}`);
        return JSON.parse(response);
    } catch (e) {
        throw e;
    }
};

export const login = async (email, password) => {
    const data = {
        user: {
            email,
            password
        }
    };
    try {
        const response = await post('/users/login', data);
        const { loggedUser } = JSON.parse(response);
        return loggedUser;
    } catch (e) {
        throw e;
    }
};

export const loginMagic = async (token) => {
    try {
        const response = await post('/users/validate-magic-link/' + token);
        const { loggedUser } = JSON.parse(response);
        return loggedUser;
    } catch (e) {
        throw e;
    }
};

export const sendMagicLink = async (email) => {
    try {
        const reponse = await post('/users/send-magic-link/' + email);
        return JSON.parse(reponse);
    } catch (e) {
        throw e;
    }
};

export const signup = async ({
    email,
    meuid_email,
    meuid_telefone,
    firstName,
    lastName,
    role,
    company,
    companyId
}) => {
    const data = {
        user: {
            email,
            meuid_email,
            meuid_telefone,
            firstName,
            lastName,
            role,
            company,
            companyId
        }
    };
    try {
        const response = await post('/users/signup', data);
        const { loggedUser } = JSON.parse(response);
        return loggedUser;
    } catch (error) {
        throw error;
    }
};

export const logout = async () => {
    try {
        await post('/users/logout');
        localStorage.removeItem('user');
        user.value = {};
    } catch (error) {
        throw error;
    }
};
