export const slugify = (text) => {
    return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

export const preetyAppType = (type) => {
    if (type === 'android') {
        return 'Android Google Play';
    } else if (type === 'ios') {
        return 'iOS App Store';
    }
    return type;
};

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const isValidEmail = (email) => {
    return emailRegex.test(email);
};

export const isValidCorporateEmail = (email) => {
    if (!isValidEmail(email)) return false;

    const freeEmailDomains = [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'aol.com',
        'outlook.com',
        'live.com',
        'msn.com',
        'icloud.com',
        'mail.com',
        'zoho.com',
        'yandex.com',
        'protonmail.com',
        'gmx.com',
        'gmx.net',
        'gmx.us',
        'yahoo.co.uk',
        'yahoo.co.jp',
        'yahoo.in',
        'yahoo.fr',
        'bol.com.br',
        'uol.com.br',
        'terra.com.br',
        'rediffmail.com',
        'mail.ru',
        'web.de',
        'inbox.com',
        'mailinator.com',
        'me.com',
        'lycos.com',
        'excite.com',
        'fastmail.com',
        'hushmail.com',
        'yandex.ru',
        'yandex.ua',
        'qq.com',
        '126.com',
        '163.com',
        'sina.com',
        'sohu.com',
        'yeah.net',
        'tutanota.com',
        'tutanota.de',
        'tutamail.com',
        'tuta.io',
        'keemail.me',
        'nate.com',
        'daum.net',
        'hanmail.net',
        'naver.com'
    ];

    const domain = email.split('@')[1];
    return !freeEmailDomains.includes(domain);
};

export const isValidUrl = (urlString) => {
    try {
        // eslint-disable-next-line no-unused-vars
        const url = new URL(urlString);
        return true;
    } catch (error) {
        return false;
    }
};

export const getDomainName = (url) => {
    const parsedUrl = new URL(url);
    let hostname = parsedUrl.hostname;
    hostname = hostname.replace(/^www\./, '');
    const parts = hostname.split('.');
    return parts[0];
};

export const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
};

export const randomScore = () => {
    return Math.floor(Math.random() * 100);
};

export const randomScreenshotId = () => {
    return Math.floor(Math.random() * 43);
};

export const randomRating = () => {
    return Math.floor(Math.random() * 50) / 10;
};

export const normalizeString = (str) => {
    const map = {
        á: 'a',
        à: 'a',
        ã: 'a',
        â: 'a',
        é: 'e',
        ê: 'e',
        í: 'i',
        ó: 'o',
        ô: 'o',
        õ: 'o',
        ú: 'u',
        ç: 'c',
        Á: 'A',
        À: 'A',
        Ã: 'A',
        Â: 'A',
        É: 'E',
        Ê: 'E',
        Í: 'I',
        Ó: 'O',
        Ô: 'O',
        Õ: 'O',
        Ú: 'U',
        Ç: 'C'
    };

    return str
        .split('')
        .map((letter) => map[letter] || letter)
        .join('')
        .toLowerCase();
};
