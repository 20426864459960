import { classNames } from '../helpers';
import { IoStar, IoStarOutline, IoStarHalfOutline } from 'react-icons/io5';

export default function Rating({ value, size = 5, className }) {
    const sizeStyle = (sizeValue) => ({
        width: `${sizeValue * 0.25}rem`,
        height: `${sizeValue * 0.25}rem`
    });

    return (
        <div className={classNames('flex items-center', className)}>
            {[0, 1, 2, 3, 4].map((index) => {
                let star;
                if (value >= index + 1) {
                    star = (
                        <IoStar
                            className="text-yellow-400 flex-shrink-0"
                            style={sizeStyle(size)}
                            aria-hidden="true"
                        />
                    );
                } else if (value >= index + 0.5) {
                    star = (
                        <IoStarHalfOutline
                            className="text-yellow-400 flex-shrink-0"
                            style={sizeStyle(size)}
                            aria-hidden="true"
                        />
                    );
                } else {
                    star = (
                        <IoStarOutline
                            className="text-gray-300 flex-shrink-0"
                            style={sizeStyle(size)}
                            aria-hidden="true"
                        />
                    );
                }
                return <span key={index}>{star}</span>;
            })}
        </div>
    );
}
