import { Rating } from '../../../components';

export default function ReviewsList({ reviews }) {
    return (
        <div className="divide-y divide-gray-200">
            {reviews.map((review, index) => (
                <div key={index} className="py-6 space-y-4">
                    <Rating value={review.rating} />
                    <div>{review.content}</div>
                </div>
            ))}
        </div>
    );
}
