import { ResponsiveBar } from '@nivo/bar';

export default function ComparisonChartBar({
    chartTheme,
    data,
    keys,
    layout = 'vertical'
}) {
    return (
        <ResponsiveBar
            data={data}
            theme={chartTheme}
            keys={keys} // keys agora são as empresas selecionadas
            indexBy="dimension" // indexBy é ajustado para 'dimension'
            layout={layout}
            margin={{ top: 30, right: 150, bottom: 30, left: 50 }}
            padding={0.3}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in dimension: ${e.indexValue}`
            }
        />
    );
}
