import { ResponsiveCalendar } from '@nivo/calendar';

const mockData = require('./changelog_sample.json')[0].changelogs;

const data = mockData.map((changelog) => ({
    day: changelog.date.substring(0, 10),
    value: 1,
    description: changelog.value
}));

const minDate = new Date(
    Math.min.apply(
        null,
        data.map((d) => new Date(d.day))
    )
);
const maxDate = new Date(
    Math.max.apply(
        null,
        data.map((d) => new Date(d.day))
    )
);

const minDateFirstDayOfMonth = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
const maxDateLastDayOfMonth = new Date(maxDate.getFullYear(), maxDate.getMonth() + 1, 0);

function getDescriptionByDay(day) {
    const changelog = data.find((c) => c.day === day);
    return changelog ? changelog.description : '';
}

export default function ReviewsChangelogChart() {
    return (
        <ResponsiveCalendar
            data={data}
            from={minDateFirstDayOfMonth}
            to={maxDateLastDayOfMonth}
            emptyColor="#eeeeee"
            colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
            yearSpacing={40}
            monthBorderColor="#ffffff"
            dayBorderWidth={2}
            dayBorderColor="#ffffff"
            tooltip={(n) => {
                return getDescriptionByDay(n.day);
            }}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left'
                }
            ]}
        />
    );
}
