import React from 'react';
import { getChartColors } from '../../styles';
import { ResponsiveRadar } from '@nivo/radar';
import { randomScore } from '../../helpers';

const keys = ['Tempo', 'Facilidade', 'Acessibilidade', 'Segurança'];

const ExperienceRadar = ({ data }) => (
    <ResponsiveRadar
        data={data}
        keys={keys}
        indexBy="journey"
        valueFormat=">-.2f"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        borderColor={{ from: 'color' }}
        gridLabelOffset={36}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        colors={getChartColors(keys.length)}
        // blendMode="multiply" // Bugado
        motionConfig="wobbly"
        legends={[
            {
                anchor: 'top-left',
                direction: 'column',
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: '#999',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
);

export default function Report() {
    const data = {
        experience: ['Tempo', 'Facilidade', 'Acessibilidade', 'Segurança'].map(
            (journey) => ({
                journey,
                Tempo: randomScore() / 10,
                Facilidade: randomScore() / 10,
                Acessibilidade: randomScore() / 10,
                Segurança: randomScore() / 10
            })
        )
    };

    return (
        <main>
            <div className="mx-auto py-8 px-4 sm:px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">
                                Experiência
                            </h3>
                        </div>
                        <div style={{ height: 'calc(100vh - 300px)' }}>
                            <ExperienceRadar data={data.experience} />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
