import { useEffect, useCallback } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { Company, Layout, CTA } from '..';
import { CompanyService } from '../../services';
import { currentCompany } from '../../signals';

export default function CompanyLayout() {
    const { companySlug, appId } = useParams();

    const loadCompanyDetails = useCallback(async () => {
        try {
            currentCompany.value =
                await CompanyService.fetchCompanyDetailsByName(companySlug);
        } catch (error) {
            console.error('Failed to load company details:', error);
        }
    }, [companySlug]);

    useEffect(() => {
        loadCompanyDetails();
    }, [companySlug]);

    return (
        <>
            <CTA.ComparisonModal />
            <CTA.JoinRanking />
            <Layout.Head title={currentCompany.value.name} />
            <div className="min-h-full">
                <Company.Header />
                <main className="pb-16">
                    <div className="bg-gray-50">
                        <div className="mx-auto max-w-7xl ">
                            <Company.Tabs
                                company={currentCompany.value}
                                appId={appId}
                            />
                        </div>
                    </div>
                    <Outlet />
                </main>
            </div>
        </>
    );
}
