import { useEffect, useCallback } from 'react';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Table from './Table';
import { UI } from '../../components';

const list = signal([]);

export default function AdminCompanyList() {
    pageTitle.value = 'Empresas';

    const fetchData = useCallback(async () => {
        try {
            list.value = await AdminService.getCompanies();
            console.log('Companies list:', list.value);
        } catch (error) {
            console.error('Failed to load companies list:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const headers = [
        'ID',
        'Nome',
        'Código',
        'Tipo',
        'Visivel',
        'No Ranking',
        ''
    ];

    const rows = list.value.map((item, index) => [
        item.companyId,
        item.name,
        item.slug,
        item.type,
        item.isVisible ? 'Sim' : 'Não',
        item.verified ? 'Sim' : 'Não',
        <UI.Button
            as="a"
            key={index}
            href={`/admin/companies/${item.companyId}`}
            variant="link"
            className="text-white hover:text-white"
        >
            Editar
        </UI.Button>
    ]);

    return <Table headers={headers} rows={rows} />;
}
