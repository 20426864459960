import React from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../../helpers';
import { BadgeRanking } from '../../components';

export default function CompanyTabs({ company, page, appId }) {
    const { slug } = company;
    const pathname = window.location.pathname;

    const tabs = [
        {
            name: 'Visão Geral',
            href: `/${slug}/sobre`,
            current: pathname.endsWith('/sobre') || pathname === `/${slug}`,
            rankingExclusive: false
        },
        {
            name: 'Fluxos',
            href: `/${slug}/fluxos`,
            current: pathname.endsWith('/fluxos'),
            rankingExclusive: true
        },
        {
            name: 'Comparativo',
            href: `/${slug}/comparativo`,
            current: pathname.endsWith('/comparativo'),
            rankingExclusive: true
        },
        {
            name: 'Bacen',
            href: `/${slug}/bacen`,
            current: pathname.endsWith('/bacen'),
            rankingExclusive: false
        }
    ];

    if (company.apps.length) {
        tabs.push({
            name: 'Apps',
            href: appId
                ? `/${slug}/avaliacoes/${appId}`
                : `/${slug}/avaliacoes`,
            current: pathname.includes('/avaliacoes'),
            rankingExclusive: false
        });
    }

    return (
        <div className="bg-gray-50">
            <div className="border-b border-gray-200">
                <nav className="-mb-px flex pl-8" aria-label="Tabs">
                    {tabs
                        .filter((t) => !t.rankingExclusive || company.verified)
                        .filter(
                            (t) =>
                                !(t.name === 'Bacen' && !company.bcbInstitution)
                        )
                        .map((tab) => (
                            <Link
                                key={tab.name}
                                to={tab.href}
                                className={classNames(
                                    tab.current
                                        ? 'border-idwallDarkPurple-500 text-idwallDarkPurple-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'group inline-flex items-center border-b-4 py-4 px-6 text-sm font-medium'
                                )}
                                aria-current={tab.current ? 'page' : undefined}
                            >
                                <span>{tab.name}</span>
                                {tab.rankingExclusive && (
                                    <BadgeRanking size={5} />
                                )}
                            </Link>
                        ))}
                </nav>
            </div>
        </div>
    );
}
