import { UI } from '..';

export default function CTAReport() {
    return (
        <div className="overflow-hidden py-20">
            <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                    <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                        <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                            Ranking de Experiência 2024
                        </h2>
                        <p className="mt-6 text-xl leading-8 text-gray-600">
                            Descubra quais aplicativos bancários proporcionam a
                            melhor experiência de usuário em nosso relatório
                            anual. A edição de 2024 do Ranking de Experiência
                            Idwall está aqui para orientar suas escolhas com
                            insights e análises detalhadas.
                        </p>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Com base em critérios rigorosos e feedbacks
                            autênticos dos usuários, oferecemos uma visão clara
                            de quais plataformas se destacam em facilidade de
                            uso, segurança e inovação. Não perca as últimas
                            tendências do mercado financeiro digital.
                        </p>
                        <div className="mt-10 flex">
                            <UI.Button as="a" href="/2024">
                                Acesse o Relatório{' '}
                                <span aria-hidden="true">&rarr;</span>
                            </UI.Button>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                        <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                            <img
                                src="/img/ranking-experiencia-2024-promo.png"
                                alt="Capa do Relatório de Experiência 2024"
                                className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
