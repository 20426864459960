import React, { useState } from 'react';
import { UploadService } from '../../services';

const FileUpload = () => {
    const [file, setFile] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await UploadService.uploadFile(formData);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            <input
                type="file"
                onChange={(event) => setFile(event.target.files[0])}
            />
            <button type="submit">Upload</button>
        </form>
    );
};

export default FileUpload;
