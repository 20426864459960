import { Layout } from '..';

const products = [
    {
        id: 1,
        name: 'Relatório completo',
        description: 'Relatório completo de experiência do cliente idwall.',
        href: '#',
        quantity: 1,
        price: 'R$ XXXX,XX',
        imageSrc: '/img/cta-report.jpg',
        imageAlt: 'Relatório completo'
    }
];

export default function Success() {
    // Get all querystring parameters from the URL
    const params = new URLSearchParams(window.location.search);
    const paymentIntentId = params.get('payment_intent');
    const paymentStatus = params.get('redirect_status');
    const paymentClientSecret = params.get('payment_intent_client_secret');

    return (
        <>
            <Layout.Head title="Index - Compra realizada com sucesso!" />
            <div className="bg-white">
                <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                    <div className="max-w-xl">
                        <h1 className="text-base font-medium text-idwallDarkPurple-600">
                            Parabéns!
                        </h1>
                        <p className="mt-2 text-4xl font-semibold tracking-tight sm:text-5xl">
                            Compra realizada com sucesso!
                        </p>
                        <p className="mt-2 text-base text-gray-500">
                            Acesse agora o Relatório Completo.
                        </p>

                        <dl className="mt-12 text-sm font-medium">
                            <dt className="text-gray-900">Código da compra</dt>
                            <dd className="mt-2 text-idwallDarkPurple-600">
                                51547878755545848512
                            </dd>
                        </dl>
                    </div>

                    <div className="mt-10 border-t border-gray-200">
                        <h2 className="sr-only">Your order</h2>

                        <h3 className="sr-only">Items</h3>
                        {products.map((product) => (
                            <div
                                key={product.id}
                                className="flex space-x-6 border-b border-gray-200 py-10"
                            >
                                <img
                                    src={product.imageSrc}
                                    alt={product.imageAlt}
                                    className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
                                />
                                <div className="flex flex-auto flex-col">
                                    <div>
                                        <h4 className="font-medium text-gray-900">
                                            <a href={product.href}>
                                                {product.name}
                                            </a>
                                        </h4>
                                        <p className="mt-2 text-sm text-gray-600">
                                            {product.description}
                                        </p>
                                    </div>
                                    <div className="mt-6 flex flex-1 items-end">
                                        <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                                            <div className="flex">
                                                <dt className="font-medium text-gray-900">
                                                    Quantidade
                                                </dt>
                                                <dd className="ml-2 text-gray-700">
                                                    {product.quantity}
                                                </dd>
                                            </div>
                                            <div className="flex pl-4 sm:pl-6">
                                                <dt className="font-medium text-gray-900">
                                                    Valor
                                                </dt>
                                                <dd className="ml-2 text-gray-700">
                                                    {product.price}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="sm:ml-40 sm:pl-6">
                            <h4 className="sr-only">Pagamento</h4>
                            <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
                                <div>
                                    <dt className="font-medium text-gray-900">
                                        Método de pagamento
                                    </dt>
                                    <dd className="mt-2 text-gray-700">
                                        <p>Apple Pay</p>
                                        <p>Mastercard</p>
                                        <p>
                                            <span aria-hidden="true">••••</span>
                                            <span className="sr-only">
                                                Ending in{' '}
                                            </span>
                                            1545
                                        </p>
                                    </dd>
                                </div>
                                <div></div>
                            </dl>

                            <dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
                                <div className="flex justify-between">
                                    <dt className="font-medium text-gray-900">
                                        Subtotal
                                    </dt>
                                    <dd className="text-gray-700">
                                        R$ XXXX,XX
                                    </dd>
                                </div>
                                <div className="flex justify-between">
                                    <dt className="flex font-medium text-gray-900">
                                        Cupom de desconto
                                        <span className="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-600">
                                            IDWALL10
                                        </span>
                                    </dt>
                                    <dd className="text-gray-700">
                                        -R$XX,XX (XX%)
                                    </dd>
                                </div>
                                <div className="flex justify-between">
                                    <dt className="font-medium text-gray-900">
                                        Total
                                    </dt>
                                    <dd className="text-gray-900">R$XXXX,XX</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <div className="bg-orange-300">
                    <pre>payment_intent</pre>
                    <pre>{paymentIntentId}</pre>
                    <pre>redirect_status</pre>
                    <pre>{paymentStatus}</pre>
                    <pre>payment_intent_client_secret</pre>
                    <pre>{paymentClientSecret}</pre>
                </div>
            </div>
        </>
    );
}
