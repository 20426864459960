import { UI } from '../../../components';
import { slugify } from '../../../helpers';

export default function FlowsTabs({ journeys }) {
    return (
        <div className="mx-auto sticky top-0 z-10">
            <div className="bg-gray-200">
                <div className="mx-auto max-w-7xl overflow-x-auto p-5">
                    <nav className="flex" aria-label="Tabs">
                        {journeys.map((journey) => (
                            <a
                                key={journey.expJourneyId}
                                href={`#${slugify(journey.name)}`}
                                className="text-gray-600 hover:bg-gray-300 flex space-x-4 rounded-md py-4 px-6 text-sm font-medium items-center" // Added items-center class
                            >
                                {journey.screenshots.length > 0 ? (
                                    <img
                                        src={journey.screenshots[0].url}
                                        alt=""
                                        className="h-12 w-auto"
                                    />
                                ) : (
                                    <img
                                        src="/img/screenshot-placeholder.png"
                                        alt=""
                                        className="h-12 w-auto"
                                    />
                                )}

                                <UI.H2 className="inline-block align-middle whitespace-nowrap">
                                    {journey.name}
                                </UI.H2>
                            </a>
                        ))}
                        {journeys.length === 0 && (
                            <div className="py-4">&nbsp;</div>
                        )}
                    </nav>
                </div>
            </div>
        </div>
    );
}
