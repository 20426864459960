import React, { useEffect, useCallback } from 'react';
import {
    currentCompany,
    modalCTAReport,
    currentReport,
    user
} from '../../../signals';
import { getCompanyColor, primaryColor } from '../../../styles';
import { ReportService } from '../../../services';
import { randomRating } from '../../../helpers';
import { UI, Layout, Feedback } from '../../../components';
import companyCategories from '../../../data/company-category';

const showDetails = () => {
    modalCTAReport.value = true;
};

function StatsBox({ stat, blur = false }) {
    const statMock = {
        name: stat.name,
        value: randomRating()
    };

    if (blur) {
        return (
            <div className="group flex flex-col bg-gray-200 py-5">
                <dt className="text-sm font-semibold leading-6 text-gray-600 group-hover:hidden blur-sm line-clamp-2">
                    {statMock.name}
                </dt>
                <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900 group-hover:hidden blur-md line-clamp-2">
                    {statMock.value}
                </dd>
                <dd className="hidden order-first tracking-tight text-gray-900 group-hover:block">
                    <UI.Button
                        variant="white"
                        size="xs"
                        className="mt-5"
                        onClick={showDetails}
                    >
                        Ver
                        <br />
                        informação
                    </UI.Button>
                </dd>
            </div>
        );
    }
    return (
        <div className="flex flex-col bg-gray-200 py-5">
            <dt className="text-sm font-semibold text-gray-600 line-clamp-3">
                {stat.name}
            </dt>
            <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900 line-clamp-2">
                {stat.value}
            </dd>
        </div>
    );
}

function TitleBox({ title, bgColor = primaryColor }) {
    return (
        <div
            key="title"
            className="flex flex-col justify-center bg-idwallDarkPurple py-5"
            style={{ backgroundColor: bgColor }}
        >
            <dt className="order-first text-lg font-semibold tracking-tight text-white text-center">
                {title}
            </dt>
        </div>
    );
}

export default function Comparison() {
    const company = currentCompany.value;
    const { companyId } = company;

    const loadCompanyDetails = useCallback(async () => {
        try {
            if (companyId) {
                currentReport.value = await ReportService.getReport(companyId);
            }
        } catch (error) {
            console.error('Failed to load company details:', error);
        }
    }, [companyId]);

    useEffect(() => {
        loadCompanyDetails();
    }, [companyId, loadCompanyDetails]);

    const report = currentReport.value;

    if (!report) {
        return null;
    }

    const reportData = report.data.indicators;

    const { wordCloud } = report.data.feedback;
    const { isLoggedIn } = user.value;
    const companyCategory = companyCategories.data[companyId];

    // Função para ordenar os stats na ordem desejada
    const orderStats = (stats) => {
        const order = [
            'Tempo de Onboarding',
            'Percepção positiva sobre a instituição',
            'Confiança',
            'Percepção de segurança',
            'Nota Geral'
        ];

        return stats.sort((a, b) => {
            return order.indexOf(a.name) - order.indexOf(b.name);
        });
    };

    // Ordena os stats do relatório atual
    const orderedStats = orderStats(reportData.stats);

    const averageDigitalBanks = orderStats([
        { id: 432, name: 'Tempo de Onboarding', value: '05:54' },
        {
            id: 433,
            name: 'Percepção positiva sobre a instituição',
            value: '31,01%'
        },
        { id: 434, name: 'Confiança', value: '22,51%' },
        { id: 435, name: 'Percepção de segurança', value: '89,14%' },
        { id: 436, name: 'Nota Geral', value: '6,94' }
    ]);

    const averageTraditionalBanks = orderStats([
        { id: 437, name: 'Tempo de Onboarding', value: '07:21' },
        {
            id: 438,
            name: 'Percepção positiva sobre a instituição',
            value: '40,34%'
        },
        { id: 439, name: 'Confiança', value: '35,94%' },
        { id: 440, name: 'Percepção de segurança', value: '89,35%' },
        { id: 441, name: 'Nota Geral', value: '6,71' }
    ]);

    const averageInvestmentBanks = orderStats([
        { id: 447, name: 'Tempo de Onboarding', value: '06:24' },
        {
            id: 448,
            name: 'Percepção positiva sobre a instituição',
            value: '15,80%'
        },
        { id: 449, name: 'Confiança', value: '18,66%' },
        { id: 450, name: 'Percepção de segurança', value: '81,65%' },
        { id: 451, name: 'Nota Geral', value: '6,69' }
    ]);

    const averageAll = orderStats([
        { id: 442, name: 'Tempo de Onboarding', value: '06:25' },
        {
            id: 443,
            name: 'Percepção positiva sobre a instituição',
            value: '31,54%'
        },
        { id: 444, name: 'Confiança', value: '26,05%' },
        { id: 445, name: 'Percepção de segurança', value: '88,05%' },
        { id: 446, name: 'Nota Geral', value: '6,83' }
    ]);

    return (
        <>
            <Layout.Head title={`Index - ${company.name}: Comparativo`} />

            <main className="isolate">
                <div className="mx-auto max-w-7xl px-6 sm:mt-0 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none my-20">
                        <div className="mt-6 flex flex-col gap-x-16 gap-y-20 lg:flex-row">
                            <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                                <h2 className="text-3xl font-semibold tracking-tight text-gray-900">
                                    {`${company.name} em relação ao mercado`}
                                </h2>
                                <p className="mt-2 text-lg text-gray-600">
                                    Comparativo dos indicadores
                                </p>

                                <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-6">
                                    <TitleBox
                                        title={company.name}
                                        bgColor={getCompanyColor(company.name, [
                                            '#1D2530' // bg-gray-800
                                        ])}
                                    />
                                    {orderedStats.map((stat, index) => (
                                        <StatsBox
                                            key={stat.id}
                                            stat={stat}
                                            blur={index !== 0 && !isLoggedIn}
                                        />
                                    ))}
                                </dl>
                                {companyCategory === 'Digital' && (
                                    <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-6">
                                        <TitleBox title="Bancos Digitais" />
                                        {averageDigitalBanks.map(
                                            (stat, index) => (
                                                <StatsBox
                                                    key={stat.id}
                                                    stat={stat}
                                                    blur={
                                                        index !== 0 &&
                                                        !isLoggedIn
                                                    }
                                                />
                                            )
                                        )}
                                    </dl>
                                )}
                                {companyCategory === 'Tradicional' && (
                                    <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-6">
                                        <TitleBox title="Bancos Tradicionais" />
                                        {averageTraditionalBanks.map(
                                            (stat, index) => (
                                                <StatsBox
                                                    key={stat.id}
                                                    stat={stat}
                                                    blur={
                                                        index !== 0 &&
                                                        !isLoggedIn
                                                    }
                                                />
                                            )
                                        )}
                                    </dl>
                                )}
                                {companyCategory === 'Investimento' && (
                                    <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-6">
                                        <TitleBox title="Bancos de Investimento" />
                                        {averageInvestmentBanks.map(
                                            (stat, index) => (
                                                <StatsBox
                                                    key={stat.id}
                                                    stat={stat}
                                                    blur={
                                                        index !== 0 &&
                                                        !isLoggedIn
                                                    }
                                                />
                                            )
                                        )}
                                    </dl>
                                )}
                                <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-6">
                                    <TitleBox title="Média Geral" />
                                    {averageAll.map((stat, index) => (
                                        <StatsBox
                                            key={stat.id}
                                            stat={stat}
                                            blur={index !== 0 && !isLoggedIn}
                                        />
                                    ))}
                                </dl>
                            </div>
                            <div className="lg:flex lg:flex-auto lg:justify-center">
                                <div className="max-w-lg">
                                    <h2 className="text-3xl font-semibold tracking-tight text-gray-900">
                                        O que os clientes falam
                                    </h2>
                                    <p className="mt-2 text-lg text-gray-600">
                                        {`${company.name} na pesquisa de experiência`}
                                    </p>

                                    <div className="mt-12 flex items-center text-center">
                                        <Feedback.WordCloud data={wordCloud} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
