import React, { useEffect } from 'react';
import { pdfjs } from 'react-pdf';

import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useParams
} from 'react-router-dom';

import './App.css';

import './react-pdf-AnnotationLayer.css';
import './react-pdf-TextLayer.css';

import Layout from './Layout';
import LayoutEmpty from './LayoutEmpty';

import {
    // Home,
    Flows,
    Legal,
    Reviews,
    Company,
    Directory,
    NotFound,
    About,
    Error,
    ErrorBoundary,
    Bacen,
    LandingPageRanking,
    Pricing,
    Slideshow,
    Comparison,
    MyAccount,
    Feedback,
    Auth,
    Admin,
    Checkout,
    CTA,
    Report,
    ComparisonCharts,
    // eslint-disable-next-line no-unused-vars
    HomeComingSoon,
    Home
} from './components';

import { settings, user } from './signals';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function RedirectReport() {
    const { companySlug } = useParams();
    return <Navigate to={`/${companySlug}/report/1`} replace />;
}

function App() {
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            user.value = JSON.parse(storedUser);
        }

        const storedSettings = localStorage.getItem('settings');
        if (storedSettings) {
            settings.value = JSON.parse(storedSettings);
        }
    }, []);

    useEffect(() => {
        // Atualizar o localStorage sempre que o user ou alguma setting for alterado
        localStorage.setItem('user', JSON.stringify(user.value));
        localStorage.setItem('settings', JSON.stringify(settings.value));
    }, [user.value, settings.value]);

    // prettier-ignore
    return (
        <Router>
            <ErrorBoundary>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Layout>
                                <Home />
                                {/* <HomeComingSoon /> */}
                            </Layout>
                        }
                    />

                    {/* ------------------------ Admin ------------------------ */}
                    <Route
                        path="/admin"
                        element={
                            <Layout>
                                <Auth.CheckAdmin>
                                    <Admin.Page />
                                </Auth.CheckAdmin>
                            </Layout>
                        }
                    >
                        <Route index element={<Admin.Home />} />
                        <Route path="companies" element={<Admin.Companies />} />
                        <Route path="companies/:companyId" element={<Admin.Company />} />
                        <Route path="users" element={<Admin.Users />} />
                        <Route path="users/create" element={<Admin.UserCreate />} />
                        <Route path="users/:userId" element={<Admin.User />} />
                        <Route path="reports" element={<Admin.Reports />} />
                        <Route path="reports/create" element={<Admin.ReportCreate />} />
                        <Route path="reports/:reportId" element={<Admin.Report />} />
                        <Route path="exp/scores" element={<Admin.ExpScores />} />
                        <Route path="exp/scores/create" element={<Admin.ExpScoreCreate />} />
                        <Route path="exp/scores/:expScoreId" element={<Admin.ExpScore />} />
                        <Route path="bcb/institutions" element={<Admin.BcbInstitutions />} />
                        <Route path="bcb/institutions/:bcbInstitutionId" element={<Admin.BcbInstitution />} />
                        <Route path="bcb/conglomerates" element={<Admin.BcbConglomerates />} />
                        <Route path="upload" element={<Admin.Upload />} />

                    </Route>

                    {/* ------------------------ Company ------------------------ */}
                    <Route
                        path="/:companySlug"
                        element={
                            <Layout>
                                <Auth.CheckLoggedIn>
                                    <Company.Page />
                                </Auth.CheckLoggedIn>
                            </Layout>
                        }
                    >
                        <Route index element={<About.Home />} />
                        <Route path="sobre" element={<About.Home />} />
                        {/* <Route index element={<About.HomeComingSoon />} /> */}
                        {/* <Route path="sobre" element={<About.HomeComingSoon />} /> */}
                        <Route path="bacen" element={<Bacen.Home />} />
                        <Route path="fluxos" element={<Flows.Flows />} />
                        <Route path="voz-dos-usuarios" element={<Feedback.Home />} />
                        <Route path="comparativo" element={<Comparison.Home />} />
                        {/* <Route path="comparativo" element={<Comparison.HomeComingSoon />} /> */}
                        <Route
                            path="avaliacoes"
                            element={
                                <Company.AppsTabs>
                                    <Reviews.Details />
                                </Company.AppsTabs>
                            }
                        />
                        <Route
                            path="avaliacoes/:appId"
                            element={
                                <Company.AppsTabs>
                                    <Reviews.Details />
                                </Company.AppsTabs>
                            }
                        />
                        <Route path="report" element={<RedirectReport />} />
                    </Route>

                    {/* ------------------------ Report Slideshow ------------------------ */}
                    <Route
                        path="/:companySlug/report/:page"
                        element={
                            <Auth.CheckLoggedIn>
                                <Slideshow.Container />
                            </Auth.CheckLoggedIn>
                        }
                    />

                    {/* ------------------------ Report Public ------------------------ */}
                    <Route
                        path="/2024"
                        element={
                            <Layout>
                                <Auth.CheckLoggedIn>
                                    <Report.PDF />
                                </Auth.CheckLoggedIn>
                            </Layout>
                        }
                    />

                    {/* ------------------------ Comparison ------------------------ */}
                    <Route
                        path="/comparativo"
                        element={
                            <Layout page="comparativo">
                                <ComparisonCharts.Page />
                            </Layout>
                        }
                    />

                    {/* ------------------------ CTA ------------------------ */}
                    <Route
                        path="/contato"
                        element={
                            <LayoutEmpty>
                                <Auth.CheckLoggedIn>
                                    <CTA.Conclusion />
                                </Auth.CheckLoggedIn>
                            </LayoutEmpty>
                        }
                    />

                    {/* ------------------------ Checkout ------------------------ */}
                    <Route
                        path="/checkout"
                        element={
                            <LayoutEmpty>
                                <Auth.CheckLoggedIn>
                                    <Checkout.Checkout />
                                </Auth.CheckLoggedIn>
                            </LayoutEmpty>
                        }
                    />
                    <Route
                        path="/completion"
                        element={
                            <LayoutEmpty>
                                <Auth.CheckLoggedIn>
                                    <Checkout.Success />
                                </Auth.CheckLoggedIn>
                            </LayoutEmpty>
                        }
                    />

                    {/* ------------------------ Legal ------------------------ */}
                    <Route
                        path="/termos-de-uso"
                        element={
                            <LayoutEmpty>
                                <Legal.TermsOfUse />
                            </LayoutEmpty>
                        }
                    />

                    {/* ------------------------ Account ------------------------ */}
                    <Route
                        path="/minha-conta"
                        element={
                            <Layout>
                                <Auth.CheckLoggedIn>
                                    <MyAccount />
                                </Auth.CheckLoggedIn>
                            </Layout>
                        }
                    />

                    {/* ------------------------ Auth ------------------------ */}
                    <Route path="/login" element={<Auth.Login />} />
                    <Route path="/login/email-enviado" element={<Auth.LoginEmailSent />} />
                    <Route path="/login/:token" element={<Auth.LoginMagicLink />} />
                    <Route path="/cadastro" element={<Auth.Signup />} />
                    <Route path="/logout" element={<Auth.Logout />} />

                    {/* ------------------------ Pricing ------------------------ */}
                    <Route
                        path="/pricing"
                        element={
                            <LayoutEmpty>
                                <Pricing />
                            </LayoutEmpty>
                        }
                    />

                    {/* ------------------------ Directory ------------------------ */}
                    <Route
                        path="/instituicoes"
                        element={
                            <Layout page="instituicoes">
                                <Directory.List />
                            </Layout>
                        }
                    />

                    {/* ------------------------ Ranking landing page ------------------------ */}
                    <Route
                        path="/ranking"
                        element={
                            <LayoutEmpty>
                                <Auth.CheckLoggedIn>
                                    <LandingPageRanking />
                                </Auth.CheckLoggedIn>
                            </LayoutEmpty>
                        }
                    />

                    {/* ------------------------ Errors ------------------------ */}
                    <Route path="/error" element={<Error />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </ErrorBoundary>
        </Router>
    );
}

export default App;
