import { PaperClipIcon } from '@heroicons/react/20/solid';
import { user, autocompleteList } from '../signals';
import { Layout } from '../components';

export default function MyAccount() {
    const loggedUser = user.value;
    const userCompany = autocompleteList.value.find(
        (c) => c.companyId === parseInt(loggedUser.companyId)
    );

    return (
        <>
            <Layout.Head title="Index - Minha conta" />
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 space-y-16">
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                        Minha conta
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                        Atualize suas informações.
                    </p>
                </div>
                <div className="mt-6 border-t border-gray-100">
                    <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Nome
                            </dt>
                            <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    {loggedUser.firstName} {loggedUser.lastName}
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-idwallDarkPurple-600 hover:text-idwallDarkPurple-500"
                                    >
                                        Atualizar
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Email
                            </dt>
                            <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    {loggedUser.email}
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-idwallDarkPurple-600 hover:text-idwallDarkPurple-500"
                                    >
                                        Atualizar
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Plano ativo
                            </dt>
                            <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    {loggedUser.isSubscriber ? 'Sim' : 'Não'}
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-idwallDarkPurple-600 hover:text-idwallDarkPurple-500"
                                    >
                                        Atualizar
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Empresa associada
                            </dt>
                            <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <span className="flex-grow">
                                    {userCompany ? userCompany.name : 'Nenhuma'}
                                </span>
                                <span className="ml-4 flex-shrink-0">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white font-medium text-idwallDarkPurple-600 hover:text-idwallDarkPurple-500"
                                    >
                                        Atualizar
                                    </button>
                                </span>
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">
                                Faturas
                            </dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <ul
                                    role="list"
                                    className="divide-y divide-gray-100 rounded-md border border-gray-200"
                                >
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                        <div className="flex w-0 flex-1 items-center">
                                            <PaperClipIcon
                                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                <span className="truncate font-medium">
                                                    Fev 2024
                                                </span>
                                                <span className="flex-shrink-0 text-gray-400">
                                                    VISA 1234
                                                </span>
                                            </div>
                                        </div>
                                        <div className="ml-4 flex flex-shrink-0 space-x-4">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white font-medium text-idwallDarkPurple-600 hover:text-idwallDarkPurple-500"
                                            >
                                                Visualizar
                                            </button>
                                            <span
                                                className="text-gray-200"
                                                aria-hidden="true"
                                            >
                                                |
                                            </span>
                                            <button
                                                type="button"
                                                className="rounded-md bg-white font-medium text-gray-900 hover:text-gray-800"
                                            >
                                                Tentar novamente
                                            </button>
                                        </div>
                                    </li>
                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                        <div className="flex w-0 flex-1 items-center">
                                            <PaperClipIcon
                                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                <span className="truncate font-medium">
                                                    Jan 2024
                                                </span>
                                                <span className="flex-shrink-0 text-gray-400">
                                                    VISA 1234
                                                </span>
                                            </div>
                                        </div>
                                        <div className="ml-4 flex flex-shrink-0 space-x-4">
                                            <button
                                                type="button"
                                                className="rounded-md bg-white font-medium text-idwallDarkPurple-600 hover:text-idwallDarkPurple-500"
                                            >
                                                Visualizar
                                            </button>
                                            <span
                                                className="text-gray-200"
                                                aria-hidden="true"
                                            >
                                                |
                                            </span>
                                            <button
                                                type="button"
                                                className="rounded-md bg-white font-medium text-gray-900 hover:text-gray-800"
                                            >
                                                Tentar novamente
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </>
    );
}
