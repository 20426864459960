import { Layout, UI } from '..';
import { useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Document, Page, Thumbnail } from 'react-pdf';
import { classNames } from '../../helpers';
import { Listbox, Transition } from '@headlessui/react';
import { LuDot } from 'react-icons/lu';

import {
    CheckIcon,
    ChevronUpDownIcon,
    ArrowLongLeftIcon,
    ArrowLongRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from '@heroicons/react/20/solid';

const navigation = [
    { name: 'Intro', page: '1' },
    { name: 'Sumário Executivo', page: '7' },
    { name: 'Metodologia', page: '8' },
    { name: 'O Setor Financeiro e Bancos Digitais', page: '13' },
    { name: 'A Percepção dos Clientes', page: '20' },
    { name: 'Market Trends', page: '67' },
    { name: 'A Jornada dos Bancos', page: '73' },
    { name: 'Ranking 2024', page: '115' }
];

function HoverPanelLeft({ currentPage, totalPages, onClick }) {
    if (currentPage === 1) {
        return null;
    }

    return (
        <div className="absolute z-10 left-0 top-0 bottom-0">
            <div className="h-full w-24 py-[90px] cursor-pointer">
                <div
                    className="flex flex-col items-center justify-center h-full opacity-0 hover:opacity-100 z-50"
                    onClick={onClick}
                    style={{
                        background:
                            'radial-gradient(circle, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 100%)'
                    }}
                >
                    <div className="bg-white rounded-full shadow-md p-2">
                        <ChevronLeftIcon
                            className="h-5 w-5 text-black"
                            aria-hidden="true"
                        />
                    </div>
                    <p className="text-white mt-4">Anterior</p>
                </div>
            </div>
        </div>
    );
}

function HoverPanelRight({ currentPage, totalPages, onClick }) {
    if (currentPage === totalPages) {
        return null;
    }

    return (
        <div className="absolute z-10 right-0 top-0 bottom-0">
            <div className="h-full w-24 py-[90px] cursor-pointer">
                <div
                    className="flex flex-col items-center justify-center h-full opacity-0 hover:opacity-100 z-50"
                    onClick={onClick}
                    style={{
                        background:
                            'radial-gradient(circle, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 100%)'
                    }}
                >
                    <div className="bg-white rounded-full shadow-md p-2">
                        <ChevronRightIcon
                            className="h-5 w-5 text-black"
                            aria-hidden="true"
                        />
                    </div>
                    <p className="text-white mt-4">Próxima</p>
                </div>
            </div>
        </div>
    );
}

const PaginationNext = ({ onPageChange }) => (
    <UI.Button variant="menu" onClick={onPageChange}>
        <ArrowLongRightIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
        />
    </UI.Button>
);

const PaginationPrevious = ({ onPageChange }) => (
    <UI.Button variant="menu" onClick={onPageChange}>
        <ArrowLongLeftIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
        />
    </UI.Button>
);

function getContainerWidth(windowWidth) {
    if (windowWidth > 1536) return 1536;
    if (windowWidth > 1280) return 1280;
    if (windowWidth > 1024) return 1024;
    return 768;
}

function getNumberOfThumbnails(windowWidth) {
    if (windowWidth > 1536) return 12;
    if (windowWidth > 1280) return 10;
    if (windowWidth > 1024) return 8;
    return 6;
}

export default function Home() {
    const location = useLocation();
    const navigate = useNavigate();

    const [width, setWidth] = useState(getContainerWidth(window.innerWidth));
    const [numPages, setNumPages] = useState();
    const [numThumbnails, setNumThumbnails] = useState(
        getNumberOfThumbnails(window.innerWidth)
    );
    const [pageNumber, setPageNumber] = useState(1);
    const [thumbnailWindowStart, setThumbnailWindowStart] = useState(0);
    const [section, setSection] = useState(navigation[3]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowRight':
                    setPageNumber((prevPage) =>
                        Math.min(prevPage + 1, numPages)
                    );
                    break;
                case 'ArrowLeft':
                    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [numPages, pageNumber]);

    // Efeito para ajustar o número da página com base na query string na montagem do componente
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageFromUrl = parseInt(searchParams.get('page'), 10);
        if (!isNaN(pageFromUrl) && pageFromUrl >= 1) {
            setPageNumber(pageFromUrl);
        }
    }, []); // Dependências vazias para rodar apenas na montagem

    // Efeito para ajustar as dimensões baseado no redimensionamento da janela
    useEffect(() => {
        const handleResize = () => {
            setWidth(getContainerWidth(window.innerWidth));
            setNumThumbnails(getNumberOfThumbnails(window.innerWidth));
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Efeito para atualizar a URL quando o usuário muda de página
    useEffect(() => {
        if (pageNumber > 0) {
            navigate(`?page=${pageNumber}`, { replace: true });
        }
    }, [pageNumber]); // Dependências apenas com pageNumber

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function handleNextWindow() {
        setThumbnailWindowStart((prevStart) =>
            Math.min(prevStart + 1, numPages - numThumbnails)
        );
    }

    function handlePreviousWindow() {
        setThumbnailWindowStart((prevStart) => Math.max(prevStart - 1, 0));
    }

    // Set current section according to page number
    useEffect(() => {
        const section = navigation.find((section, index) => {
            const currentPage = parseInt(pageNumber);
            const nextPage =
                index < navigation.length - 1
                    ? parseInt(navigation[index + 1].page)
                    : numPages + 1;
            return (
                currentPage >= parseInt(section.page) && currentPage < nextPage
            );
        });
        if (section) {
            setSection(section);
        }
    }, [pageNumber]);

    function NavigationMenu({ onClick }) {
        return (
            <Listbox value={section}>
                {({ open }) => (
                    <>
                        <div className="relative mt-2 z-20">
                            <Listbox.Button className="relative cursor-pointer rounded-md bg-gray-800 py-1.5 pl-3 pr-10 text-left text-white shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none focus:ring-2 focus:ring-idwallDarkPurple-500 sm:leading-6">
                                <span className="inline-flex w-full truncate text-base md:text-2xl font-semibold tracking-tight space-x-2">
                                    <span>Relatório de Experiência 2024</span>
                                    <LuDot className="my-1" />
                                    <span className="truncate text-gray-300">
                                        {section.name}
                                    </span>
                                    <LuDot className="my-1" />
                                    <span className="truncate text-gray-500">
                                        {section.page}
                                    </span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {navigation.map((navigationItem) => (
                                        <Listbox.Option
                                            key={navigationItem.page}
                                            className={({ active }) =>
                                                classNames(
                                                    active
                                                        ? 'bg-idwallDarkPurple-600 text-white'
                                                        : 'text-gray-300',
                                                    'relative cursor-pointer select-none py-2 pl-3 pr-9'
                                                )
                                            }
                                            value={navigationItem}
                                            onClick={() => {
                                                onClick(
                                                    parseInt(
                                                        navigationItem.page
                                                    )
                                                );
                                            }}
                                        >
                                            {({ section, active }) => (
                                                <>
                                                    <div className="flex">
                                                        <span
                                                            className={classNames(
                                                                section
                                                                    ? 'font-semibold'
                                                                    : 'font-normal',
                                                                'truncate text-gray-300'
                                                            )}
                                                        >
                                                            {
                                                                navigationItem.name
                                                            }
                                                        </span>
                                                        <span
                                                            className={classNames(
                                                                active
                                                                    ? 'text-idwallDarkPurple-200'
                                                                    : 'text-gray-500',
                                                                'ml-2 truncate text-gray-500'
                                                            )}
                                                        >
                                                            {
                                                                navigationItem.page
                                                            }
                                                        </span>
                                                    </div>

                                                    {section ? (
                                                        <span
                                                            className={classNames(
                                                                active
                                                                    ? 'text-white'
                                                                    : 'text-idwallDarkPurple-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        );
    }

    return (
        <>
            {' '}
            <Layout.Head title="Relatório de Experiência 2024 - idwall" />
            <main className="bg-gray-800 py-16">
                <div className="max-w-screen-xl mx-auto xl:min-w-[1280px] 2xl:min-w-[1536px] lg:min-w-[1024px] min-w-[678px]">
                    <div className="flex w-full">
                        <div className="flex-auto">
                            <NavigationMenu
                                onClick={(page) => {
                                    setPageNumber(page);
                                    setThumbnailWindowStart(page - 1);
                                }}
                            />
                        </div>
                        <span className="flex-none text-2xl text-white font-semibold invisible lg:visible">
                            <div className="mt-4">
                                {pageNumber}/{numPages}
                            </div>
                        </span>
                    </div>
                    <div className="flex justify-center items-center xl:min-h-[720px] 2xl:min-h-[864px] lg:min-h-[576px] min-h-[381px] mt-4 relative bg-white">
                        <HoverPanelLeft
                            currentPage={parseInt(pageNumber)}
                            totalPages={numPages}
                            onClick={() => {
                                handlePreviousWindow();
                                setPageNumber(pageNumber - 1);
                            }}
                        />

                        <HoverPanelRight
                            currentPage={parseInt(pageNumber)}
                            totalPages={numPages}
                            onClick={() => {
                                handleNextWindow();
                                setPageNumber(pageNumber + 1);
                            }}
                        />
                        <Document
                            file="ranking_idwall_de_experiencia_digital_2024_v3.pdf"
                            loading="..."
                            onLoadSuccess={onDocumentLoadSuccess}
                            onItemClick={({ pageNumber }) => {
                                setPageNumber(pageNumber);
                                setThumbnailWindowStart(pageNumber - 1);
                            }}
                        >
                            <Page
                                className="text-gray-700 bg-white"
                                pageNumber={pageNumber}
                                width={width}
                                loading="Carregando..."
                                canvasBackground="transparent"
                            />
                        </Document>
                    </div>
                    <div className="flex justify-center items-center mt-8 space-x-4">
                        <PaginationPrevious
                            onPageChange={handlePreviousWindow}
                        />
                        {Array.from(
                            {
                                length: Math.min(
                                    numThumbnails,
                                    numPages - thumbnailWindowStart
                                )
                            },
                            (_, i) => i + 1 + thumbnailWindowStart
                        ).map((page) => (
                            <button
                                key={page}
                                onClick={() => setPageNumber(page)}
                                className={`focus:outline-none relative min-h-[56px] min-w-[100px] ${
                                    pageNumber === page
                                        ? 'ring-1 ring-offset-2 ring-white'
                                        : ''
                                }`}
                            >
                                <Document
                                    file="ranking_idwall_de_experiencia_digital_2024_v3.pdf"
                                    className="text-white"
                                    loading={page}
                                >
                                    <Thumbnail pageNumber={page} width={130} />
                                </Document>
                                <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100">
                                    <span className="text-white font-bold">
                                        {page}
                                    </span>
                                </div>
                            </button>
                        ))}
                        <PaginationNext onPageChange={handleNextWindow} />
                    </div>
                </div>
            </main>
        </>
    );
}
