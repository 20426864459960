import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { shareReportModalIsOpen } from '../../signals';
import { UI } from '..';

export default function ShareReportModal() {
    const cancelButtonRef = useRef(null);

    return (
        <Transition.Root show={shareReportModalIsOpen.value} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                initialFocus={cancelButtonRef}
                onClose={() => {
                    shareReportModalIsOpen.value = false;
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="py-10 px-6">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                        Compartilhar este report com
                                    </label>
                                    <div className="relative mt-2 rounded-md shadow-sm">
                                        <input
                                            type="text"
                                            name="email"
                                            id="email"
                                            className="block w-full rounded-md border-0 py-1.5 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-idwallDarkPurple-600 sm:text-sm sm:leading-6"
                                            placeholder="email"
                                            aria-describedby="email-domain"
                                        />
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                            <span className="text-gray-500 sm:text-sm" id="email-domain">
                                                @idwall.co
                                            </span>
                                        </div>
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500" id="email-description">
                                        Encaminharemos esta página. Só pode ser compartilhado dentro da mesma empresa.
                                    </p>
                                </div>
                                <div className="bg-gray-50 p-6 sm:flex sm:flex-row-reverse">
                                    <UI.Button
                                        onClick={() => {
                                            shareReportModalIsOpen.value = false;
                                        }}
                                        ref={cancelButtonRef}
                                        className="inline-flex w-full justify-center rounded-md bg-idwallDarkPurple-600 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-idwallDarkPurple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-idwallDarkPurple-600"
                                    >
                                        Enviar
                                    </UI.Button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
