export const tcType = {
    1: 'Público',
    2: 'Privado Nacional',
    3: 'Privado com Controle Estrangeiro'
};

export const tcbType = {
    b1: 'Instituição individual do tipo Banco Comercial, Banco Múltiplo com Carteira Comercial ou Caixas Econômicas e Conglomerado composto de pelo menos uma instituição do tipo Banco Comercial, Banco Múltiplo com Carteira Comercial ou Caixas Econômicas.',
    b2: 'Instituição individual do tipo Banco Múltiplo sem Carteira Comercial, Banco de Câmbio, ou Banco de Investimento e Conglomerado composto de pelo menos uma instituição do tipo Banco Múltiplo sem Carteira Comercial ou Banco de Investimento, mas sem conter instituições do tipo Banco Comercial e Banco Múltiplo com Carteira Comercial.',
    b3s: 'Cooperativa de Crédito Singular.',
    b3c: 'Central e Confederação de Cooperativas de Crédito.',
    b4: 'Banco de Desenvolvimento.',
    n1: 'Instituição não bancária atuante no mercado de crédito.',
    n2: 'Instituição não bancária atuante no mercado de capitais.',
    n4: 'Instituição de pagamento.'
};

export const tiType = {
    1: 'Banco do Brasil - Banco Múltiplo',
    2: 'Banco Comercial',
    4: 'BNDES',
    5: 'Banco de Desenvolvimento',
    6: 'Caixa Econômica Federal',
    7: 'Caixa Econômica Estadual',
    8: 'Banco Múltiplo',
    9: 'Cooperativa de Crédito',
    10: 'Sociedade de Crédito ao Microempreendedor',
    11: 'Banco Múltiplo Cooperativo',
    13: 'Banco de Investimento',
    14: 'Sociedade de Crédito, Financiamento e Investimento',
    15: 'Sociedade Corretora de TVM',
    16: 'Sociedade Distribuidora de TVM',
    19: 'Sociedade de Arrendamento Mercantil',
    21: 'Sociedade Corretora de Câmbio',
    25: 'Associação de Poupança e Empréstimo',
    28: 'Banco Comercial Estrangeiro - Filial no país',
    29: 'Companhia Hipotecária',
    30: 'Agência de Fomento',
    31: 'Sociedade de Crédito Imobiliário - Repassadora',
    39: 'Banco de Câmbio',
    41: 'Instituições de Pagamento',
    43: 'Sociedades de Crédito Direto',
    44: 'Sociedades de Empréstimo entre Pessoas'
};

export const clientesType = [
    {
        title: 'CCS',
        description:
            'Total de clientes constantes no CCS (Cadastro de Clientes do Sistema Financeiro Nacional), com relacionamentos ativos na instituição ou conglomerado financeiro. Posição do último dia do mês anterior ao mês de referência.'
    },
    {
        title: 'SCR',
        description:
            'Total de clientes constantes no SCR (Sistema de Informações de Crédito do Banco Central), com operações ativas na instituição ou conglomerado financeiro. Posição do mês anterior ao mês de referência.'
    },
    {
        title: 'FGC',
        description: 'Total de clientes protegidos pelo Fundo Garantidor de Créditos (FGC). Posição do semestre anterior ao mês de referência.'
    }
];

export const reclamacoesType = [
    {
        title: 'Reclamações Reguladas Procedentes',
        description:
            'Reclamações feitas pelos clientes de instituições financeiras que são consideradas justas e válidas após avaliação. Isso significa que, após a investigação, o BACEN entende que a instituição financeira não cumpriu com as normas ou expectativas regulamentadas e que a queixa do cliente era procedente.'
    },
    {
        title: 'Reclamações Reguladas - Outras',
        description:
            'Reclamações que também são reguladas pelo BACEN, mas que não foram consideradas procedentes ou que são de natureza diferente das reclamações procedentes. Por exemplo, podem ser reclamações que foram resolvidas entre a instituição financeira e o cliente sem a necessidade de uma decisão formal do regulador.'
    },
    {
        title: 'Reclamações Não Reguladas',
        description:
            'Reclamações que não estão sob a alçada regulatória do BACEN ou não se enquadram nas regras e normas definidas pelo órgão. Isso pode incluir queixas sobre serviços que não são regulados pelo BACEN ou queixas que não se encaixam nos critérios estabelecidos pelo BACEN para uma reclamação regulada.'
    }
];
