import { Fragment } from 'react';
import { UI, CTA, TableRanking } from '../components';
import ranking from '../data/ranking';
import { user } from '../signals';

function RankingCategory({ data }) {
  const handleRowClick = (slug) => {
    window.location.href = `/${slug}`;
  };

  return (
    <ul role="list" className="divide-y divide-gray-200">
      {data.map((row) => (
        <li
          key={row.companyId}
          className="relative flex justify-between gap-x-6 px-4 py-3 lg:py-5 hover:bg-gray-50 sm:px-6 lg:px-8 cursor-pointer"
          onClick={() => handleRowClick(row.slug)}
        >
          <div className="flex min-w-0 gap-x-4">
            <UI.Icon48
              className="hidden h-12 w-12 lg:flex flex-none"
              slug={row.slug}
            />
            <UI.Icon32
              className="flex h-8 w-8 mt-1.5 lg:hidden flex-none"
              slug={row.slug}
            />
            <div className="min-w-0 flex-auto">
              <p className="text-sm whitespace-nowrap font-semibold leading-6 text-gray-900">
                <span className="absolute inset-x-0 -top-px bottom-0" />
                {row.name}
              </p>
              <p className="lg:mt-1 flex text-xs leading-5 text-gray-500">
                <span className="relative truncate">{row.type}</span>
              </p>
            </div>
          </div>
          <div className="flex shrink-0 items-center gap-x-4">
            <div className="hidden sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">{row.role}</p>
              <span className="px-3 py-4 w-16 text-sm text-idwallDarkPurple-500 font-semibold text-right">
                {row.score.toFixed(2)}
              </span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

function LoginCTA() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-4 sm:px-6 sm:py-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-semibold tracking-tight text-white sm:text-4xl">
              Acesse mais informações.
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Faça seu cadastro e tenha acesso ao comparativo de instituições,
              avaliações de usuários, screenshots de fluxos, dados dos apps e
              muito mais.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <UI.Button as="a" href="/cadastro">
                Criar minha conta
              </UI.Button>

              <UI.Button
                as="a"
                variant="link"
                href="https://idwall.co/"
                target="_blank"
                className="text-white hover:text-gray-300"
              >
                Conheça a idwall <span aria-hidden="true">→</span>
              </UI.Button>
            </div>
          </div>
          <div className="relative mt-16 lg:mt-24">
            <img
              className="absolute left-0 top-0 w-[40rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src="/img/mock-dashboard-comparison.min.png"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Home() {
  return (
    <>
      <main>
        <div className={'mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 space-y-16'}>
          {user.value.isLoggedIn ? (
            <>
              <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-1">
                {/* Conditionally render ranking section based on user.value.isLoggedIn */}
                {user.value.isLoggedIn && <TableRanking />}
              </div>
              <div className="bg-gray-50">
                <div className="mx-auto max-w-7xl py-12 sm:px-6 lg:px-8 space-y-16">
                  <div className="mx-auto max-w-2xl lg:mx-0">
                    <UI.H1>Rankings de Experiência 2024</UI.H1>
                    <p className="mt-2 text-lg leading-8 text-gray-600">Categorias</p>
                  </div>
                </div>
              </div>
              <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                <div className="space-y-8">
                  <UI.H2>Relação com o Cliente</UI.H2>
                  <p className="mt-5 text-sm leading-6 text-gray-600">
                    A instituição bancária com o melhor relacionamento com cliente
                    se destacou na avaliação de seus clientes nos quesitos de
                    usabilidade, conveniência, tempo de retorno e resposta, e oferta
                    de produtos e serviços em seu portfólio.
                  </p>
                  <RankingCategory data={ranking.experience} />
                </div>
                <div className="space-y-8">
                  <UI.H2>Onboarding</UI.H2>
                  <p className="mt-5 text-sm leading-6 text-gray-600">
                    O fluxo de cadastro foi avaliado a partir da consolidação de
                    notas de quesitos críticos para esse processo, e presentes na
                    composição de dimensões como: usabilidade, satisfação e
                    segurança percebida.
                  </p>
                  <RankingCategory data={ranking.onboarding} />
                </div>
                <div className="space-y-8">
                  <UI.H2>Encantamento do Cliente</UI.H2>
                  <p className="mt-5 text-sm leading-6 text-gray-600">
                    O Encantamento do Cliente foi aferido com base em índices de
                    satisfação com cada processo bancário, percepção sobre
                    instituição financeira, escala NPS (Net Promoter Score) e de
                    experiência geral com o banco principal.
                  </p>
                  <RankingCategory data={ranking.customerLove} />
                </div>
              </div>
              <CTA.Report />
            </>
          ) : (
            <>
              <CTA.Report />
              <LoginCTA />
            </>
          )}
        </div>
      </main>
    </>
  );
}
