import { useEffect, useCallback, useState } from 'react';
import { companiesList, isLoading } from '../../signals';
import { CompanyService } from '../../services';
import { Directory, UI, Layout } from '../../components';

export default function CompaniesList() {
    const [verified, setVerified] = useState(null);
    const [type, setType] = useState([]);
    const [name, setName] = useState([]);

    const loadCompaniesList = useCallback(
        async (params) => {
            isLoading.value = true;
            try {
                companiesList.value = await CompanyService.fetchCompanies({
                    ...params,
                    type,
                    name,
                    verified
                });
            } catch (error) {
                console.error('Failed to load company details:', error);
            } finally {
                isLoading.value = false;
            }
        },
        [type, name, verified]
    );

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pageParam = urlParams.get('p');
        const currentPage = pageParam ? parseInt(pageParam) : 1;
        loadCompaniesList({
            ...companiesList.value,
            page: currentPage
        });
    }, [loadCompaniesList]);

    const handlePageChange = async (newPage) => {
        await loadCompaniesList({
            ...companiesList.value,
            page: newPage
        });

        // prettier-ignore
        if (history.pushState) {
            const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + `?p=${newPage}`;
            window.history.pushState({ path: newurl }, '', newurl);
        }
    };

    const handleFilterTypeChange = setType;
    const handleFilterNameChange = setName;

    const handleFilterVerifiedChange = async (verified) => {
        setVerified(verified);
    };

    const handleSortChange = async (field) => {
        let order, sort;

        if (
            companiesList.value.sort !== field ||
            companiesList.value.order === 'desc'
        ) {
            sort = field;
            order = 'asc';
        } else {
            sort = companiesList.value.sort;
            order = 'desc';
        }

        await loadCompaniesList({
            ...companiesList.value,
            sort,
            order
        });
    };

    return (
        <>
            <Layout.Head title="Index - Instituições" />

            <div className="py-10">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-6">
                    <div className="sm:flex sm:items-center">
                        <UI.H1>Instituições</UI.H1>
                    </div>
                </div>
                <Directory.ListFilter
                    onChangeType={handleFilterTypeChange}
                    onChangeName={handleFilterNameChange}
                    onChangeVerified={handleFilterVerifiedChange}
                />
                <div className="mb-20 flow-root">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="inline-block min-w-full py-2 align-middle">
                            <Directory.Table onSortChange={handleSortChange} />
                            <Layout.Pagination
                                page={companiesList.value.page}
                                limit={companiesList.value.limit}
                                total={companiesList.value.total}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
