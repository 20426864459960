import React from 'react';
import { TagCloud } from 'react-tagcloud';
import { Slideshow } from '..';

const customRenderer = (tag, size, color) => {
    return (
        <span key={tag.value} style={{ color, fontSize: size }} className="inline-block px-3 align-middle font-semibold">
            {tag.value}
        </span>
    );
};

const colorOptions = {
    luminosity: 'random',
    hue: '#4500BA'
};

function ReportWordCloud({ data }) {
    return (
        <main>
            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div className="flex items-center text-center px-20" style={{ height: 'calc(100vh - 200px)' }}>
                    <TagCloud minSize={30} maxSize={130} colorOptions={colorOptions} tags={data.wordCloud} renderer={customRenderer} />{' '}
                </div>
                <div>
                    <Slideshow.Testimonials data={data.testimonials} />
                </div>
            </div>
        </main>
    );
}

export default ReportWordCloud;
