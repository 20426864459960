import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { useCallback, useEffect } from 'react';
import Form from './Form';
import ExpScoreFormData from './ExpScoreFormData';

const expScore = signal({});
const companies = signal([]);
const expCriterias = signal([]);
const expJourneys = signal([]);

export default function AdminExpScoreCreate() {
    const fetchData = useCallback(async () => {
        try {
            companies.value = await AdminService.getCompanies();
            expCriterias.value = await AdminService.getExpCriterias();
            expJourneys.value = await AdminService.getExpJourneys();
        } catch (error) {
            console.error('Failed to load companies:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const createExpScore = async (data) => {
        try {
            await AdminService.createExpScore(data);
            window.location.href = '/admin/exp/scores';
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        createExpScore(expScore.value);
    };

    const form = ExpScoreFormData(expScore.value, companies.value, expCriterias.value, expJourneys.value);

    return <Form fields={form} handleSubmit={handleSubmit} signal={expScore} />;
}
