import { getCompanyColor } from '../../styles';
import { currentCompany } from '../../signals';

function TableColumn({ rankings, highlight, highlightColor }) {
    return (
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            Ranking
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Instituição
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Nota
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {rankings.map((ranking) => (
                        <tr
                            key={ranking.instituicao}
                            className={` ${
                                ranking.ranking === 1
                                    ? 'bg-idwallDarkPurple-600 font-bold text-white '
                                    : ranking.instituicao === highlight
                                    ? 'font-bold text-white'
                                    : ' even:bg-gray-50 text-gray-500'
                            }`}
                            style={ranking.instituicao === highlight ? { backgroundColor: highlightColor } : {}}
                        >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold">{ranking.ranking}º</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm ">{ranking.instituicao}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm ">{ranking.nota}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default function ReportTable({ data }) {
    const companyName = currentCompany.value.name;
    const halfLength = Math.ceil(data.ranking.length / 2);
    const firstHalf = data.ranking.slice(0, halfLength);
    const secondHalf = data.ranking.slice(halfLength);
    const companyColor = getCompanyColor(companyName, ['#58C18E']);

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 grid grid-cols-3 gap-4">
                <TableColumn rankings={firstHalf} highlight={companyName} highlightColor={companyColor} />
                <TableColumn rankings={secondHalf} highlight={companyName} highlightColor={companyColor} />
                <div>
                    <h1 className="mt-20 text-3xl font-bold text-gray-900 sm:text-4xl">{data.title}</h1>
                    <p className="mt-6 text-xl leading-8 text-gray-700">{data.subtitle}</p>
                    <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                        {data.content.map((paragraph, index) => (
                            <p key={index} className="mt-5">
                                {paragraph}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
