/* eslint-disable object-curly-spacing */
const {post} = require('../utils/api/api_caller_utils');

export const createPaymentIntent = async ({companyId, amount, description}) => {
    try {
        const response = await post('/users/create-payment-intent', {companyId, amount});
        const responseObj = JSON.parse(response);
        return responseObj.intent;
    } catch (error) {
        console.error('Error fetching reviews', error);
    }
};
