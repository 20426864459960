import React, { useState, Fragment } from 'react';
import { slugify } from '../../helpers';
import { chartTheme } from '../../styles';
import { UI, Layout, CTA } from '..';
import ChartBar from './ChartBar';
import ChartRadar from './ChartRadar';
import ChartHeatmap from './ChartHeatmap';
import Table from './Table';
import TabsChartType from './TabsChartType';
import mock from './mock.json';
import { modalCTADashboardComparison } from '../../signals';

// Array for companies list
const companies = [];

mock.forEach((item, index) => {
    if (!companies.find((c) => c.companyId === item.companyId)) {
        companies.push({
            companyId: item.companyId,
            name: item.name,
            slug: item.slug
        });
    }
});

// Order companies by name
companies.sort((a, b) => a.name.localeCompare(b.name));

// Array for dimensions combobox
const dimensions = [];

mock.forEach((item, index) => {
    if (!dimensions.find((c) => c.group === item.group)) {
        dimensions.push({
            group: item.group,
            id: `group-${slugify(item.group)}`,
            items: []
        });
    }

    const group = dimensions.find((c) => c.group === item.group);

    if (!group.items.find((c) => c.name === item.dimension)) {
        dimensions
            .find((c) => c.group === item.group)
            .items.push({
                id: index,
                name: item.dimension
            });
    }
});

function ChartContainer({
    selectedGroup,
    selectedDimensions,
    selectedCompanies,
    data
}) {
    const [selectedChart, setSelectedChart] = useState('Vertical');

    const handleTabClick = (tab) => {
        setSelectedChart(tab);
    };

    return (
        <>
            {selectedDimensions.length > 0 && (
                <TabsChartType
                    onClick={handleTabClick}
                    current={selectedChart}
                    selectedDimensions={selectedDimensions}
                />
            )}
            <div className="mt-10">
                {selectedDimensions.length === 0 && (
                    <p className="text-center mt-24">Selecione uma dimensão</p>
                )}
                {selectedDimensions.length > 0 && (
                    <div className="ml-16 text-base font-semibold leading-6 text-gray-900">
                        {`Instituições X ${selectedGroup}`}
                    </div>
                )}
                {selectedDimensions.length > 0 &&
                    selectedChart === 'Vertical' && (
                        <div className="h-[550px]">
                            <ChartBar
                                chartTheme={chartTheme}
                                data={data}
                                keys={selectedCompanies}
                            />
                        </div>
                    )}
                {selectedDimensions.length > 0 &&
                    selectedChart === 'Horizontal' && (
                        <div className="h-[550px]">
                            <ChartBar
                                chartTheme={chartTheme}
                                data={data}
                                keys={selectedCompanies}
                                layout="horizontal"
                            />
                        </div>
                    )}
                {selectedDimensions.length > 0 && selectedChart === 'Radar' && (
                    <div className="h-[550px]">
                        <ChartRadar
                            chartTheme={chartTheme}
                            data={data}
                            keys={selectedCompanies}
                        />
                    </div>
                )}
                {selectedDimensions.length > 0 &&
                    selectedChart === 'Heatmap' && (
                        <div className="h-[550px]">
                            <ChartHeatmap data={data} />
                        </div>
                    )}
                {selectedDimensions.length > 0 && selectedChart === 'Table' && (
                    <div className="h-[550px]">
                        <Table data={data} />
                    </div>
                )}
            </div>
        </>
    );
}

function List({ filter, items, onChange, selectedItems = [] }) {
    // items must have a "name" property
    return (
        <div className="max-h-[650px] overflow-y-auto -mx-8 px-8">
            <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                {items.map((item) => (
                    <label
                        key={slugify(item.name)}
                        htmlFor={slugify(item.name)}
                        className={`relative flex items-start py-3 cursor-pointer ${
                            filter &&
                            !slugify(item.name).includes(filter) &&
                            !selectedItems.includes(item.name)
                                ? 'hidden'
                                : ''
                        }`}
                    >
                        {item.slug && (
                            <UI.Icon24
                                slug={item.slug}
                                className="inline-block mr-3 -mt-1"
                            />
                        )}
                        <div className="min-w-0 flex-1 text-sm leading-4">
                            <span className="select-none font-medium text-gray-900">
                                {item.name}
                            </span>
                        </div>
                        <div className="ml-3 flex h-4 items-center">
                            <input
                                id={slugify(item.name)}
                                name={slugify(item.name)}
                                type="checkbox"
                                value={item.name}
                                onChange={onChange}
                                checked={selectedItems.includes(item.name)}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                        </div>
                    </label>
                ))}
            </div>
        </div>
    );
}

const generateDataForSelectedDimensionsAndCompanies = (
    selectedDimensions,
    selectedCompanies
) => {
    return selectedDimensions.map((dimension) => ({
        dimension,
        ...selectedCompanies.reduce(
            (acc, company) => ({
                ...acc,
                [company]: mock
                    .filter((m) => m.name === company)
                    .find((d) => d.dimension === dimension).value
            }),
            {}
        )
    }));
};

const ComboboxDimensions = ({ dimensions, onChange }) => {
    return (
        <select
            onChange={onChange}
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
            <option value="">Selecione...</option>
            {dimensions.map((dimension, index) => (
                <option key={index} value={dimension.group}>
                    {dimension.group}
                </option>
            ))}
        </select>
    );
};

const FilterCompanies = ({ onChange }) => {
    return (
        <div className="mt-2">
            <input
                type="filter"
                name="filter"
                id="filter"
                onChange={onChange}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Filtrar..."
            />
        </div>
    );
};

const Comparison = () => {
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedDimensions, setSelectedDimensions] = useState([]);
    const [filter, setFilter] = useState('');

    const handleCompanySelection = (e) => {
        const isSelected = e.target.checked;
        const company = e.target.value;
        setSelectedCompanies((prev) => {
            if (isSelected) {
                if (prev.length >= 4) {
                    modalCTADashboardComparison.value = true;
                }

                // Se já tem 4 empresas selecionadas, remove a primeira antes de adicionar a nova
                const newCompanies =
                    prev.length >= 4
                        ? [...prev.slice(1), company]
                        : [...prev, company];
                return newCompanies;
            } else {
                // Se está desmarcando, apenas remove a empresa da lista
                return prev.filter((c) => c !== company);
            }
        });
    };

    const handleFilterChange = (e) => {
        setFilter(slugify(e.target.value));
    };

    const handleGroupSelection = (e) => {
        setSelectedDimensions([]);
        setSelectedGroup(e.target.value);
    };

    const handleDimensionSelection = (e) => {
        const isSelected = e.target.checked;
        const dimension = e.target.value;
        setSelectedDimensions((prev) => {
            if (isSelected) {
                return [...prev, dimension];
            } else {
                return prev.filter((c) => c !== dimension);
            }
        });
    };

    const data = generateDataForSelectedDimensionsAndCompanies(
        selectedDimensions,
        selectedCompanies
    );

    return (
        <>
            <Layout.Head title="Index - Comparativo Ranking 2024" />
            <CTA.DashboardComparisonModal />

            <div className="bg-white pb-24">
                {/* Component layout */}
                <div className="mx-auto py-10 max-w-7xl px-4 sm:px-6 lg:px-8 mb-6">
                    <div className="sm:flex sm:items-center">
                        <UI.H1>Comparativo Ranking 2024</UI.H1>
                    </div>
                </div>

                <div className="mx-auto px-8 flex gap-x-8 min-h-[550px]">
                    <div className="flex-none w-48">
                        <div className="text-base font-semibold leading-6 text-gray-900 mb-6">
                            Instituições {filter}
                        </div>
                        <FilterCompanies onChange={handleFilterChange} />
                        <List
                            filter={filter}
                            selectedItems={selectedCompanies}
                            items={companies}
                            onChange={handleCompanySelection}
                        />
                    </div>
                    <div className="grow w-[200px] border-x">
                        <ChartContainer
                            data={data}
                            selectedGroup={selectedGroup}
                            selectedCompanies={selectedCompanies}
                            selectedDimensions={selectedDimensions}
                        />
                    </div>
                    <div className="flex-none w-48">
                        <div className="text-base font-semibold leading-6 text-gray-900 mb-6">
                            Dimensão
                        </div>
                        <ComboboxDimensions
                            dimensions={dimensions}
                            onChange={handleGroupSelection}
                        />
                        {dimensions
                            .filter((d) => d.group === selectedGroup)
                            .map((dimension) => (
                                <List
                                    key={dimension.group}
                                    items={dimension.items}
                                    selectedItems={selectedDimensions}
                                    onChange={handleDimensionSelection}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Comparison;
