import { UI } from '../components';
import { FaTrophy } from 'react-icons/fa';
import ranking from '../data/ranking';

export default function TableRanking({ data }) {
    const rowsSideA = ranking.general.slice(0, ranking.general.length / 2);
    const rowsSideB = ranking.general.slice(
        ranking.general.length / 2,
        ranking.general.length
    );
    return (
        <div className="space-y-8">
            <UI.H1>Ranking Geral - Jornada</UI.H1>
            <article className="flex max-w-3xl flex-col items-start justify-between">
                <p className="text-sm leading-6 text-gray-600">
                    A premiação da melhor jornada levou em consideração as
                    particularidades de cada segmento bancário - Instituições
                    Digitais, Tradicionais e de Investimento - e destaca, para
                    cada vencedor, a percepção e experiência dos clientes em seu
                    ciclo de vida com sua instituição principal, representado
                    pelas transações bancárias mais emblemáticas: o onboarding,
                    o investimento, o Pix, e a alteração de dados cadastrais.
                </p>
            </article>
            <div className="hidden grid-cols-2 lg:grid space-x-4">
                <Table data={rowsSideA} />
                <Table data={rowsSideB} />
            </div>

            <div className="grid grid-cols-1 lg:hidden">
                <Table data={ranking.general} />
            </div>
        </div>
    );
}

const Table = ({ data }) => {
    const handleRowClick = (slug) => {
        window.open(`/${slug}`, '_blank');
    };

    return (
        <div className="overflow-none flow-root">
            <div className="inline-block min-w-full py-2 align-middle">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th scope="col">&nbsp;</th>
                            <th
                                scope="col"
                                className="py-3.5 pl-10 w-12 text-left text-sm font-semibold text-gray-900"
                            >
                                &nbsp;
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                                Instituição
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 pr-10 text-right text-sm font-semibold text-gray-900"
                            >
                                Nota
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {data.map((row) => (
                            <tr
                                key={row.companyId}
                                className="hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleRowClick(row.slug)}
                            >
                                <td className="pt-2 text-idwallDarkPurple-100 hover:text-idwallDarkPurple-500 text-right space-x-2">
                                    {row.awards &&
                                        row.awards.map((award, index) => (
                                            <span
                                                data-tooltip={award}
                                                key={index}
                                            >
                                                <FaTrophy />
                                            </span>
                                        ))}
                                </td>
                                <td className="whitespace-nowrap py-3 px-2 w-12 text-sm font-semibold text-gray-900 text-right">
                                    <div className="flex items-center min-w-0 gap-x-4 text-right">
                                        {row.position}º
                                    </div>
                                </td>
                                <td className="whitespace-nowrap py-3 px-3 text-sm font-medium text-gray-900">
                                    <div className="flex items-center gap-x-4">
                                        <UI.Icon32
                                            className="flex-none h-8 w-8"
                                            slug={row.slug}
                                        />
                                        <div className="truncate text-sm font-medium leading-6 text-gray-500">
                                            {row.name}
                                        </div>
                                    </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-3 pr-10 w-12 text-sm text-idwallDarkPurple-700 font-semibold text-right">
                                    {row.score.toFixed(2)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
