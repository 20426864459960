import { user, favoritesIds } from '../signals';
const { post } = require('../utils/api/api_caller_utils');

export const addFavorite = async (companyId) => {
    try {
        const response = await post(`/users/favorites/add/${companyId}`);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error adding favorite:', error);
    }
};

export const removeFavorite = async (companyId) => {
    try {
        const response = await post(`/users/favorites/remove/${companyId}`);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error removing favorite:', error);
    }
};

export const toggleFavorite = (companyId) => {
    if (favoritesIds.value.includes(companyId)) {
        removeFavorite(companyId).then((response) => {
            if (response) {
                user.value = {
                    ...user.value,
                    favorites: response
                };
            }
        });
    } else {
        addFavorite(companyId).then((response) => {
            if (response) {
                user.value = {
                    ...user.value,
                    favorites: response
                };
            }
        });
    }
};
