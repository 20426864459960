import { Fragment } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { autocompleteList, searchQuery, searchIsOpen } from '../../signals';
import { UI } from '..';
import { CompanyService } from '../../services';
import Fuse from 'fuse.js';
import { classNames } from '../../helpers';

const handleSelection = (item) => {
    searchIsOpen.value = false;
    const searchForm = document.getElementById('search-form');
    searchForm.action = `/${item.slug}`;
    searchForm.submit();
};

const handleChange = (event) => {
    searchQuery.value = event.target.value;
};

const emptyQuery = () => {
    searchQuery.value = '';
};

const closeSearch = () => {
    searchIsOpen.value = false;
};

const search = (query) => {
    const options = {
        includeScore: true,
        keys: [
            { name: 'name', weight: 0.8 },
            { name: 'bcbConglomerateName', weight: 0.3 },
            { name: 'bcbInstitutionName', weight: 0.3 },
            { name: 'url', weight: 0.3 }
        ]
    };

    if (!autocompleteList.value) return [];

    const fuse = new Fuse(autocompleteList.value, options);
    return fuse.search(query).map((result) => result.item);
};

const loadAutocomplete = async () => {
    try {
        autocompleteList.value = await CompanyService.fetchAutocomplete();
    } catch (error) {}
};

loadAutocomplete();

export default function SearchBox() {
    const results = search(searchQuery.value);

    return (
        <Transition.Root
            show={searchIsOpen.value}
            as={Fragment}
            afterLeave={emptyQuery}
            appear
        >
            <Dialog as="div" className="relative z-30" onClose={closeSearch}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                            <Combobox onChange={handleSelection}>
                                <form id="search-form" method="get">
                                    <div className="relative">
                                        <MagnifyingGlassIcon
                                            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <Combobox.Input
                                            id="search-input"
                                            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                            placeholder="Buscar instituição..."
                                            onChange={handleChange}
                                        />
                                    </div>
                                </form>

                                {results.length > 0 && (
                                    <Combobox.Options
                                        static
                                        className="max-h-[600px] transform-gpu scroll-py-3 overflow-y-auto p-3"
                                    >
                                        {results.map((item) => (
                                            <Combobox.Option
                                                key={item.slug}
                                                value={item}
                                                className={({ active }) =>
                                                    classNames(
                                                        'flex cursor-pointer select-none rounded-xl p-3',
                                                        active && 'bg-gray-100'
                                                    )
                                                }
                                            >
                                                {({ active }) => (
                                                    <>
                                                        <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg">
                                                            <UI.Icon32
                                                                slug={item.slug}
                                                                className="h-8 w-8"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                        <div className="ml-4 flex-auto">
                                                            <p
                                                                className={classNames(
                                                                    'text-sm font-medium',
                                                                    active
                                                                        ? 'text-gray-900'
                                                                        : 'text-gray-700'
                                                                )}
                                                            >
                                                                {item.name}
                                                            </p>
                                                            {item.bcbInstitutionName && (
                                                                <p
                                                                    className={classNames(
                                                                        'text-xs',
                                                                        active
                                                                            ? 'text-gray-700'
                                                                            : 'text-gray-500'
                                                                    )}
                                                                >
                                                                    Instituição{' '}
                                                                    {
                                                                        item.bcbInstitutionName
                                                                    }
                                                                </p>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}

                                {searchQuery.value !== '' &&
                                    results.length === 0 && (
                                        <div className="px-6 py-14 text-center text-sm sm:px-14">
                                            <ExclamationCircleIcon
                                                type="outline"
                                                name="exclamation-circle"
                                                className="mx-auto h-6 w-6 text-gray-400"
                                            />
                                            <p className="mt-4 font-semibold text-gray-900">
                                                Nenhuma instituição encontrada.
                                            </p>
                                            <p className="mt-2 text-gray-500">
                                                Tente usar termos diferentes.
                                            </p>
                                        </div>
                                    )}
                                {searchQuery.value !== '' && (
                                    <div className="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
                                        Não encontrou alguma instituição no
                                        Index?{' '}
                                        <a
                                            href="mailto:index@idwall.co"
                                            className="ml-2 underline"
                                        >
                                            Entre em contato conosco.
                                        </a>
                                    </div>
                                )}
                            </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
