import { UI, Layout } from '..';
import { modalCTADashboardComparison } from '../../signals';

const Content = () => {
    return (
        <div className="relative isolate">
            <div className="mx-auto max-w-7xl -my-10">
                <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                    <div className="w-full flex-auto">
                        <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                            Desbloqueie a análise comparativa de todos os
                            participantes do Ranking 2024.
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-700">
                            Transforme a maneira como você compreende o mercado
                            financeiro digital com insights aprofundados e dados
                            confiáveis.
                        </p>
                        <div className="mt-10 place-content-center">
                            <UI.Button as="a" href="/contato" size="xl">
                                <span className="px-10">Fale conosco</span>
                            </UI.Button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
                aria-hidden="true"
            >
                <div
                    className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#00CEFF] to-[#5500FF] opacity-25"
                    style={{
                        clipPath:
                            'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)'
                    }}
                />
            </div>
        </div>
    );
};

const closeModal = () => {
    modalCTADashboardComparison.value = false;
};

export default function CTADashboardComparison() {
    return (
        <Layout.Modal
            show={modalCTADashboardComparison.value}
            showFooter={false}
            onClose={closeModal}
        >
            <Content />
        </Layout.Modal>
    );
}
