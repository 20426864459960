import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Notification } from '..';
import logoColor from '../../svg/index-logo-color.svg';
import logo from '../../svg/index-logo-white.svg';
import SignupForm from './SignupForm';

export default function Signup() {
    const [showNotification, setShowNotification] = useState(false); // Uso de useState
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Essa função agora está dentro de useEffect para evitar re-renderizações infinitas
        const msg = searchParams.get('msg');
        if (msg === 'notfound') {
            setShowNotification(true);
        }
    }, [searchParams]); // Dependências do useEffect, executado apenas quando searchParams muda

    function toggleNotification() {
        setShowNotification(!showNotification);
    }

    return (
        <>
            <Notification
                show={showNotification}
                onClose={toggleNotification}
                title="Usuário não encontrado!"
                body="Por favor, faça seu cadastro."
                Icon={ExclamationCircleIcon}
                iconColor="text-red-400"
                bgColor="bg-red-50"
            />
            <div className="flex min-h-full flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-8 lg:hidden">
                        <img
                            className="mx-auto h-24 w-auto"
                            src={logoColor}
                            alt="Index by idwall"
                        />
                    </div>
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                    <h2 className="text-xl font-semibold leading-9 tracking-tight text-gray-600 text-center">
                        Crie sua conta
                    </h2>
                        <SignupForm />
                        <p className="text-xs mt-4 text-gray-600">
                            Ao criar sua conta, você reconhece que leu e
                            concordou com nossos{' '}
                            <a
                                tabIndex="-1"
                                className="font-semibold hover:underline"
                                target="_blank"
                                href="/termos-de-uso"
                            >
                                Termos de Uso
                            </a>{' '}
                            e nossa{' '}
                            <a
                                tabIndex="-1"
                                className="font-semibold hover:underline"
                                target="_blank"
                                href="https://idwall.co/pt-BR/politica-de-privacidade/"
                                rel="noreferrer"
                            >
                                Política de Privacidade
                            </a>
                            .
                        </p>
                        <p className="mt-6 text-center text-sm text-gray-500">
                            Já é membro?{' '}
                            <a
                                href="/login"
                                className="font-semibold leading-6 text-idwallDarkPurple-600 hover:text-idwallDarkPurple-500"
                                tabIndex="-1"
                            >
                                Faça login
                            </a>
                        </p>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block bg-idwallDarkPurple">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="/img/promo-bg.jpg"
                        alt=""
                    />
                    <div className="absolute inset-0 flex flex-col items-start justify-center space-y-12">
                        <img
                            className="ml-20 w-56 h-auto mb-16"
                            src={logo}
                            alt="Index by idwall"
                        />
                        <h2 className="px-20 text-3xl font-semibold tracking-tight text-white sm:text-4xl">
                            Amplie sua visão.
                            <br />
                            Melhore sua tomada de decisão.
                        </h2>
                        <p className="px-20 max-w-[700px] text-lg leading-8 text-white">
                            Transforme a maneira como você compreende o mercado
                            financeiro digital com insights aprofundados e dados
                            confiáveis.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
