import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Form from './Form';
import ReportFormData from './ReportFormData';

const report = signal({});
const companies = signal([]);

export default function AdminReportDetails() {
    const { reportId } = useParams();

    const fetchData = useCallback(async () => {
        try {
            report.value = await AdminService.getReport(reportId);
            companies.value = await AdminService.getCompanies();
            pageTitle.value = `Report ${report.value.reportId} details`;
        } catch (error) {
            console.error('Failed to load report details:', error);
        }
    }, [reportId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const updateReport = async (reportId, data) => {
        try {
            report.value = await AdminService.updateReport(reportId, data);
            console.log('Updated report:', report.value);
            alert('Report updated successfully!');
        } catch (error) {
            console.error(error);
            alert('Failed to update report!');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateReport(reportId, report.value);
    };

    const formFields = ReportFormData(report.value, companies.value);

    return (
        <Form fields={formFields} handleSubmit={handleSubmit} signal={report} />
    );
}
