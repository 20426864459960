import { ResponsiveBump } from '@nivo/bump';
import { getCompanyColor } from '../../styles';

const companies = [
    {
        id: 20,
        name: 'Nubank'
    },
    {
        id: 4,
        name: 'Inter'
    },
    {
        id: 5,
        name: 'Original'
    },
    {
        id: 12,
        name: 'C6 Bank'
    },
    {
        id: 19,
        name: 'Next'
    },
    {
        id: 18,
        name: 'Neon'
    }
];

const companiesColors = companies.map((company) => {
    return getCompanyColor(company.name);
});

function generateRandomCompanyRanking(companies, startYear, endYear) {
    const rankings = companies.map((company) => ({ id: company.id, name: company.name, data: [] }));

    for (let year = startYear; year <= endYear; year++) {
        const shuffledCompanies = companies.sort(() => Math.random() - 0.5);

        shuffledCompanies.forEach((company, index) => {
            const companyData = rankings.find((c) => c.id === company.id);
            companyData.data.push({ x: year, y: index + 1 });
        });
    }

    return rankings;
}

const data = generateRandomCompanyRanking(companies, 2020, 2024);

// To simulate a company that entered the ranking later,
// change records with years (x) 2020 and 2021 where id = "Next" to null
const filteredData = data.map((companyData) => ({
    ...companyData,
    data: companyData.data.map((record) => {
        if ((record.x === 2020 || record.x === 2021) && companyData.id === 'Next') {
            return { ...record, y: null };
        }
        return record;
    })
}));

const RankingBump = () => (
    <ResponsiveBump
        data={filteredData}
        onClick={(data, event) => {
            window.open(`/${data.id}`, '_blank');
        }}
        colors={companiesColors}
        lineWidth={10}
        activeLineWidth={10}
        inactiveLineWidth={5}
        inactiveOpacity={0.5}
        pointSize={10}
        activePointSize={16}
        inactivePointSize={0}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={3}
        activePointBorderWidth={3}
        pointBorderColor={{ from: 'serie.color' }}
        axisTop={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -36
        }}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        endLabel={(d) => d.name}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'ranking',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
        axisRight={null}
    />
);

export default RankingBump;
