const { get } = require('../utils/api/api_caller_utils');

export const fetchCompanies = async ({
    page,
    sort,
    order,
    type,
    name,
    verified
}) => {
    try {
        const params = new URLSearchParams();

        if (page != null) {
            params.append('page', page);
        }
        if (sort != null) {
            params.append('sort', sort);
        }
        if (order != null) {
            params.append('order', order);
        }
        params.append('limit', 40);
        if (name != null) {
            params.append('name', name);
        }
        if (type && type.length > 0) {
            type.forEach((typeValue) => {
                params.append('type', typeValue);
            });
        }
        if (verified != null) {
            params.append('verified', verified);
        }

        const url = `/companies/list?${params.toString()}`;
        const response = await get(url);

        const { companies, limit, total } = JSON.parse(response);

        const results = {
            companies,
            total,
            page,
            limit,
            order,
            sort
        };

        if (page * limit < total) {
            results.next = { page: page + 1 };
        }

        if ((page - 1) * limit > 0) {
            results.previous = { page: page - 1 };
        }

        return results;
    } catch (error) {
        console.error('Error fetching companies: ', error);
    }
};

export const fetchCompanyDetails = async (id) => {
    try {
        const response = await get(`/companies/${id}`);
        return JSON.parse(response);
    } catch (error) {
        console.error(`Error fetching company ${id}: `, error);
    }
};

export const fetchCompanyDetailsByName = async (slug) => {
    try {
        const response = await get(`/companies/name/${slug}`);
        return JSON.parse(response);
    } catch (error) {
        console.error(`Error fetching company ${slug}: `, error);
    }
};

export const fetchAutocomplete = async () => {
    try {
        const response = await get('/companies/autocomplete');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching autocomplete', error);
    }
};

export const fetchCompanyTypes = async () => {
    try {
        const response = await get('/companies/types');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching company types', error);
    }
};
