import { format } from 'd3-format';

// Função para formatar números no formato 1k, 1M, etc.
export const formatNumbers = (value) => {
    if (value >= 1000) {
        return format('~s')(value);
    }
    return value;
};

export const primaryColor = '#7d4dcf';
export const colorSchemeSequential = [
    '#355070',
    '#6d597a',
    '#b56576',
    '#e56b6f',
    '#eaac8b'
];
export const colorSchemeBlue = [
    '#005069',
    '#0A758F',
    '#0DA3C6',
    '#00CEFF',
    '#6AC1E1'
];

// Uma função que retorna as cores mais diferentes possíveis dentro do array colorSchemeSequential
// Ex: se o número de steps for 2, deve retornar a primeira e ultima
// Ex: se o número de steps for 3, deve retornar a primeira, a ultima e a do meio
export function getChartColors(steps = colorSchemeBlue.length) {
    if (steps <= 1) {
        return colorSchemeBlue.slice(0, 1);
    } else if (steps >= colorSchemeBlue.length) {
        return colorSchemeBlue;
    } else {
        const stepSize = Math.floor(colorSchemeBlue.length / (steps - 1));
        const colors = [];
        for (let i = 0; i < colorSchemeBlue.length; i += stepSize) {
            colors.push(colorSchemeBlue[i]);
        }
        // Garantir que a última cor seja sempre incluída
        if (colors.length > steps) {
            colors.pop(); // Remove o penúltimo se for muito longo
        }
        if (!colors.includes(colorSchemeBlue[colorSchemeBlue.length - 1])) {
            colors.push(colorSchemeBlue[colorSchemeBlue.length - 1]); // Adiciona a última cor
        }
        return colors;
    }
}

// https://github.com/plouc/nivo/blob/master/packages/core/index.d.ts
const textStyle = {
    fontFamily: 'Fellix',
    fontSize: 12,
    fill: '#000000',
    outlineWidth: 0,
    outlineColor: 'transparent',
    fontWeight: 'normal'
};

export const chartTheme = {
    background: '#ffffff',
    text: textStyle,
    axis: {
        domain: {
            line: {
                stroke: '#777777',
                strokeWidth: 1
            }
        },
        legend: {
            text: textStyle
        },
        ticks: {
            line: {
                stroke: '#777777',
                strokeWidth: 1
            },
            text: textStyle
        }
    },
    grid: {
        line: {
            stroke: '#dddddd',
            strokeWidth: 1
        }
    },
    legends: {
        title: {
            text: textStyle
        },
        text: textStyle,
        ticks: {
            line: {},
            text: textStyle
        }
    },
    annotations: {
        text: textStyle,
        link: {
            stroke: '#000000',
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: '#ffffff',
            outlineOpacity: 1
        },
        outline: {
            stroke: '#000000',
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: '#ffffff',
            outlineOpacity: 1
        },
        symbol: {
            fill: '#000000',
            outlineWidth: 2,
            outlineColor: '#ffffff',
            outlineOpacity: 1
        }
    },
    tooltip: {
        container: {
            background: '#ffffff',
            fontSize: 12
        },
        basic: {},
        chip: {},
        table: {},
        tableCell: {},
        tableCellValue: {}
    },
    labels: {
        text: textStyle
    }
};

// Obs: Cores não oficiais. Sempre versão mais escura.
const companyColors = {
    Agibank: '#363636',
    Bradesco: '#C71530',
    'Banco do Brasil': '#0038A8',
    'Banco Inter': '#FF7408',
    Inter: '#FF7408',
    'Banco Original': '#069D4E',
    Original: '#069D4E',
    'Banco Pan': '#12ACFC',
    Banrisul: '#050247',
    BMG: '#F05500',
    'BTG Pactual': '#091324',
    'BV Financeira': '#5660B9',
    'Caixa Econômica Federal': '#0463AB',
    'C6 Bank': '#252424',
    C6: '#252424',
    Digio: '#0A2A59',
    Itau: '#FF5C08',
    Itaú: '#FF5C08',
    Iti: '#FF3182',
    'Mercado Pago': '#08B5FE',
    Modal: '#378D80',
    Neon: '#1187FF',
    Next: '#00FF54',
    Nubank: '#8200D4',
    PagBank: '#D9D84A',
    Paypal: '#083180',
    PicPay: '#0A9B46',
    Santander: '#FE080E',
    Sicoob: '#0A353D',
    Sicredi: '#3D9A18',
    'Sofisa Direto': '#08A289',
    Safra: '#1D2342',
    Superdigital: '#500876',
    'XP Investimentos': '#1A1A1A',
    'Will Bank': '#E2AD0E',
    'Banco Bari': '#0C60FF',
    N26: '#0C60FF',
    Nomad: '#FFD00C',
    'Claro Pay': '#D32926',
    '99 Pay': '#FFC508',
    'Paraná Banco': '#3460FF',
    'Vivo Pay': '#600892'
};

// Fallback must be a random color from array provided
export const getCompanyColor = (companyName, fallback) => {
    const companyColor = companyColors[companyName];
    return (
        companyColor || fallback[Math.floor(Math.random() * fallback.length)]
    );
};
