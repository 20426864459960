import { ModalInfo } from '../../../components';
import { modalTCB, modalCidade, modalTI, modalTC, modalChartReclamacoes, modalChartClientes } from '../../../signals';
import { tcbType, tcType, tiType, clientesType, reclamacoesType } from './Domains';

function closeModalTCB() {
    modalTCB.value = false;
}
function closeModalCidade() {
    modalCidade.value = false;
}
function closeModalTI() {
    modalTI.value = false;
}
function closeModalTC() {
    modalTC.value = false;
}
function closeModalChartReclamacoes() {
    modalChartReclamacoes.value = false;
}
function closeModalChartClientes() {
    modalChartClientes.value = false;
}

export default function Modals() {
    return (
        <>
            <ModalInfo show={modalChartReclamacoes.value} onClose={closeModalChartReclamacoes} title="Reclamações BACEN">
                <table className="mt-10">
                    <tbody className="bg-white divide-y divide-gray-200">
                        {reclamacoesType.map((row, idx) => (
                            <tr key={idx}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">{row.title}</td>
                                <td className="px-6 py-4 whitespace-normal text-sm text-gray-500">{row.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ModalInfo>
            <ModalInfo show={modalChartClientes.value} onClose={closeModalChartClientes} title="Quantidade de Clientes BACEN">
                <table className="mt-10">
                    <tbody className="bg-white divide-y divide-gray-200">
                        {clientesType.map((row, idx) => (
                            <tr key={idx}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-right">{row.title}</td>
                                <td className="px-6 py-4 whitespace-normal text-sm text-gray-500">{row.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ModalInfo>
            <ModalInfo show={modalCidade.value} onClose={closeModalCidade} title="Cidade / UF">
                Cidade e Unidade da Federação onde fica localizada a sede da instituição de acordo com o cadastro do Banco Central do Brasil.
            </ModalInfo>
            <ModalInfo show={modalTCB.value} onClose={closeModalTCB} title="Tipo de Consolidado Bancário (TCB)">
                <div className="text-sm text-gray-500">
                    <ul className="list-disc pl-3 mt-5 space-y-2">
                        {Object.keys(tcbType).map((key) => {
                            return (
                                <li key={key}>
                                    <strong>{key.toUpperCase()}:</strong> {tcbType[key]}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </ModalInfo>
            <ModalInfo show={modalTI.value} onClose={closeModalTI} title="Tipo de Instituição (TI)">
                <div className="text-sm text-gray-500">
                    <ul className="list-disc pl-3 mt-5 space-y-2">
                        {Object.keys(tiType).map((key) => {
                            return (
                                <li key={key}>
                                    <strong>{key.toUpperCase()}:</strong> {tiType[key]}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </ModalInfo>

            <ModalInfo show={modalTC.value} onClose={closeModalTC} title="Tipo de Controle (TC)">
                <div className="text-sm text-gray-500">
                    <span>
                        Identifica a origem do controle de capital dos conglomerados bancários ou TC das instituições independentes, com a seguinte
                        representação:
                    </span>
                    <ul className="list-disc pl-3 mt-5 space-y-2">
                        {Object.keys(tcType).map((key) => {
                            return (
                                <li key={key}>
                                    <strong>{key.toUpperCase()}:</strong> {tcType[key]}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </ModalInfo>
        </>
    );
}
