const data = {
    27: 'Tradicional',
    31: 'Investimento',
    32: 'Tradicional',
    35: 'Tradicional',
    38: 'Investimento',
    39: 'Tradicional',
    40: 'Digital',
    71: 'Digital',
    88: 'Digital',
    98: 'Tradicional',
    102: 'Investimento',
    114: 'Investimento',
    125: 'Tradicional',
    126: 'Digital',
    132: 'Tradicional',
    133: 'Tradicional',
    135: 'Digital',
    136: 'Digital',
    137: 'Digital',
    138: 'Digital',
    139: 'Digital',
    140: 'Digital',
    142: 'Digital',
    143: 'Digital',
    146: 'Digital',
    149: 'Digital'
};

export default {
    data
};
