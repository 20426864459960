import { useEffect, useCallback } from 'react';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Table from './Table';

const list = signal([]);

export default function AdminBcbInstitutionsList() {
    pageTitle.value = 'Instituições BACEN';

    const fetchData = useCallback(async () => {
        try {
            list.value = await AdminService.getBcbConglomerates();
            console.log('list:', list.value);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const headers = [
        'ID',
        'namePrudential',
        'codePrudential',
        'nameFinancial',
        'codeFinancial',
        'name'
    ];

    const rows = list.value.map((item, index) => [
        item.bcbConglomerateId,
        item.namePrudential,
        item.codePrudential,
        item.nameFinancial,
        item.codeFinancial,
        item.name
    ]);

    return <Table headers={headers} rows={rows} />;
}
