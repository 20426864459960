/* eslint-disable object-curly-spacing */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './icons-sprites.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider>
        <App />
    </HelmetProvider>
);

function sendToGoogleAnalytics({ name, delta, value, id }) {
    // eslint-disable-next-line no-undef
    gtag('event', name, {
        value: delta,
        metric_id: id,
        metric_value: value,
        metric_delta: delta
    });
}

reportWebVitals(sendToGoogleAnalytics);
