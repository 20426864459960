// http://localhost:4321/auth/linkedin?code=AQRzns8fg9NphPSTQFxY99vVBvfGFQENT_MGKIesfYD2gbJ897KRUb-np8FaoZRGpYgTk_S60UezI2tyuRpG1k2YxhB0TlQCvhBJ8UmiKai-QBPwufiq7a3FxgT9gQz_kScYcSj2Ab_K_i1TIsSUa0nzw08aw8OVisMB4oumSpP8gZEWBwY6lrWgAIsKOhvRrgmJnWGSawmHDYXFgs0&state=123456

import React, { useEffect } from 'react';
import { AuthService } from '../../services';
import { signal } from '@preact/signals-react';

const LinkedInApi = {
    clientId: '77yzzgx7yyeiz1',
    redirectUrl: 'http://localhost:3000/linkedin',
    oauthUrl: 'https://www.linkedin.com/oauth/v2/authorization?response_type=code',
    scope: 'openid profile email',
    state: '123456'
};

const initialState = signal({
    user: {},
    loggedIn: false
});

export default function LoginLinkedin() {
    useEffect(() => {
        const { loggedIn } = initialState.value;

        if (!loggedIn) {
            if (window.opener && window.opener !== window) {
                const code = getCodeFromWindowURL(window.location.href);
                window.opener.postMessage({ type: 'code', code }, '*');
                window.close();
            }
            window.addEventListener('message', handlePostMessage);
        }
    }, []);

    const getCodeFromWindowURL = (url) => {
        const popupWindowURL = new URL(url);
        return popupWindowURL.searchParams.get('code');
    };

    const handlePostMessage = (event) => {
        if (event.data.type === 'code') {
            const { code } = event.data;
            getUserCredentials(code);
        }
    };

    const showPopup = () => {
        const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
        const url = `${oauthUrl}&client_id=${clientId}&scope=${encodeURIComponent(scope)}&state=${state}&redirect_uri=${encodeURIComponent(redirectUrl)}`;

        const width = 450;
        const height = 730;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        window.open(
            url,
            'LinkedIn',
            'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left
        );
    };

    const getUserCredentials = async (code) => {
        try {
            const response = await AuthService.linkedInGetCredentials(code);
            console.log('response', response);
            if (response.user) {
                initialState.value = {
                    user: response.user,
                    loggedIn: true
                };
            }
        } catch (error) {
            console.error('Error fetching user credentials:', error);
        }
    };

    const { loggedIn, user } = initialState.value;

    const contentWhenLoggedIn = (
        <>
            <img src={user.picture} alt="Profile image" />
            <h3>{user.name}</h3>
            <h3>{user.email}</h3>
        </>
    );

    const contentWhenLoggedOut = (
        <>
            <button onClick={showPopup}>Sign in with LinkedIn</button>
        </>
    );

    return <div>{loggedIn && user !== {} ? contentWhenLoggedIn : contentWhenLoggedOut}</div>;
}
