import { ResponsiveHeatMap } from '@nivo/heatmap';

function convertData(inputData) {
    // Identificar todos os bancos únicos nos dados de entrada
    const banks = [
        ...new Set(
            inputData.flatMap((item) =>
                Object.keys(item).filter((key) => key !== 'dimension')
            )
        )
    ];

    // Mapear cada banco para a nova estrutura de dados
    const outputData = banks.map((bank) => ({
        id: bank,
        data: inputData.map((entry) => ({
            x: entry.dimension,
            y: entry[bank]
        }))
    }));

    return outputData;
}

export default function ComparisonChartHeatmap({ data }) {
    const convertedData = convertData(data);

    return (
        <ResponsiveHeatMap
            data={convertedData}
            margin={{ top: 60, right: 90, bottom: 60, left: 90 }}
            valueFormat=">-.2s"
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legend: '',
                legendOffset: 46
            }}
            axisRight={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'country',
                legendPosition: 'middle',
                legendOffset: 70
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'country',
                legendPosition: 'middle',
                legendOffset: -72
            }}
            colors={{
                type: 'diverging',
                scheme: 'red_yellow_green',
                divergeAt: 0.5,
                minValue: 0,
                maxValue: 100
            }}
            emptyColor="#555555"
            legends={[
                {
                    anchor: 'bottom',
                    translateX: 0,
                    translateY: 30,
                    length: 400,
                    thickness: 8,
                    direction: 'row',
                    tickPosition: 'after',
                    tickSize: 3,
                    tickSpacing: 4,
                    tickOverlap: false,
                    tickFormat: '>-.2s',
                    title: 'Value →',
                    titleAlign: 'start',
                    titleOffset: 4
                }
            ]}
        />
    );
}
