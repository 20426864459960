import { UI } from '../../components';
import { classNames, preetyAppType } from '../../helpers';
import { Link, useParams } from 'react-router-dom';
import { currentCompany } from '../../signals';
import { IoIosAppstore, IoLogoAndroid } from 'react-icons/io';

export default function AppsTabs({ children }) {
    const { appId } = useParams();

    const apps = currentCompany.value.apps.map((app) => {
        const currentUrl = window.location.pathname;
        const url = appId
            ? `${currentUrl.substring(0, currentUrl.lastIndexOf('/'))}/${
                  app.appId
              }`
            : `${currentUrl}/${app.appId}`;
        return { ...app, url };
    });

    return (
        <div className="mx-auto">
            <div className="bg-gray-200 p-5">
                <div className="mx-auto max-w-7xl">
                    <nav className="flex space-x-5" aria-label="Tabs">
                        {apps.map((app) => (
                            <Link
                                key={app.appId}
                                to={app.url}
                                className={classNames(
                                    app.appId === parseInt(appId)
                                        ? 'bg-gray-100 text-gray-800'
                                        : 'text-gray-600 hover:bg-gray-300',
                                    'flex space-x-6 rounded-md py-4 px-6 text-sm font-medium'
                                )}
                            >
                                <img
                                    src={
                                        app.iconLowURL ||
                                        `/img/store-placeholder-${app.type}.png`
                                    }
                                    alt=""
                                    className="h-12 w-12 rounded-xl"
                                />
                                <div>
                                    <UI.H2>
                                        {app.name || preetyAppType(app.type)}
                                    </UI.H2>
                                    <h3 className="text-md font-medium text-gray-700">
                                        {app.type === 'ios' && (
                                            <IoIosAppstore className="text-xl inline-block mr-1" />
                                        )}
                                        {app.type === 'android' && (
                                            <IoLogoAndroid className="text-xl inline-block mr-1" />
                                        )}
                                        <span>{preetyAppType(app.type)}</span>
                                    </h3>
                                </div>
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>
            {children}
        </div>
    );
}
