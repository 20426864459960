/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AppService } from '../../../services';
import { signal } from '@preact/signals-react';
import { UI, Reviews, Company, Layout } from '../../../components';
import { currentCompany } from '../../../signals';
import { preetyAppType } from '../../../helpers';

const showModal = signal(false);

const closeModal = () => {
    showModal.value = false;
};

const openModal = () => {
    showModal.value = true;
};

const AppReviews = ({ reviews }) => {
    return (
        <div className="space-y-8 lg:sticky top-20">
            <UI.H2>Avaliações na store</UI.H2>
            <Reviews.Summary reviews={reviews} />
            {/* <UI.H2>Menções</UI.H2>
            <Reviews.WordCloud /> */}
        </div>
    );
};

const AppReviewsEmpty = ({ reviews }) => {
    return (
        <div className="space-y-8">
            <UI.H2>Avaliações na store</UI.H2>
            <p className="italic">Nenhuma avaliação encontrada.</p>
        </div>
    );
};

const EmptyState = () => {
    return (
        <div className="text-center py-36">
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
                Nenhum app selecionado
            </h3>
            <p className="mt-1 text-sm text-gray-500">Selecione acima</p>
        </div>
    );
};

export default function ReviewsDetails() {
    const [isLoading, setIsLoading] = useState(true);
    const [reviews, setReviews] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const { appId } = useParams();
    const company = currentCompany.value;
    const { companyId } = company;

    const loadReviews = useCallback(
        async (page) => {
            try {
                if (companyId && appId) {
                    const r = await AppService.fetchReviews({
                        companyId,
                        appId,
                        page
                    });
                    setReviews(r);
                }
            } catch (error) {
                console.error('Failed to load company details:', error);
            } finally {
                setIsLoading(false);
            }
        },
        [companyId, appId, currentPage]
    );

    useEffect(() => {
        loadReviews(currentPage);
    }, [loadReviews]);

    const handlePageChange = async (newPage) => {
        setCurrentPage(newPage);
    };

    function Modal({ reviews }) {
        return (
            <Layout.Modal
                show={showModal.value}
                onClose={closeModal}
                title={`${reviews.name} - ${preetyAppType(reviews.appType)}`}
            >
                <Reviews.List reviews={reviews.reviews} />
                <Layout.Pagination
                    page={reviews.page}
                    limit={reviews.pageSize}
                    total={reviews.total}
                    onPageChange={handlePageChange}
                />
            </Layout.Modal>
        );
    }

    if (!appId) {
        return <EmptyState />;
    }

    if (isLoading) {
        return <Company.StateLoading />;
    }

    return (
        <>
            <Layout.Head title={`Index - ${company.name}: App Reviews`} />
            <main>
                <div className="mx-auto max-w-7xl py-8 px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-10 gap-8">
                        <div className="col-span-10 lg:col-span-4 p-8">
                            {reviews && <AppReviews reviews={reviews} />}
                            {!reviews && <AppReviewsEmpty />}
                        </div>
                        <div className="col-span-10 lg:col-span-6 p-8">
                            {reviews.reviews && (
                                <>
                                    <Reviews.List reviews={reviews.reviews} />
                                    <Layout.Pagination
                                        page={reviews.page}
                                        limit={reviews.pageSize}
                                        total={reviews.total}
                                        onPageChange={handlePageChange}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
