import { Helmet } from 'react-helmet-async';
import { user } from '../../signals';

export default function Head({ title, children }) {
    const loggedUser = user.value;
    let userId = null;

    if (loggedUser.isLoggedIn) {
        userId = loggedUser.id;
    }

    return (
        <Helmet>
            {title && <title>{title}</title>}
            <link rel="canonical" href="https://idwall.co/" />
            {userId && (
                <script type="text/javascript">{`gtag('config', 'G-X95J5LLKC6', {'user_id': '${userId}' });`}</script>
            )}
            {!userId && (
                <script type="text/javascript">
                    {"gtag('config', 'G-X95J5LLKC6');"}
                </script>
            )}
            {children}
        </Helmet>
    );
}
