/* eslint-disable no-unused-vars */
import { useEffect, useState, useCallback } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { PaymentService } from '../../services';
import { signal } from '@preact/signals-react';
import { loadStripe } from '@stripe/stripe-js';

const clientSecret = signal(null);

function Payment() {
    const stripePromise = loadStripe('pk_test_51OWfvPJOnwsmDP6ThgguhnNfcwd7gc6n2almIVSyS0N1GHOEYjhRSboFCU3qlUaTJBXrlamyKw4GIcv5Gblxo5eD00bVX1CL04');
    const companyId = 1;
    const amount = 100;
    const description = 'Test payment';

    const createPaymentIntent = useCallback(async () => {
        try {
            const intent = await PaymentService.createPaymentIntent({ companyId, amount, description });
            clientSecret.value = intent.client_secret;
            console.log('Payment intent created', intent);
        } catch (error) {
            console.error('Failed to create payment intent:', error);
        }
    }, []);

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        createPaymentIntent();
    }, []);

    const options = {
        clientSecret: clientSecret.value
    };

    return (
        <>
            {clientSecret.value && stripePromise && (
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm />
                </Elements>
            )}
        </>
    );
}

export default Payment;
