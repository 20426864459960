import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Form from './Form';
import ExpScoreFormData from './ExpScoreFormData';

const expScore = signal({});
const companies = signal([]);
const expCriterias = signal([]);
const expJourneys = signal([]);

export default function AdminExpScoreDetails() {
    const { expScoreId } = useParams();

    const fetchData = useCallback(async () => {
        try {
            expScore.value = await AdminService.getExpScore(expScoreId);
            companies.value = await AdminService.getCompanies();
            expCriterias.value = await AdminService.getExpCriterias();
            expJourneys.value = await AdminService.getExpJourneys();
            pageTitle.value = `Score ${expScore.value.expScoreId} details`;
        } catch (error) {
            console.error('Failed to load score details:', error);
        }
    }, [expScoreId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const updateExpScore = async (expScoreId, data) => {
        try {
            expScore.value = await AdminService.updateExpScore(
                expScoreId,
                data
            );
            console.log('Updated score:', expScore.value);
            alert('Score atualizado com sucesso!');
        } catch (error) {
            console.error(error);
            alert('Erro ao atualizar score!');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        updateExpScore(expScoreId, expScore.value);
    };

    const formFields = ExpScoreFormData(
        expScore.value,
        companies.value,
        expCriterias.value,
        expJourneys.value
    );

    return (
        <Form
            fields={formFields}
            handleSubmit={handleSubmit}
            signal={expScore}
        />
    );
}
