import {
    ArrowLongLeftIcon,
    ArrowLongRightIcon
} from '@heroicons/react/20/solid';

const PaginationLink = ({ page, currentPage, onPageChange, children }) => (
    <a
        href="#"
        onClick={(e) => {
            e.preventDefault();
            onPageChange(page);
        }}
        className={`inline-flex items-center border-t-2 ${
            parseInt(page) === parseInt(currentPage)
                ? 'border-idwallDarkPurple-500 text-idwallDarkPurple-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200'
        } px-4 pt-4 text-sm font-medium`}
    >
        {children}
    </a>
);

export default function CompaniesPagination({
    page = 1,
    limit,
    total,
    onPageChange
}) {
    const totalPages = Math.ceil(total / limit);

    const handlePrevious = (e) => {
        e.preventDefault();
        onPageChange(parseInt(page) - 1);
    };

    const handleNext = (e) => {
        e.preventDefault();
        onPageChange(parseInt(page) + 1);
    };

    return (
        <nav
            className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0"
            aria-label="Pagination"
        >
            <div className="-mt-px flex w-0 flex-1 pl-4">
                {page > 1 && (
                    <a
                        href="#"
                        onClick={handlePrevious}
                        className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                    >
                        <ArrowLongLeftIcon
                            className="mr-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                        Anterior
                    </a>
                )}
            </div>
            <div className="hidden md:-mt-px md:flex">
                {Array.from({ length: totalPages }, (_, i) => (
                    <PaginationLink
                        key={i + 1}
                        page={i + 1}
                        currentPage={page}
                        onPageChange={onPageChange}
                    >
                        {i + 1}
                    </PaginationLink>
                ))}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end pr-4">
                {page < totalPages && (
                    <a
                        href="#"
                        onClick={handleNext}
                        className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-200 hover:text-gray-700"
                    >
                        Próxima
                        <ArrowLongRightIcon
                            className="ml-3 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </a>
                )}
            </div>
        </nav>
    );
}
