import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Layout } from '..';
import { emailRegex, isValidCorporateEmail } from '../../helpers';
import { AuthService } from '../../services';
import { user } from '../../signals';
import logo from '../../svg/index-logo-color.svg';
import logoWhite from '../../svg/index-logo-white.svg';

const inputStyleValid =
    'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-idwallDarkPurple-600 sm:text-sm sm:leading-6';

const inputStyleInvalid =
    'block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-red-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6';

const Label = ({ children, label, errorMsg, isError, ...props }) => {
    return (
        <label
            className={`block text-sm font-medium leading-6 ${
                isError ? 'text-red-500' : 'text-gray-900'
            }`}
            {...props}
        >
            {label || children}{' '}
            {isError && (
                <span className="text-gray-400 text-xs italic pt-1 float-right">
                    {errorMsg}
                </span>
            )}
        </label>
    );
};

export default function Login() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        if (user.value.isLoggedIn) {
            navigate('/');
        }
    }, [navigate]);

    const onSubmit = async (data) => {
        try {
            const { email } = data;
            const response = await AuthService.sendMagicLink(email);
            const { success } = response;

            if (success) {
                navigate('/login/email-enviado?email=' + email, {
                    replace: true
                });
            } else {
                // Usuário não encontrado
                navigate('/cadastro?msg=notfound&email=' + email, {
                    replace: true
                });
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    return (
        <>
        <div className="flex min-h-full flex-1">
            <Layout.Head>
                <script
                    src="https://login.meuid.com.br/sdk/index.js"
                    defer
                ></script>
                <script src="/scripts/meuid.js" defer></script>
            </Layout.Head>
            <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-16">
                    <img
                        className="mx-auto h-24 w-auto"
                        src={logo}
                        alt="Index by idwall"
                    />
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <h2 className="mt-16 text-xl font-semibold leading-9 tracking-tight text-gray-600 text-center">
                            Acesse sua conta
                        </h2>
                    </div>
                </div>

                <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form
                        className="space-y-6"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div>
                            <Label
                                htmlFor="email"
                                errorMsg="O seu email corporativo é obrigatório."
                                isError={errors.email}
                            >
                                Email
                            </Label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    type="email"
                                    autoComplete="email"
                                    placeholder="Email"
                                    className={
                                        errors.email
                                            ? inputStyleInvalid
                                            : inputStyleValid
                                    }
                                    {...register('email', {
                                        required: true,
                                        validate: isValidCorporateEmail,
                                        pattern: emailRegex
                                    })}
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-black px-3 py-2 text-md font-semibold leading-6 text-white shadow-sm hover:bg-black-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black-600 disabled:bg-gray-500"
                            >
                                Enviar link de acesso
                            </button>
                        </div>
                    </form>
                    <p className="mt-6 text-center text-sm text-gray-500">
                        Não é membro?{' '}
                        <a
                            href="/cadastro"
                            className="font-semibold leading-6 text-idwallDarkPurple-600 hover:text-idwallDarkPurple-500"
                        >
                            Crie uma conta grátis
                        </a>
                        .
                    </p>
                    <div className="relative my-6">
                        <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                        >
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <span className="bg-white px-2 text-sm text-gray-500">
                                ou
                            </span>
                        </div>
                    </div>
                    <div id="meuid-login"></div>
                </div>
            </div>
            <div className="relative hidden w-0 flex-1 lg:block bg-idwallDarkPurple">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="/img/promo-bg.jpg"
                        alt=""
                    />
                    <div className="absolute inset-0 flex flex-col items-start justify-center space-y-12">
                        <img
                            className="ml-20 w-56 h-auto mb-16"
                            src={logoWhite}
                            alt="Index by idwall"
                        />
                        <h2 className="px-20 text-3xl font-semibold tracking-tight text-white sm:text-4xl">
                            Amplie sua visão.
                            <br />
                            Melhore sua tomada de decisão.
                        </h2>
                        <p className="px-20 max-w-[700px] text-lg leading-8 text-white">
                            Transforme a maneira como você compreende o mercado
                            financeiro digital com insights aprofundados e dados
                            confiáveis.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
