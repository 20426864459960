const TableHeader = ({ children }) => (
    <th
        scope="col"
        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
    >
        {children}
    </th>
);

const TableColumn = ({ children }) => (
    <td className="px-3 py-2 text-sm text-gray-500 dark:text-gray-300">
        {children}
    </td>
);

export default function AdminTable({ headers, rows }) {
    return (
        <table className="w-full text-left">
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <TableHeader key={index}>{header}</TableHeader>
                    ))}
                </tr>
            </thead>
            <tbody className="bg-gray-700">
                {rows.map((row, index) => (
                    <tr
                        key={index}
                        className="even:bg-gray-600 hover:bg-gray-500"
                    >
                        {row.map((column, index) => (
                            <TableColumn key={index}>{column}</TableColumn>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
