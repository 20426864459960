export function Header1({ className, children, ...props }) {
    return (
        <h1 className={`text-3xl font-semibold tracking-tight text-gray-900 ${className}`} {...props}>
            {children}
        </h1>
    );
}

export function Header2({ className, children, ...props }) {
    return (
        <h2 className={`text-xl font-semibold tracking-tight text-gray-900 ${className}`} {...props}>
            {children}
        </h2>
    );
}
