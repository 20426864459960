import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { chartTheme, getChartColors } from '../../styles';

const DemographicPyramid = ({ data }) => (
    <ResponsiveBar
        data={data}
        keys={['masculino', 'feminino']}
        indexBy="age"
        layout="horizontal"
        padding={0.3}
        margin={{ top: 20, right: 80, bottom: 50, left: 80 }}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={getChartColors(2)}
        colorBy="id"
        axisTop={null}
        valueFormat={(value) => Math.abs(value)}
        axisRight={{
            format: (value) => value
        }}
        axisBottom={null}
        axisLeft={{
            format: (value) => value
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 30,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={chartTheme}
    />
);

const ChartEscolaridade = ({ data }) => (
    <ResponsivePie
        data={data}
        colors={getChartColors(data.length)}
        margin={{ top: 40, right: 80, bottom: 80, left: 120 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        arcLinkLabel="label"
        arcLinkLabelsSkipAngle={10}
        arcLabelsSkipAngle={10}
        legends={[
            {
                anchor: 'bottom-left',
                direction: 'column',
                translateX: -100,
                translateY: 0,
                itemsSpacing: 0,
                itemWidth: 150,
                itemHeight: 22,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
        theme={chartTheme}
    />
);

export default function Report({ data }) {
    return (
        <main>
            <div className="mx-auto py-8 px-4 sm:px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Representatividade demográfica</h3>
                        </div>
                        <div style={{ height: 'calc(100vh - 300px)' }}>
                            <DemographicPyramid data={data.demographic} />
                        </div>
                    </div>
                    <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                        <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                            <h3 className="text-base font-semibold leading-6 text-gray-900">Escolaridade</h3>
                        </div>
                        <div style={{ height: 'calc(100vh - 300px)' }}>
                            <ChartEscolaridade data={data.education} />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
