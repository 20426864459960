import logo from '../../svg/logo-color.svg';
import { Link } from 'react-router-dom';
import { UI } from '..';
import { getCompanyColor } from '../../styles';
import { currentCompany, currentExperience } from '../../signals';

export default function ReportWelcome() {
    const company = currentCompany.value;
    const experience = currentExperience.value;
    const urlNextPage = `/${company.companyId}/report/2`;

    // Get max 5 random screenshots from [experience].[journeys].[screenshots].url object
    const screenshots = experience.flatMap((j) =>
        j.screenshots.map((screenshot) => screenshot.url)
    );
    const randomScreenshots = screenshots
        .sort(() => 0.5 - Math.random())
        .slice(0, 5);

    return (
        <div className="bg-white h-full overflow-hidden">
            <main className="flex flex-col items-center justify-center h-full">
                <div className="relative w-full isolate px-6">
                    <BackgroundEffects
                        colorFrom={getCompanyColor(company.name, [null])}
                        colorTo={getCompanyColor(company.name, [null])}
                    />
                    <div className="overflow-hidden">
                        <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                                <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl space-y-10">
                                    <div className="flex gap-6 items-center">
                                        <UI.Icon64
                                            slug={company.slug}
                                            className="h-[64px] w-auto"
                                        />
                                        <span>&#x2b;</span>
                                        <img
                                            className="h-[64px] w-auto"
                                            src={logo}
                                            alt="idwall"
                                        />
                                    </div>
                                    <h1 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
                                        Relatório de Experiência 2024
                                    </h1>
                                    <p className="text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                                        Este relatório foi produzido com base em
                                        dados de uma pesquisa realizada pela
                                        idwall, que consistiu em testes de
                                        cadastros em 30 players diferentes,
                                        divididos entre: Bancos Tradicionais,
                                        Bancos Digitais e Wallets. Desses 30
                                        players, apenas 23 participaram do
                                        Ranking de Onboarding 2022, relatório
                                        público divulgado pela idwall.
                                    </p>
                                    <div className="flex items-center gap-x-6">
                                        <Link
                                            to={urlNextPage}
                                            className="text-sm font-semibold leading-6 text-gray-900"
                                        >
                                            Conheça a metodologia
                                        </Link>
                                        <UI.Button as="Link" to={urlNextPage}>
                                            Começar
                                            <span aria-hidden="true">→</span>
                                        </UI.Button>
                                    </div>
                                </div>
                                <Screenshots screenshots={randomScreenshots} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

const Screenshots = ({ screenshots }) => (
    <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
        <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
            <div className="relative">
                <img
                    src={screenshots[0]}
                    alt=""
                    className="w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
                <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
        </div>
        <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
            <div className="relative">
                <img
                    src={screenshots[1]}
                    alt=""
                    className="w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
                <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div className="relative">
                <img
                    src={screenshots[2]}
                    alt=""
                    className="w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
                <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
        </div>
        <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
            <div className="relative">
                <img
                    src={screenshots[3]}
                    alt=""
                    className="w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
                <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div className="relative">
                <img
                    src={screenshots[4]}
                    alt=""
                    className="w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                />
                <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
        </div>
    </div>
);

const BackgroundEffects = ({ colorFrom = '#00CEFF', colorTo = '#5500FF' }) => (
    <>
        <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
        >
            <defs>
                <pattern
                    id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                    width={200}
                    height={200}
                    x="50%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                >
                    <path d="M.5 200V.5H200" fill="none" />
                </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path
                    d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                    strokeWidth={0}
                />
            </svg>
            <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
        </svg>
        <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
        >
            <div
                className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#00CEFF] to-[#5500FF]  opacity-30"
                style={{
                    clipPath:
                        'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                    '--tw-gradient-to': colorTo,
                    '--tw-gradient-from': colorFrom
                }}
            />
        </div>
    </>
);
