const { get } = require('../utils/api/api_caller_utils');

export const getReport = async (companyId) => {
    try {
        const response = await get(`/companies/${companyId}/report`);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error getting report:', error);
    }
};
