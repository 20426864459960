import { classNames } from '../helpers';

function getBankNameRandomly() {
    const banks = [
        'Banco do Brasil',
        'Caixa Econômica Federal',
        'Itaú Unibanco',
        'Bradesco',
        'Santander',
        'Banco Safra',
        'Banco BTG Pactual',
        'Banco da Amazônia',
        'Banrisul',
        'Banco do Nordeste'
    ];

    const randomIndex = Math.floor(Math.random() * banks.length);
    return banks[randomIndex];
}

const tiers = [
    {
        name: 'Melhor Onboarding',
        id: 1,
        description:
            'Distinctio et nulla eum soluta et neque labore quibusdam.',
        mostPopular: false
    },
    {
        name: 'Melhor Banco Digital',
        id: 2,
        description:
            'Distinctio et nulla eum soluta et neque labore quibusdam.',
        mostPopular: true
    },
    {
        name: 'Melhor PIX',
        id: 3,
        description:
            'Distinctio et nulla eum soluta et neque labore quibusdam.',
        mostPopular: false
    }
];

const people = [
    {
        name: 'Carlos Silva',
        role: 'Diretor Financeiro',
        imageUrl: '/img/people/1.png',
        logo: 'https://logo.clearbit.com/www.bancobmg.com.br',
        bio: 'Especialista em finanças corporativas com mais de 10 anos de experiência em gestão financeira e investimentos estratégicos.',
        twitterUrl: '#',
        linkedinUrl: '#'
    },
    {
        name: 'Mariana Pereira',
        role: 'Analista de Riscos',
        imageUrl: '/img/people/2.png',
        logo: 'https://www.bancopan.com.br/favicon.ico',
        bio: 'Experiente em análises quantitativas e qualitativas de riscos financeiros, com ênfase em modelagem de risco de crédito e mercado.',
        twitterUrl: '#',
        linkedinUrl: '#'
    },
    {
        name: 'Rafael Santos',
        role: 'Gerente de Investimentos',
        imageUrl: '/img/people/3.png',
        logo: 'https://www.mercadopago.com.br/favicon.ico',
        bio: 'Responsável pela gestão de portfólios de investimentos, com foco em maximizar os retornos ajustados ao risco.',
        twitterUrl: '#',
        linkedinUrl: '#'
    },
    {
        name: 'Juliana Lima',
        role: 'Especialista em Compliance',
        imageUrl: '/img/people/4.png',
        logo: 'https://logo.clearbit.com/www.picpay.com',
        bio: 'Forte experiência em regulamentações financeiras, trabalhando para garantir a conformidade das operações bancárias.',
        twitterUrl: '#',
        linkedinUrl: '#'
    }
];

export default function Ranking() {
    return (
        <>
            <div className="bg-gray-900 py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl text-center">
                        <h2 className="text-base font-semibold leading-7 text-idwallDarkPurple-400">
                            idwall
                        </h2>
                        <p className="mt-2 text-4xl font-semibold tracking-tight text-white sm:text-5xl">
                            Ranking de Experiência 2024
                        </p>
                    </div>
                    <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
                        Distinctio et nulla eum soluta et neque labore
                        quibusdam. Saepe et quasi iusto modi velit ut non
                        voluptas in. Explicabo id ut laborum.
                    </p>
                    <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {tiers.map((tier, tierIdx) => (
                            <div
                                key={tier.id}
                                className={classNames(
                                    tier.mostPopular
                                        ? 'lg:z-10 lg:rounded-b-none ring-2 ring-idwallDarkPurple-500'
                                        : 'lg:mt-8',
                                    tierIdx === 0 ? 'lg:rounded-r-none' : '',
                                    tierIdx === tiers.length - 1
                                        ? 'lg:rounded-l-none'
                                        : '',
                                    'flex flex-col justify-between rounded-3xl bg-gray-800 p-8 ring-1 ring-gray-700/50 xl:p-10'
                                )}
                            >
                                <div>
                                    <div className="flex items-center justify-between gap-x-4">
                                        <h3
                                            id={tier.id}
                                            className={classNames(
                                                tier.mostPopular
                                                    ? 'text-idwallDarkPurple-400'
                                                    : 'text-white',
                                                'text-lg font-semibold leading-8'
                                            )}
                                        >
                                            {tier.name}
                                        </h3>
                                        {tier.mostPopular ? (
                                            <p className="rounded-full bg-idwallDarkPurple-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-idwallDarkPurple-400">
                                                Bicampeão
                                            </p>
                                        ) : null}
                                    </div>
                                    <p className="mt-4 text-sm leading-6 text-gray-300">
                                        {tier.description}
                                    </p>
                                    <p className="mt-6 flex items-baseline gap-x-3">
                                        <span className="text-4xl font-semibold tracking-tight text-idwallDarkPurple-400">
                                            1
                                        </span>
                                        <span className="text-4xl font-semibold leading-10 text-gray-300">
                                            {getBankNameRandomly()}
                                        </span>
                                    </p>
                                    <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-300">
                                        {[2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                            (number) => (
                                                <li
                                                    key={number}
                                                    className="flex gap-x-3"
                                                >
                                                    <span className="flex-none text-idwallDarkPurple-400 font-black">
                                                        {number}
                                                    </span>
                                                    {getBankNameRandomly()}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <People />
        </>
    );
}

function People() {
    return (
        <div className="bg-white py-24 md:py-32">
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
                <div className="max-w-2xl xl:col-span-2">
                    <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                        Profissionais de destaque
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Quem está por trás dos melhores bancos digitais do
                        Brasil. Conheça os profissionais que construíram as
                        melhores experiências para os clientes.
                    </p>
                </div>
                <ul className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
                    {people.map((person) => (
                        <li
                            key={person.name}
                            className="flex flex-col gap-10 pt-12 sm:flex-row"
                        >
                            <img
                                className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                                src={person.imageUrl}
                                alt=""
                            />
                            <div className="max-w-xl flex-auto">
                                <div className="flex">
                                    <img
                                        src={person.logo}
                                        alt={person.name}
                                        className="w-16 h-16 flex-none mr-6"
                                    />
                                    <div className="flex-auto">
                                        <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">
                                            {person.name}
                                        </h3>
                                        <p className="text-base leading-7 text-gray-600">
                                            {person.role}
                                        </p>
                                    </div>
                                </div>
                                <p className="mt-6 text-base leading-7 text-gray-600">
                                    {person.bio}
                                </p>
                                <ul className="mt-6 flex gap-x-6">
                                    <li>
                                        <a
                                            href={person.twitterUrl}
                                            className="text-gray-400 hover:text-gray-500"
                                        >
                                            <span className="sr-only">
                                                Twitter
                                            </span>
                                            <svg
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={person.linkedinUrl}
                                            className="text-gray-400 hover:text-gray-500"
                                        >
                                            <span className="sr-only">
                                                LinkedIn
                                            </span>
                                            <svg
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
