import { Outlet } from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import { UI } from '../../components';
import { pageTitle, user } from '../../signals';

export default function AdminPage() {
    if (!user.value.type === 'admin') {
        window.location.href = '/logout';
        return null;
    }

    return (
        <>
            <div className="bg-gray-800 text-white pt-10 pb-32">
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 space-y-8">
                    <Breadcrumb />
                    <UI.H1 className="text-white">{pageTitle.value}</UI.H1>
                    <Outlet />
                </div>
            </div>
        </>
    );
}
