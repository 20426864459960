import {
    LinkAuthenticationElement,
    PaymentElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { UI } from '../../components';

export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/completion`
            }
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
        } else {
            setMessage('An unexpected error occured.');
        }

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <LinkAuthenticationElement
                id="link-authentication-element"
                // Access the email value like so:
                // onChange={(event) => {
                //  setEmail(event.value.email);
                // }}
                //
                // Prefill the email field like so:
                // options={{defaultValues: {email: 'foo@bar.com'}}}
            />
            <PaymentElement id="payment-element" />
            <p className="text-xs mt-10 text-gray-600">
                Seu método de pagamento será cobrado mensalmente no valor de
                $99,00 a partir de quinta-feira, 25 de janeiro de 2024, a menos
                que você cancele antes disso, visitando as Configurações da sua
                Conta. Ao clicar em &quot;Assinar Index+&quot;, você reconhece
                que leu e concordou com nossos{' '}
                <a
                    className="font-semibold hover:underline"
                    target="_blank"
                    href="/termos-de-uso"
                >
                    Termos de Uso
                </a>{' '}
                e nossa{' '}
                <a
                    className="font-semibold hover:underline"
                    target="_blank"
                    href="https://idwall.co/pt-BR/politica-de-privacidade/"
                    rel="noreferrer"
                >
                    Política de Privacidade
                </a>
                .
            </p>
            <div className="flex justify-end mt-8">
                <UI.Button
                    disabled={isLoading || !stripe || !elements}
                    id="submit"
                    type="submit"
                >
                    <span id="button-text">
                        {isLoading ? (
                            <div className="spinner" id="spinner"></div>
                        ) : (
                            'Assinar Index+'
                        )}
                    </span>
                </UI.Button>
            </div>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}
