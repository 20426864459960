/* eslint-disable camelcase */
import { CRMService } from '../../services';
import { isValidEmail } from '../../helpers';
import { UI } from '..';
import { signal } from '@preact/signals-react';

const formStatus = signal({ submitted: false, message: '' });

const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const { email } = Object.fromEntries(new FormData(form));
    const first_name = email.split('@')[0];

    if (!isValidEmail(email)) {
        formStatus.value = { submitted: false, message: 'E-mail inválido.' };
        return;
    }

    try {
        await CRMService.registerCRMLead({ email, first_name });
        form.reset();
        formStatus.value = {
            submitted: true,
            message: 'E-mail cadastrado com sucesso!'
        };
    } catch (error) {
        formStatus.value = {
            submitted: true,
            message: 'Erro ao cadastrar e-mail.'
        };
    }
};

export default function NewsletterForm() {
    return (
        <div>
            {!formStatus.value.submitted ? (
                <form
                    className="mt-6 sm:flex sm:max-w-md lg:mt-0"
                    onSubmit={handleSubmit}
                >
                    <label htmlFor="email" className="sr-only">
                        Endereço de e-mail
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        required
                        className="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-idwallDarkPurple-600 sm:w-56 sm:text-sm sm:leading-6"
                        placeholder="Digite seu e-mail"
                    />
                    <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                        <UI.Button type="submit" className="flex w-full">
                            Inscrever-se
                        </UI.Button>
                    </div>
                </form>
            ) : (
                <p className="text-sm text-gray-900">
                    {formStatus.value.message}
                </p>
            )}
        </div>
    );
}
