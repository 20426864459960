import { user } from '../../signals';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function CheckLoggedIn({ children }) {
    const navigate = useNavigate();

    useEffect(() => {
        // Workaround para que o signal user seja populado antes de redirecionar
        setTimeout(() => {
            if (!user.value.isLoggedIn) {
                navigate('/login');
            } else {
                if (user.value.type !== 'admin') {
                    navigate('/');
                }
            }
        }, 0);
    }, [user.value.isLoggedIn, user.value.type]);

    if (!user.value.isLoggedIn) {
        return null;
    }

    if (user.value.type !== 'admin') {
        return null;
    }

    return children;
}
