import logo from '../../svg/index-logo-color.svg';
import { IoLogoLinkedin } from 'react-icons/io5';
import { FaXTwitter } from 'react-icons/fa6';
import { AiFillInstagram } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import CookieConsent from './CookieConsent';
import NewsletterForm from './NewsletterForm';
import { user } from '../../signals';

const navigation = {
    idwall: [
        {
            name: 'Soluções',
            href: 'https://idwall.co/',
            target: '_blank'
        },
        {
            name: 'Conteúdo',
            href: 'https://idwall.co/biblioteca',
            target: '_blank'
        },
        {
            name: 'Plataforma',
            href: 'https://idwall.co/plataforma/',
            target: '_blank'
        },
        {
            name: 'Contato',
            href: 'https://idwall.co/contato/',
            target: '_blank'
        }
    ],
    index: [
        { name: 'Relatório 2024', href: '/2024' },
        { name: 'Ajuda', href: 'mailto:index@idwall.co' },
        {
            name: 'Reportar informação desatualizada',
            href: 'mailto:index@idwall.co'
        }
    ],
    legal: [
        {
            name: 'Política de Privacidade',
            href: 'https://idwall.co/pt-BR/politica-de-privacidade/',
            target: '_blank'
        },
        { name: 'Termos de Uso', href: '/termos-de-uso', target: '_blank' }
    ],
    social: [
        {
            name: 'LinkedIn',
            href: 'https://www.linkedin.com/company/idwall',
            icon: (props) => <IoLogoLinkedin {...props} />
        },
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/idwall_co',
            icon: (props) => <AiFillInstagram {...props} />
        },
        {
            name: 'Twitter',
            href: 'https://twitter.com/idwall_co',
            icon: (props) => <FaXTwitter {...props} />
        }
    ]
};

export default function Footer() {
    const navigate = useNavigate();
    const { isLoggedIn } = user.value;

    return (
        <footer className="bg-gray-50" aria-labelledby="footer-heading">
            <CookieConsent />
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
                <div className="grid xl:grid-cols-4 md:grid-cols-3 gap-8">
                    <img
                        className="md:col-span-3 xl:col-span-1 h-18 cursor-pointer"
                        src={logo}
                        alt="Index by idwall"
                        onClick={() => navigate('/')}
                    />

                    <div className="mt-10 md:mt-0">
                        <h3 className="text-sm font-semibold leading-6 text-gray-900">
                            idwall
                        </h3>
                        <ul role="list" className="mt-6 space-y-2">
                            {navigation.idwall.map((item) => (
                                <li key={item.name}>
                                    <a
                                        href={item.href}
                                        target={item.target}
                                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="mt-10 md:mt-0">
                        <h3 className="text-sm font-semibold leading-6 text-gray-900">
                            Index
                        </h3>
                        <ul role="list" className="mt-6 space-y-2">
                            {navigation.index.map((item) => (
                                <li key={item.name}>
                                    <a
                                        href={item.href}
                                        target={item.target}
                                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="mt-10 md:mt-0">
                        <h3 className="text-sm font-semibold leading-6 text-gray-900">
                            Legal
                        </h3>
                        <ul role="list" className="mt-6 space-y-2">
                            {navigation.legal.map((item) => (
                                <li key={item.name}>
                                    <a
                                        href={item.href}
                                        target={item.target}
                                        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                                    >
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                {!isLoggedIn && (
                    <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
                        <div>
                            <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                Inscreva-se em nossa newsletter
                            </h3>
                            <p className="mt-2 text-sm leading-6 text-gray-600">
                                As últimas notícias, artigos e recursos enviados
                                para sua caixa de entrada semanalmente.
                            </p>
                        </div>
                        <NewsletterForm />
                    </div>
                )}
                <div className="mt-8 border-t border-gray-900/10 pt-8 md:flex md:items-center md:justify-between">
                    <div className="flex space-x-6 md:order-2">
                        {navigation.social.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                <span className="sr-only">{item.name}</span>
                                <item.icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </a>
                        ))}
                    </div>
                    <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
                        &copy; 2024 IDWALL TECNOLOGIA LTDA. Todos os direitos
                        reservados.
                    </p>
                </div>
            </div>
        </footer>
    );
}
