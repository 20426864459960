import { signal } from '@preact/signals-react';

// Autocomplete list (name/id pairs)
export const autocompleteList = signal([]);

// List of companies
export const companiesList = signal({
    companies: [],
    page: 1,
    total: null,
    sort: null,
    order: null,
    limit: null
});

export const isLoading = signal(false);

// Company details
export const currentCompany = signal({
    name: '...',
    apps: []
});
