export default function getFormData({ user, companies }) {
    return [
        {
            label: 'First Name',
            name: 'firstName',
            value: user.firstName || ''
        },
        {
            label: 'Last Name',
            name: 'lastName',
            value: user.lastName || ''
        },
        {
            label: 'Tipo',
            name: 'type',
            type: 'select',
            value: user.type || '',
            options: [
                { key: 'admin', value: 'admin' },
                {
                    key: 'general',
                    value: 'general'
                }
            ]
        },
        {
            label: 'Email',
            name: 'email',
            type: 'email',
            value: user.email || ''
        },
        {
            label: 'Usuário é válido?',
            name: 'isValidUser',
            type: 'checkbox',
            checked: user.isValidUser || false
        },
        {
            label: 'Usuário está ativo?',
            name: 'isActive',
            type: 'checkbox',
            checked: user.isActive || false
        },
        {
            label: 'Company',
            name: 'companyId',
            type: 'select',
            value: user.companyId || '',
            options: companies.map((company) => ({
                key: company.companyId,
                value: company.name
            }))
        },
        {
            label: 'Metadados',
            name: 'metadata',
            type: 'json',
            value: user.metadata || {}
        }
    ];
}
