import { ResponsiveBar } from '@nivo/bar';
import { chartTheme, getChartColors } from '../../styles';

const BacenComplaints = ({ data }) => {
    // Função para transformar o formato do trimestre
    const formatQuarter = (item) => {
        const year = item.year.toString().slice(-2);
        return `${item.quarter}º tri ${year}`;
    };

    // Transformar os dados para novas chaves
    const data2 = data.map((item) => ({
        ...item,
        quarter: formatQuarter(item),
        'Reguladas Procedentes': item.numberOfComplaintsRegulatedValid,
        'Reguladas Outras': item.numberOfComplaintsRegulatedOther,
        'Não Reguladas': item.numberOfComplaintsNonRegulated
    }));

    const keys = ['Reguladas Procedentes', 'Reguladas Outras', 'Não Reguladas'];

    return (
        <ResponsiveBar
            data={data2}
            theme={chartTheme}
            labelTextColor="white"
            keys={keys}
            indexBy="quarter"
            margin={{ top: 30, right: 30, bottom: 100, left: 70 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={getChartColors(keys.length)}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Trimestre',
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Quantidade de Reclamações',
                legendPosition: 'middle',
                legendOffset: -50,
                truncateTickAt: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 70,
                    itemsSpacing: 2,
                    itemWidth: 160,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            role="application"
        />
    );
};

export default BacenComplaints;
