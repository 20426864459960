import { Fragment } from 'react';
import {
    currentCompany,
    modalTCB,
    modalCidade,
    modalTI,
    modalTC,
    modalChartReclamacoes,
    modalChartClientes
} from '../../../signals';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Bacen, Charts, Layout } from '../../../components';
import { tcbType, tcType, tiType } from './Domains';

const Alert = () => {
    return (
        <div className="rounded-md bg-blue-50 p-4 mb-6">
            <div className="flex">
                <div className="flex-shrink-0">
                    <InformationCircleIcon
                        className="h-5 w-5 text-blue-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">
                        Atenção: Reclamações e quantidade de clientes e outros
                        dados referentes ao conglomerado do qual a instituição
                        faz parte.
                    </p>
                    <p className="mt-3 text-sm md:ml-6 md:mt-0 space-x-5">
                        <a
                            href="https://www3.bcb.gov.br/ifdata/#"
                            target="_blank"
                            className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                            rel="noreferrer"
                        >
                            IF.data
                        </a>
                        <a
                            href="https://www.bcb.gov.br/meubc/rankingreclamacoes"
                            target="_blank"
                            className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                            rel="noreferrer"
                        >
                            Ranking de Reclamações
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default function CompanyBacen() {
    const company = currentCompany.value;

    let institution = null;
    let conglomerate = null;
    let complaints = [];

    if (company.bcbInstitution) {
        institution = company.bcbInstitution;

        if (institution.bcbConglomerate) {
            conglomerate = institution.bcbConglomerate;
            const { bcbComplaints } = conglomerate;

            if (bcbComplaints && bcbComplaints.length) {
                complaints = bcbComplaints;
            }
        }
    }

    // Sort complaints by year and quarter
    complaints.sort((a, b) => {
        if (a.year !== b.year) {
            return a.year - b.year;
        }
        return a.quarter - b.quarter;
    });

    return (
        <>
            <Layout.Head title={`Index - ${company.name}: Bacen`} />
            <Bacen.Modals />
            <main>
                <div className="mx-auto max-w-screen-2xl py-8 px-4 sm:px-6 lg:px-8 space-y-8">
                    <Alert />
                    {complaints.length ? (
                        <>
                            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                                <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                                    <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                                            Quantidade de clientes (
                                            {conglomerate.name ||
                                                institution.name}
                                            )
                                            <InformationCircleIcon
                                                className="h-6 w-6 text-gray-500 inline-block cursor-pointer hover:text-blue-700 ml-2"
                                                onClick={() => {
                                                    modalChartClientes.value = true;
                                                }}
                                            />
                                        </h3>
                                    </div>
                                    <div className="h-[450px]">
                                        <Charts.BacenUserCount
                                            data={complaints}
                                        />
                                    </div>
                                </div>
                                <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                                    <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900">
                                            Reclamações (
                                            {conglomerate.name ||
                                                institution.name}
                                            )
                                            <InformationCircleIcon
                                                className="h-6 w-6 text-gray-500 inline-block cursor-pointer hover:text-blue-700 ml-2"
                                                onClick={() => {
                                                    modalChartReclamacoes.value = true;
                                                }}
                                            />
                                        </h3>
                                    </div>
                                    <div className="h-[450px]">
                                        <Charts.BacenComplaints
                                            data={complaints}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                    <BacenCard institution={institution} />
                </div>
            </main>
        </>
    );
}

function BacenCardRow({ title, children, onClick }) {
    return (
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
                {title}
                {onClick && (
                    <InformationCircleIcon
                        className="h-6 w-6 text-gray-500 inline-block cursor-pointer hover:text-blue-700 ml-2"
                        onClick={onClick}
                    />
                )}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {children}
            </dd>
        </div>
    );
}

function BacenCard({ institution }) {
    return (
        <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                    {institution.name}
                </h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                    {institution.code}
                </p>
            </div>
            <div className="border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <BacenCardRow title="Conglomerado Financeiro">
                        {institution.conglomerate}
                    </BacenCardRow>
                    <BacenCardRow
                        title="Tipo de Consolidado Bancário (TCB)"
                        onClick={() => {
                            modalTCB.value = true;
                        }}
                    >
                        <strong>{institution.tcb.toUpperCase()}:</strong>{' '}
                        {tcbType[institution.tcb]}
                    </BacenCardRow>
                    <BacenCardRow
                        title="Tipo de Controle (TC)"
                        onClick={() => {
                            modalTC.value = true;
                        }}
                    >
                        <strong>{institution.tc.toUpperCase()}:</strong>{' '}
                        {tcType[institution.tc]}
                    </BacenCardRow>
                    <BacenCardRow
                        title="Tipo de Instituição (TI)"
                        onClick={() => {
                            modalTI.value = true;
                        }}
                    >
                        <strong>{institution.ti.toUpperCase()}:</strong>{' '}
                        {tiType[institution.ti]}
                    </BacenCardRow>
                    <BacenCardRow
                        title="Cidade"
                        onClick={() => {
                            modalCidade.value = true;
                        }}
                    >
                        {institution.city} - {institution.state}
                    </BacenCardRow>
                    <BacenCardRow title="Ativo Total">
                        R$ {institution.totalAssets.toLocaleString('pt-BR')},00
                    </BacenCardRow>
                    <BacenCardRow title="Número de Agências">
                        {institution.numberOfBranches}
                    </BacenCardRow>
                    <BacenCardRow title="Número de Postos de Atendimento">
                        {institution.numberOfServiceStations}
                    </BacenCardRow>
                    <div className="px-4 py-6 sm:px-6">
                        <p className="mt-1 text-sm leading-6 text-gray-500">
                            Dados do {institution.quarter}º trimestre de{' '}
                            {institution.year}
                        </p>
                    </div>
                </dl>
            </div>
        </div>
    );
}
