const { BASE_URL } = require('../utils/api/api_caller_utils');

export const uploadFile = async (formData) => {
    try {
        console.log('formData', formData);
        console.log('BASE_URL', BASE_URL);
        const response = await fetch(`${BASE_URL}/admin/upload`, {
            method: 'POST',
            body: formData
        });

        console.log('response', response);

        if (!response.ok) {
            throw new Error('Falha no upload do arquivo');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error uploading file', error);
        throw error; // Relance o erro para ser capturado no componente
    }
};
