import { currentExperience, currentCompany } from '../../../signals';
import { useEffect, useCallback } from 'react';
import { ExperienceService } from '../../../services';
import { Tab } from '@headlessui/react';
import { CircleScore, Rating, UI, Layout } from '../..';
import FlowsTabs from './FlowsTabs';
import { slugify, classNames } from '../../../helpers';

const JourneyCriterias = ({ data }) => {
    const detailsScore = data.scores.filter((c) => c.type === 'score')[0];
    const detailsRating = data.scores.filter((c) => c.type === 'rating')[0];
    const detailsGeneric = data.scores[0];

    return (
        <table className="divide-y divide-gray-200 mt-10 w-full">
            <tbody>
                <tr className="border-t border-gray-200">
                    <th
                        colSpan={3}
                        scope="colgroup"
                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-md font-semibold text-gray-900 sm:pl-3"
                    >
                        {data.name}
                    </th>
                </tr>
                {!detailsRating &&
                    !detailsScore &&
                    detailsGeneric.details.map((row, index) => (
                        <tr key={index} className="border-b border-gray-200">
                            <td
                                colSpan={2}
                                className="w-1/2 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"
                            >
                                {row.label}
                            </td>
                            <td className="w-1/2 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {row.value}
                            </td>
                        </tr>
                    ))}

                {detailsScore &&
                    detailsScore.details &&
                    detailsScore.details.map((row, index) => (
                        <tr key={index}>
                            {index === 0 && (
                                <td
                                    rowSpan={detailsScore.details.length}
                                    className="whitespace-nowrap p-6 text-sm font-medium text-gray-900"
                                >
                                    <CircleScore
                                        score={detailsScore.value}
                                        radius={50}
                                        strokeWidth={12}
                                        fontSize="text-3xl"
                                    />
                                    <p className="text-center mt-3">Score</p>
                                </td>
                            )}

                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                {row.label}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {row.value}
                            </td>
                        </tr>
                    ))}

                {detailsRating &&
                    detailsRating.details &&
                    detailsRating.details.map((row, index) => (
                        <tr key={index} className="border-b border-gray-200">
                            {index === 0 && (
                                <td
                                    rowSpan={detailsRating.details.length}
                                    className="whitespace-nowrap p-6 text-sm font-medium text-gray-900"
                                >
                                    <Rating
                                        value={detailsRating.value}
                                        size={8}
                                    />
                                    <p className="text-center mt-3">
                                        Nota geral de usuários
                                    </p>
                                </td>
                            )}
                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                <span>{row.label}</span>
                                {(row.comments || []).map((comment, index2) => (
                                    <div
                                        key={index2}
                                        className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 mt-3"
                                    >
                                        <p className="text-sm leading-6 text-gray-500 italic">
                                            {comment}
                                        </p>
                                    </div>
                                ))}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <Rating value={row.value} />
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};

const ImageGallery = ({ screenshots }) => {
    return (
        <Tab.Group as="div" className="flex flex-col-reverse sticky top-36">
            {/* Image selector */}
            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-6 gap-6">
                    {screenshots.map((image) => (
                        <Tab
                            key={image.expScreenshotId}
                            className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                        >
                            {({ selected }) => (
                                <>
                                    <span className="absolute inset-0 overflow-hidden rounded-md">
                                        <img
                                            src={image.url}
                                            alt=""
                                            className="h-auto w-full object-cover object-center"
                                        />
                                    </span>
                                    <span
                                        className={classNames(
                                            selected
                                                ? 'ring-idwallDarkPurple-500'
                                                : 'ring-transparent',
                                            'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </Tab>
                    ))}
                </Tab.List>
            </div>

            <div className="mx-auto">
                <Tab.Panels className="w-full max-w-[240px]">
                    {screenshots.map((image) => (
                        <Tab.Panel key={image.expScreenshotId}>
                            <img
                                src={image.url}
                                alt=""
                                className="h-auto w-full object-cover object-center sm:rounded-lg shadow-lg"
                            />
                        </Tab.Panel>
                    ))}
                </Tab.Panels>
            </div>
        </Tab.Group>
    );
};

export default function Flows() {
    const company = currentCompany.value;
    const { companyId } = company;

    const loadExperience = useCallback(async () => {
        try {
            if (companyId) {
                currentExperience.value = await ExperienceService.getExperience(
                    companyId
                );
            }
        } catch (error) {
            console.error('Failed to load company details:', error);
        }
    }, [companyId]);

    useEffect(() => {
        loadExperience();
    }, [companyId]);

    const journeys = currentExperience.value;

    if (!journeys) {
        return null;
    }

    return (
        <>
            <Layout.Head title={`Index - ${company.name}: Fluxos`} />
            <div className="bg-white">
                <FlowsTabs journeys={journeys} />
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                    {journeys.map((journey) => (
                        <div
                            key={journey.expJourneyId}
                            id={slugify(journey.name)}
                            className="flex flex-col lg:grid lg:grid-cols-3 lg:gap-x-8 border-b border-gray-200 pb-10 sm:pb-16 mb-10 sm:mb-16"
                        >
                            {/* Image gallery */}
                            <div className="lg:col-span-1">
                                <ImageGallery
                                    screenshots={journey.screenshots}
                                />
                            </div>

                            {/* Journey info */}
                            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:col-span-2 lg:mt-0">
                                <UI.H1>{journey.name}</UI.H1>
                                {journey.criterias.map((criteria) => (
                                    <JourneyCriterias
                                        key={criteria.expCriteriaId}
                                        data={criteria}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>{' '}
        </>
    );
}
