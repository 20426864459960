const general = [
    {
        companyId: 40,
        name: 'C6',
        url: 'https://www.c6bank.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco digital em crescimento no mercado brasileiro, oferecendo produtos financeiros inovadores e contas sem tarifa.',
        slug: 'c6',
        awards: ['1º lugar Bancos Digitais', '1º lugar Onboarding'],
        position: 1,
        score: 7.4
    },
    {
        companyId: 125,
        name: 'Itaú',
        url: 'https://www.itau.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Um dos maiores e mais tradicionais bancos privados do Brasil, oferecendo uma ampla gama de serviços e produtos financeiros para diversos tipos de clientes.',
        slug: 'itau',
        awards: ['1º lugar Bancos Tradicionais', '1º lugar Experiência'],
        position: 2,
        score: 7.33
    },
    {
        companyId: 138,
        name: 'Nubank',
        url: 'https://www.nubank.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Um dos maiores bancos digitais do Brasil, conhecido por seu cartão de crédito roxo e uma plataforma financeira completa sem tarifas abusivas.',
        slug: 'nubank',
        awards: [
            '2º lugar Bancos Digitais',
            '1º lugar Encantamento do Cliente'
        ],
        position: 3,
        score: 7.3
    },
    {
        companyId: 38,
        name: 'BTG Pactual',
        url: 'https://www.btgpactual.com/',
        type: 'Banco Múltiplo',
        description:
            'Banco de investimentos líder na América Latina, oferecendo serviços financeiros para investidores institucionais, empresas e clientes individuais.',
        slug: 'btg-pactual',
        awards: ['1º lugar Bancos de Investimento', '3º lugar Onboarding'],
        position: 4,
        score: 7.24
    },
    {
        companyId: 71,
        name: 'Banco Inter',
        url: 'https://www.bancointer.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco digital em rápida expansão, conhecido por oferecer conta corrente gratuita e uma série de outros serviços financeiros sem tarifas.',
        slug: 'banco-inter',
        awards: ['3º lugar Bancos Digitais'],
        position: 5,
        score: 7.16
    },
    {
        companyId: 139,
        name: 'PagBank',
        url: 'https://www.pagseguro.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Serviço financeiro do grupo UOL. O PagSeguro é uma solução de pagamentos online, enquanto o PagBank oferece serviços bancários digitais, como contas e cartões.',
        slug: 'pagbank',
        awards: [],
        position: 6,
        score: 7.13
    },
    {
        companyId: 88,
        name: 'Banco Pan',
        url: 'https://www.bancopan.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco comercial que atua em diversos segmentos financeiros, como crédito ao consumidor e financiamento de veículos.',
        slug: 'banco-pan',
        awards: [],
        position: 7,
        score: 7.06
    },
    {
        companyId: 142,
        name: 'Stone',
        url: 'https://www.stone.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'A Stone Pagamentos é uma fintech brasileira que fornece soluções de meios de pagamentos. Ela atua principalmente por meio de serviços de adquirência multibandeiras, utilizando máquinas de cartões que processam transações realizadas com cartões de crédito, débito e voucher. Fundada para facilitar as transações financeiras entre as bandeiras de cartão e os bancos emissores, a Stone tem como objetivo transformar a indústria de meios de pagamentos, equilibrando as forças entre lojistas e bancos, e melhorando a experiência de consumo e a produtividade dos clientes​​​​.',
        slug: 'stone',
        awards: [],
        position: 8,
        score: 7.05
    },
    {
        companyId: 137,
        name: 'Neon',
        url: 'https://www.banconeon.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Banco digital brasileiro que oferece conta digital, cartão de crédito e outras soluções financeiras sem a burocracia tradicional.',
        slug: 'neon',
        awards: [],
        position: 9,
        score: 7.02
    },
    {
        companyId: 140,
        name: 'Picpay',
        url: 'https://www.picpay.com/',
        type: 'Instituição de Pagamento',
        description:
            'Aplicativo de pagamentos brasileiro que permite aos usuários armazenar dinheiro, fazer pagamentos, transferências e até mesmo comprar créditos para serviços.',
        slug: 'picpay',
        awards: [],
        position: 10,
        score: 7
    },
    {
        companyId: 133,
        name: 'Sicredi',
        url: 'https://www.sicredi.com.br/',
        type: 'Banco Múltiplo Cooperativo',
        description:
            'Rede de cooperativas de crédito que atua em várias regiões do Brasil, focada em promover o desenvolvimento econômico e social de seus membros e da comunidade.',
        slug: 'sicredi',
        awards: ['2º lugar Bancos Tradicionais'],
        position: 11,
        score: 6.99
    },
    {
        companyId: 136,
        name: 'Mercado Pago',
        url: 'https://www.mercadopago.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Plataforma de pagamentos do Mercado Livre, que também oferece soluções de crédito e um cartão pré-pago.',
        slug: 'mercado-pago',
        awards: [],
        position: 12,
        score: 6.93
    },
    {
        companyId: 114,
        name: 'XP',
        url: 'https://www.xpi.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Plataforma de investimentos independente de grande porte no Brasil, oferecendo uma ampla variedade de produtos financeiros e serviços de assessoria.',
        slug: 'xp',
        awards: ['2º lugar Bancos de Investimento'],
        position: 13,
        score: 6.88
    },
    {
        companyId: 132,
        name: 'Sicoob',
        url: 'https://www.sicoob.com.br/',
        type: 'Banco Múltiplo Cooperativo',
        description:
            'Sistema de Cooperativas de Crédito do Brasil, a maior rede de cooperativas de crédito do país, buscando oferecer soluções financeiras para seus cooperados.',
        slug: 'sicoob',
        awards: ['3º lugar Bancos Tradicionais'],
        position: 14,
        score: 6.83
    },
    {
        companyId: 146,
        name: 'Midway',
        url: 'https://www.midway.com.br/',
        type: 'Sociedade de Crédito, Financiamento e Investimento',
        description:
            'A Midway Financeira, conhecida pelos cartões de crédito Riachuelo, oferece uma gama de produtos financeiros, incluindo conta digital, empréstimos pessoais, seguros e serviços de saúde. Fundada em 2008, ela se destaca no mercado brasileiro de cartões Private Label. Seus serviços incluem cartões Visa e Mastercard com benefícios para compras nas lojas Riachuelo, e empréstimos com limites de até R$ 10 mil, podendo ser pagos em até 21 parcelas​​​​​​.',
        slug: 'midway',
        awards: ['2º lugar Onboarding'],
        position: 15,
        score: 6.81
    },
    {
        companyId: 143,
        name: 'Will Bank',
        url: 'https://www.willbank.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Banco digital brasileiro focado em oferecer soluções financeiras simples e acessíveis através de um aplicativo.',
        slug: 'will-bank',
        awards: [],
        position: 16,
        score: 6.75
    },
    {
        companyId: 35,
        name: 'Bradesco',
        url: 'https://www.bradesco.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Um dos maiores bancos privados do Brasil, oferecendo uma ampla gama de produtos e serviços bancários, tanto para pessoas físicas quanto jurídicas.',
        slug: 'bradesco',
        awards: [],
        position: 17,
        score: 6.72
    },
    {
        companyId: 98,
        name: 'Santander',
        url: 'https://www.santander.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco espanhol com forte presença no Brasil, oferecendo uma ampla gama de serviços bancários para pessoas físicas e jurídicas.',
        slug: 'santander',
        awards: [],
        position: 18,
        score: 6.71
    },
    {
        companyId: 39,
        name: 'Banco BV',
        url: 'https://www.bv.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Instituição de crédito focada principalmente em financiamento de veículos, crédito pessoal e financiamento ao consumidor.',
        slug: 'banco-bv',
        awards: [],
        position: 19,
        score: 6.63
    },
    {
        companyId: 135,
        name: 'BANQI',
        url: 'https://www.banqi.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'O banQi é uma conta digital gratuita criada pela parceria entre Via Varejo e Airfox, lançada em 2019. Com a missão de oferecer serviços financeiros acessíveis e inclusivos, o banQi atende principalmente pessoas não servidas pelos bancos tradicionais. Oferece funcionalidades como pagamento de parcelas com dinheiro de volta, cartão de crédito pré-pago e outras opções financeiras. A iniciativa enfatiza a independência, liberdade e tranquilidade financeira para seus usuários​​​​.',
        slug: 'banqi',
        awards: [],
        position: 20,
        score: 6.63
    },
    {
        companyId: 126,
        name: 'Next',
        url: 'https://banconext.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco digital criado e gerenciado pelo Bradesco, com foco em soluções modernas e inovadoras para o público mais jovem.',
        slug: 'next',
        awards: [],
        position: 21,
        score: 6.6
    },
    {
        companyId: 102,
        name: 'Sofisa Direto',
        url: 'https://www.sofisadireto.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Plataforma de investimentos online do Banco Sofisa, permitindo que os clientes invistam em produtos financeiros diretamente pela internet.',
        slug: 'sofisa-direto',
        awards: [],
        position: 22,
        score: 6.46
    },
    {
        companyId: 27,
        name: 'Banco do Brasil',
        url: 'https://www.bb.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Instituição financeira estatal e um dos maiores bancos da América Latina, oferecendo uma variedade de serviços financeiros para clientes no Brasil e no exterior.',
        slug: 'banco-do-brasil',
        awards: [],
        position: 23,
        score: 6.4
    },
    {
        companyId: 149,
        name: 'Superdigital',
        url: 'https://www.superdigital.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Plataforma financeira digital controlada pelo Santander, oferecendo serviços como conta digital, cartão pré-pago e transferências.',
        slug: 'superdigital',
        awards: [],
        position: 24,
        score: 6.34
    },
    {
        companyId: 31,
        name: 'Banco Bari',
        url: 'https://www.bancobari.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Instituição financeira com foco em crédito com garantia de imóvel e consignado, além de soluções de conta digital e investimentos.',
        slug: 'banco-bari',
        awards: [],
        position: 25,
        score: 6.18
    },
    {
        companyId: 32,
        name: 'BMG',
        url: 'https://www.bancobmg.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco conhecido por seus serviços de crédito pessoal e consignado, com presença marcante em todo o Brasil.',
        slug: 'bmg',
        awards: [],
        position: 26,
        score: 6.09
    }
];

const onboarding = [
    {
        companyId: 40,
        name: 'C6',
        url: 'https://www.c6bank.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco digital em crescimento no mercado brasileiro, oferecendo produtos financeiros inovadores e contas sem tarifa.',
        slug: 'c6',
        position: 1,
        score: 8.32
    },
    {
        companyId: 146,
        name: 'Midway',
        url: 'https://www.midway.com.br/',
        type: 'Sociedade de Crédito, Financiamento e Investimento',
        description:
            'A Midway Financeira, conhecida pelos cartões de crédito Riachuelo, oferece uma gama de produtos financeiros, incluindo conta digital, empréstimos pessoais, seguros e serviços de saúde. Fundada em 2008, ela se destaca no mercado brasileiro de cartões Private Label. Seus serviços incluem cartões Visa e Mastercard com benefícios para compras nas lojas Riachuelo, e empréstimos com limites de até R$ 10 mil, podendo ser pagos em até 21 parcelas​​​​​​.',
        slug: 'midway',
        position: 2,
        score: 7.37
    },
    {
        companyId: 38,
        name: 'BTG Pactual',
        url: 'https://www.btgpactual.com/',
        type: 'Banco Múltiplo',
        description:
            'Banco de investimentos líder na América Latina, oferecendo serviços financeiros para investidores institucionais, empresas e clientes individuais.',
        slug: 'btg-pactual',
        position: 3,
        score: 7.25
    },
    {
        companyId: 133,
        name: 'Sicredi',
        url: 'https://www.sicredi.com.br/',
        type: 'Banco Múltiplo Cooperativo',
        description:
            'Rede de cooperativas de crédito que atua em várias regiões do Brasil, focada em promover o desenvolvimento econômico e social de seus membros e da comunidade.',
        slug: 'sicredi',
        position: 4,
        score: 7.2
    },
    {
        companyId: 125,
        name: 'Itaú',
        url: 'https://www.itau.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Um dos maiores e mais tradicionais bancos privados do Brasil, oferecendo uma ampla gama de serviços e produtos financeiros para diversos tipos de clientes.',
        slug: 'itau',
        position: 5,
        score: 7.09
    },
    {
        companyId: 142,
        name: 'Stone',
        url: 'https://www.stone.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'A Stone Pagamentos é uma fintech brasileira que fornece soluções de meios de pagamentos. Ela atua principalmente por meio de serviços de adquirência multibandeiras, utilizando máquinas de cartões que processam transações realizadas com cartões de crédito, débito e voucher. Fundada para facilitar as transações financeiras entre as bandeiras de cartão e os bancos emissores, a Stone tem como objetivo transformar a indústria de meios de pagamentos, equilibrando as forças entre lojistas e bancos, e melhorando a experiência de consumo e a produtividade dos clientes​​​​.',
        slug: 'stone',
        position: 6,
        score: 6.95
    },
    {
        companyId: 143,
        name: 'Will Bank',
        url: 'https://www.willbank.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Banco digital brasileiro focado em oferecer soluções financeiras simples e acessíveis através de um aplicativo.',
        slug: 'will-bank',
        position: 7,
        score: 6.9
    },
    {
        companyId: 135,
        name: 'BANQI',
        url: 'https://www.banqi.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'O banQi é uma conta digital gratuita criada pela parceria entre Via Varejo e Airfox, lançada em 2019. Com a missão de oferecer serviços financeiros acessíveis e inclusivos, o banQi atende principalmente pessoas não servidas pelos bancos tradicionais. Oferece funcionalidades como pagamento de parcelas com dinheiro de volta, cartão de crédito pré-pago e outras opções financeiras. A iniciativa enfatiza a independência, liberdade e tranquilidade financeira para seus usuários​​​​.',
        slug: 'banqi',
        position: 8,
        score: 6.84
    },
    {
        companyId: 136,
        name: 'Mercado Pago',
        url: 'https://www.mercadopago.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Plataforma de pagamentos do Mercado Livre, que também oferece soluções de crédito e um cartão pré-pago.',
        slug: 'mercado-pago',
        position: 9,
        score: 6.83
    },
    {
        companyId: 126,
        name: 'Next',
        url: 'https://banconext.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco digital criado e gerenciado pelo Bradesco, com foco em soluções modernas e inovadoras para o público mais jovem.',
        slug: 'next',
        position: 10,
        score: 6.71
    }
];

const customerLove = [
    {
        companyId: 138,
        name: 'Nubank',
        url: 'https://www.nubank.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Um dos maiores bancos digitais do Brasil, conhecido por seu cartão de crédito roxo e uma plataforma financeira completa sem tarifas abusivas.',
        slug: 'nubank',
        position: 1,
        score: 8.07
    },
    {
        companyId: 132,
        name: 'Sicoob',
        url: 'https://www.sicoob.com.br/',
        type: 'Banco Múltiplo Cooperativo',
        description:
            'Sistema de Cooperativas de Crédito do Brasil, a maior rede de cooperativas de crédito do país, buscando oferecer soluções financeiras para seus cooperados.',
        slug: 'sicoob',
        position: 2,
        score: 8.04
    },
    {
        companyId: 71,
        name: 'Banco Inter',
        url: 'https://www.bancointer.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco digital em rápida expansão, conhecido por oferecer conta corrente gratuita e uma série de outros serviços financeiros sem tarifas.',
        slug: 'banco-inter',
        position: 3,
        score: 7.99
    },
    {
        companyId: 125,
        name: 'Itaú',
        url: 'https://www.itau.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Um dos maiores e mais tradicionais bancos privados do Brasil, oferecendo uma ampla gama de serviços e produtos financeiros para diversos tipos de clientes.',
        slug: 'itau',
        position: 4,
        score: 7.87
    },
    {
        companyId: 98,
        name: 'Santander',
        url: 'https://www.santander.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco espanhol com forte presença no Brasil, oferecendo uma ampla gama de serviços bancários para pessoas físicas e jurídicas.',
        slug: 'santander',
        position: 5,
        score: 7.76
    },
    {
        companyId: 27,
        name: 'Banco do Brasil',
        url: 'https://www.bb.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Instituição financeira estatal e um dos maiores bancos da América Latina, oferecendo uma variedade de serviços financeiros para clientes no Brasil e no exterior.',
        slug: 'banco-do-brasil',
        position: 6,
        score: 7.71
    },
    {
        companyId: 133,
        name: 'Sicredi',
        url: 'https://www.sicredi.com.br/',
        type: 'Banco Múltiplo Cooperativo',
        description:
            'Rede de cooperativas de crédito que atua em várias regiões do Brasil, focada em promover o desenvolvimento econômico e social de seus membros e da comunidade.',
        slug: 'sicredi',
        position: 7,
        score: 7.68
    },
    {
        companyId: 140,
        name: 'Picpay',
        url: 'https://www.picpay.com/',
        type: 'Instituição de Pagamento',
        description:
            'Aplicativo de pagamentos brasileiro que permite aos usuários armazenar dinheiro, fazer pagamentos, transferências e até mesmo comprar créditos para serviços.',
        slug: 'picpay',
        position: 8,
        score: 7.68
    },
    {
        companyId: 139,
        name: 'PagBank',
        url: 'https://www.pagseguro.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Serviço financeiro do grupo UOL. O PagSeguro é uma solução de pagamentos online, enquanto o PagBank oferece serviços bancários digitais, como contas e cartões.',
        slug: 'pagbank',
        position: 9,
        score: 7.59
    },
    {
        companyId: 136,
        name: 'Mercado Pago',
        url: 'https://www.mercadopago.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Plataforma de pagamentos do Mercado Livre, que também oferece soluções de crédito e um cartão pré-pago.',
        slug: 'mercado-pago',
        position: 10,
        score: 7.51
    }
];

const experience = [
    {
        companyId: 125,
        name: 'Itaú',
        url: 'https://www.itau.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Um dos maiores e mais tradicionais bancos privados do Brasil, oferecendo uma ampla gama de serviços e produtos financeiros para diversos tipos de clientes.',
        slug: 'itau',
        position: 1,
        score: 7.79
    },
    {
        companyId: 139,
        name: 'PagBank',
        url: 'https://www.pagseguro.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Serviço financeiro do grupo UOL. O PagSeguro é uma solução de pagamentos online, enquanto o PagBank oferece serviços bancários digitais, como contas e cartões.',
        slug: 'pagbank',
        position: 2,
        score: 7.65
    },
    {
        companyId: 98,
        name: 'Santander',
        url: 'https://www.santander.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco espanhol com forte presença no Brasil, oferecendo uma ampla gama de serviços bancários para pessoas físicas e jurídicas.',
        slug: 'santander',
        position: 3,
        score: 7.45
    },
    {
        companyId: 40,
        name: 'C6',
        url: 'https://www.c6bank.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco digital em crescimento no mercado brasileiro, oferecendo produtos financeiros inovadores e contas sem tarifa.',
        slug: 'c6',
        position: 4,
        score: 7.43
    },
    {
        companyId: 137,
        name: 'Neon',
        url: 'https://www.banconeon.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Banco digital brasileiro que oferece conta digital, cartão de crédito e outras soluções financeiras sem a burocracia tradicional.',
        slug: 'neon',
        position: 5,
        score: 7.42
    },
    {
        companyId: 88,
        name: 'Banco Pan',
        url: 'https://www.bancopan.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco comercial que atua em diversos segmentos financeiros, como crédito ao consumidor e financiamento de veículos.',
        slug: 'banco-pan',
        position: 6,
        score: 7.4
    },
    {
        companyId: 71,
        name: 'Banco Inter',
        url: 'https://www.bancointer.com.br/',
        type: 'Banco Múltiplo',
        description:
            'Banco digital em rápida expansão, conhecido por oferecer conta corrente gratuita e uma série de outros serviços financeiros sem tarifas.',
        slug: 'banco-inter',
        position: 7,
        score: 7.4
    },
    {
        companyId: 132,
        name: 'Sicoob',
        url: 'https://www.sicoob.com.br/',
        type: 'Banco Múltiplo Cooperativo',
        description:
            'Sistema de Cooperativas de Crédito do Brasil, a maior rede de cooperativas de crédito do país, buscando oferecer soluções financeiras para seus cooperados.',
        slug: 'sicoob',
        position: 8,
        score: 7.35
    },
    {
        companyId: 38,
        name: 'BTG Pactual',
        url: 'https://www.btgpactual.com/',
        type: 'Banco Múltiplo',
        description:
            'Banco de investimentos líder na América Latina, oferecendo serviços financeiros para investidores institucionais, empresas e clientes individuais.',
        slug: 'btg-pactual',
        position: 9,
        score: 7.32
    },
    {
        companyId: 136,
        name: 'Mercado Pago',
        url: 'https://www.mercadopago.com.br/',
        type: 'Instituição de Pagamento',
        description:
            'Plataforma de pagamentos do Mercado Livre, que também oferece soluções de crédito e um cartão pré-pago.',
        slug: 'mercado-pago',
        position: 10,
        score: 7.3
    }
];

export default {
    general,
    experience,
    onboarding,
    customerLove
};
