import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Form from './Form';

const company = signal({});
const bcbInstitutions = signal([]);

export default function AdminCompanyDetails() {
    const { companyId } = useParams();

    const fetchData = useCallback(async () => {
        try {
            company.value = await AdminService.getCompany(companyId);
            bcbInstitutions.value = await AdminService.getBcbInstitutions();
            console.log('Company details:', company.value);
            pageTitle.value = company.value.name;
        } catch (error) {
            console.error('Failed to load company details:', error);
        }
    }, [companyId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const updateCompany = async (companyId, data) => {
        try {
            company.value = await AdminService.updateCompany(companyId, data);
            console.log('Updated company:', company.value);
            alert('Empresa atualizada com sucesso!');
        } catch (error) {
            console.error(error);
            alert('Erro ao atualizar empresa!');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (company.value.bcbInstitutionId === '') {
            company.value.bcbInstitutionId = null;
        }
        updateCompany(companyId, company.value);
    };

    const formFields = [
        {
            label: 'Nome',
            name: 'name',
            value: company.value.name || ''
        },
        {
            label: 'Slug',
            name: 'slug',
            value: company.value.slug || ''
        },
        {
            label: 'Descrição',
            name: 'description',
            type: 'textarea',
            value: company.value.description || ''
        },
        {
            label: 'Tipo',
            name: 'type',
            value: company.value.type || ''
        },
        {
            label: 'URL',
            name: 'url',
            type: 'url',
            value: company.value.url || ''
        },
        {
            label: 'Verificado',
            name: 'verified',
            type: 'checkbox',
            checked: company.value.verified || false
        },
        {
            label: 'Visível',
            name: 'isVisible',
            type: 'checkbox',
            checked: company.value.isVisible || false
        },
        {
            label: 'Instituição BACEN',
            name: 'bcbInstitutionId',
            type: 'select',
            value: company.value.bcbInstitutionId || '',
            options: bcbInstitutions.value
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((i) => ({
                    key: i.bcbInstitutionId,
                    value: i.name
                }))
        }
    ];

    return (
        <Form
            fields={formFields}
            handleSubmit={handleSubmit}
            signal={company}
        />
    );
}
