import React, { useEffect, useCallback } from 'react';
import { ReportService } from '../../../services';
import { currentReport, currentCompany } from '../../../signals';
import { Layout, Feedback } from '../../../components';

export default function FeedbackHome() {
    const company = currentCompany.value;
    const { companyId } = company;

    const loadCompanyDetails = useCallback(async () => {
        try {
            if (companyId) {
                currentReport.value = await ReportService.getReport(companyId);
            }
        } catch (error) {
            console.error('Failed to load company details:', error);
        }
    }, [companyId]);

    useEffect(() => {
        loadCompanyDetails();
    }, [companyId, loadCompanyDetails]);

    const report = currentReport.value;

    if (!report) {
        return null;
    }

    const reportData = report.data.feedback;

    return (
        <>
            <Layout.Head title={`Index - ${company.name}: Voz dos Usuários`} />
            <main>
                <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div
                        className="flex items-center text-center px-20 mt-[150px]"
                        style={{ height: '400px' }}
                    >
                        <Feedback.WordCloud data={reportData.wordCloud} />
                    </div>
                    <div>
                        <Feedback.Testimonials data={reportData.testimonials} />
                    </div>
                </div>
            </main>{' '}
        </>
    );
}
