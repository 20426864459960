import { Fragment, useCallback, useEffect } from 'react';
import { Disclosure, Menu, Transition, Popover } from '@headlessui/react';
import { MagnifyingGlassIcon, BookmarkIcon } from '@heroicons/react/20/solid';
import {
    Bars3Icon,
    ChevronDownIcon,
    XMarkIcon
} from '@heroicons/react/24/outline';
import logo from '../../svg/index-logo-white-small.svg';
import { user, searchIsOpen } from '../../signals';
import { classNames } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import { UI } from '..';
import SearchBox from './SearchBox';

const openSearch = () => {
    searchIsOpen.value = true;
    setTimeout(() => {
        document.getElementById('search-input').focus();
    }, 50);
};

const SearchBar2 = () => {
    const handleKeyPress = useCallback((event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            openSearch();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <UI.Button
            variant=""
            className={classNames(
                'w-full max-w-lg lg:max-w-xs justify-start font-normal relative',
                searchIsOpen.value
                    ? 'bg-white text-gray-700 ring-0'
                    : 'bg-gray-700 hover:bg-gray-500 text-gray-400 hover:text-white'
            )}
            onClick={openSearch}
        >
            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
            Buscar instituição...
            <div className="absolute right-0 pr-2 hidden lg:flex">
                <kbd className="inline-flex items-center rounded border border-gray-400 px-1 py-0.5 font-sans text-xs text-gray-400">
                    {navigator.platform.includes('Win') ? 'ctrl+' : '⌘'}K
                </kbd>
            </div>
        </UI.Button>
    );
};

const FavoritesMenu = () => {
    const favs = user.value.favorites.sort((a, b) =>
        a.name.localeCompare(b.name)
    );

    return (
        <Popover className="relative">
            <Popover.Button className="inline-flex items-center rounded-md py-2 px-3 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white">
                <span>Favoritos</span>
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-md bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                        {favs.length > 0 && (
                            <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                                {favs.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                                    >
                                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg">
                                            <UI.Icon32
                                                className="h-8 w-8"
                                                aria-hidden="true"
                                                slug={item.slug}
                                            />
                                        </div>
                                        <div>
                                            <a
                                                href={`/${item.slug}`}
                                                className="font-semibold text-gray-900"
                                            >
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </a>
                                            <p className="mt-1 text-gray-600">
                                                {item.type}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {favs.length === 0 && (
                            <div className="px-8 py-6">
                                <div className="flex items-center gap-x-3">
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">
                                        <BookmarkIcon
                                            className="h-5 w-5 inline mr-2 text-idwallDarkPurple-600"
                                            aria-hidden="true"
                                        />
                                        Favoritos
                                    </h3>
                                </div>
                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                    Veja aqui as instituições que você marcou
                                    como Favoritas.
                                </p>
                            </div>
                        )}
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

const FavoritesMenuMini = () => {
    const favs = user.value.favorites.sort((a, b) =>
        a.name.localeCompare(b.name)
    );
    return (
        <>
            {favs.length > 0 && (
                <>
                    <hr className="border-gray-700" /> {/* Divider */}
                    <div className="text-xs font-semibold px-3 leading-6 text-gray-400">
                        Favoritos
                    </div>
                    {favs.map((item) => (
                        <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={`/${item.companyId}`}
                            className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                        >
                            <UI.Icon24
                                slug={item.slug}
                                className="inline-block mr-3 -my-1.5"
                            />
                            {item.name}
                        </Disclosure.Button>
                    ))}
                </>
            )}
        </>
    );
};

const ProfileDropdown = () => {
    // const userNavigation = [
    //     { name: 'Minha conta', href: '/minha-conta' },
    //     { name: user.value.email.split('@')[1], href: '#' }
    // ];

    return (
        <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="hidden lg:flex lg:items-center">
                    <span
                        className="ml-4 leading-6 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                        aria-hidden="true"
                    >
                        {user.value.firstName}
                    </span>
                    <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </span>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {/* {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                            {({ active }) => (
                                <a
                                    href={item.href}
                                    className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                    )}
                                >
                                    {item.name}
                                </a>
                            )}
                        </Menu.Item>
                    ))}
                    <hr className="border-gray-100 my-2" /> */}
                    <Menu.Item>
                        {({ active }) => (
                            <a
                                href="/logout"
                                className={classNames(
                                    active ? 'bg-gray-50' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                            >
                                Sair
                            </a>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default function Navbar({ page }) {
    const navigate = useNavigate();

    const navigation = [
        {
            name: 'Instituições',
            href: '/instituicoes',
            current: page === 'instituicoes'
        }
        // {
        //     name: 'Comparativo',
        //     href: '/comparativo',
        //     current: page === 'comparativo'
        // }
    ];
    const { isLoggedIn } = user.value;

    return (
        <>
            <SearchBox />
            <Disclosure as="nav" className="bg-gray-800">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                            <div className="relative flex h-16 items-center justify-between">
                                <div className="flex items-center px-2 lg:px-0">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-8 w-auto cursor-pointer"
                                            src={logo}
                                            alt="Index by idwall"
                                            onClick={() => navigate('/')}
                                        />
                                    </div>
                                    <div className="hidden lg:ml-6 lg:block">
                                        <div className="flex space-x-4">
                                            <UI.Button
                                                as="a"
                                                variant={
                                                    isLoggedIn
                                                        ? 'primary'
                                                        : 'menu'
                                                }
                                                href="/2024"
                                                className="ml-6 inline-flex"
                                            >
                                                Relatório 2024
                                            </UI.Button>
                                            {navigation.map((item) => (
                                                <UI.Button
                                                    as="a"
                                                    variant={
                                                        item.current
                                                            ? 'menu-active'
                                                            : 'menu'
                                                    }
                                                    key={item.name}
                                                    href={item.href}
                                                    aria-current={
                                                        item.current
                                                            ? 'page'
                                                            : undefined
                                                    }
                                                    target={item.target}
                                                >
                                                    {item.name}
                                                </UI.Button>
                                            ))}
                                            {isLoggedIn && <FavoritesMenu />}
                                            {!isLoggedIn && (
                                                <UI.Button as="a" href="/login">
                                                    Login
                                                </UI.Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                                    <SearchBar2 />
                                    {/* <SearchBar /> */}
                                </div>
                                <div className="flex lg:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">
                                            Open main menu
                                        </span>
                                        {open ? (
                                            <XMarkIcon
                                                className="block h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <Bars3Icon
                                                className="block h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                                <div className="hidden lg:ml-4 lg:block">
                                    <div className="flex items-center">
                                        {/* Profile dropdown */}
                                        {isLoggedIn && <ProfileDropdown />}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="lg:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2">
                                {navigation.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                                {isLoggedIn && <FavoritesMenuMini />}
                                {!isLoggedIn && (
                                    <UI.Button as="a" href="/login">
                                        Login
                                    </UI.Button>
                                )}
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </>
    );
}
