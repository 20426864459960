import { useEffect, useCallback } from 'react';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Table from './Table';
import { UI } from '../../components';

const list = signal([]);

export default function AdminUserList() {
    const fetchData = useCallback(async () => {
        try {
            list.value = await AdminService.getUsers();
            pageTitle.value = `Usuários (${list.value.length})`;
            console.log('Users list:', list.value);
        } catch (error) {
            console.error('Failed to load users list:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const headers = [
        'ID',
        'First Name',
        'Last Name',
        'Email',
        'Type',
        'isValidUser',
        'isActive',
        ''
    ];

    const rows = list.value.map((item, index) => [
        item.userId,
        item.firstName,
        item.lastName,
        item.email,
        item.type,
        item.isValidUser ? 'Sim' : 'Não',
        item.isActive ? 'Sim' : 'Não',
        <UI.Button
            as="a"
            key={index}
            href={`/admin/users/${item.userId}`}
            variant="link"
            className="text-white hover:text-white"
        >
            Editar
        </UI.Button>
    ]);

    return (
        <div>
            <Table headers={headers} rows={rows} />
        </div>
    );
}
