import { ResponsiveBar } from '@nivo/bar';
import { chartTheme, getChartColors } from '../../styles';

const keys = ['Muito baixo', 'Baixo', 'Neutro', 'Alto', 'Muito Alto'];

export default function ReportEffortPerception({ data }) {
    return (
        <ResponsiveBar
            data={data}
            theme={chartTheme}
            keys={keys}
            indexBy="x"
            margin={{ top: 70, right: 50, bottom: 50, left: 50 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={getChartColors(keys.length)}
            borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]]
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                truncateTickAt: 0
            }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 32,
                truncateTickAt: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -40,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            role="application"
            ariaLabel="Gráfico de experiência de esforço"
            barAriaLabel={(e) => e.id + ': ' + e.formattedValue + ' in x: ' + e.indexValue}
            enableLabel={true}
            labelTextColor="white"
        />
    );
}
