import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Slideshow, Layout } from '..';
import {
    CompanyService,
    ExperienceService,
    ReportService
} from '../../services';
import {
    currentCompany,
    currentExperience,
    currentReport
} from '../../signals';

export default function ReportContainer() {
    const { page = 1, companySlug } = useParams();
    const currentPage = parseInt(page);

    const loadCompanyDetails = useCallback(async () => {
        try {
            currentCompany.value =
                await CompanyService.fetchCompanyDetailsByName(companySlug);
            const { companyId } = currentCompany.value;
            if (companyId) {
                currentExperience.value = await ExperienceService.getExperience(
                    companyId
                );
                currentReport.value = await ReportService.getReport(companyId);
            }
        } catch (error) {
            console.error('Failed to load company details:', error);
        }
    }, [companySlug]);

    useEffect(() => {
        loadCompanyDetails();
    }, [companySlug, loadCompanyDetails]);

    const report = currentReport.value;

    if (!report) {
        return null;
    }

    const reportData = report.data;

    const pages = [
        {
            id: 1,
            title: 'Bem-vindo',
            component: <Slideshow.Welcome />
        },
        {
            id: 2,
            title: 'Dados demográficos',
            component: (
                <Slideshow.Demography data={reportData.demographicData} />
            )
        },
        {
            id: 3,
            component: <Slideshow.Comparison data={reportData.indicators} />,
            title: 'Comparativo dos indicadores'
        },
        {
            id: 4,
            component: <Slideshow.Chart data={reportData.effortPerception} />,
            title: 'Outros dados'
        },
        {
            id: 5,
            component: <Slideshow.Table data={reportData.rankingTable} />,
            title: 'Notas Gerais'
        },
        {
            id: 6,
            component: <Slideshow.WordCloud data={reportData.feedback} />,
            title: 'O que os usuários estão dizendo'
        },
        {
            id: 7,
            component: <Slideshow.Ranking />,
            title: 'Ranking'
        },
        {
            id: 8,
            component: <Slideshow.Experience />,
            title: 'Experiência da Jornada'
        },
        { id: 9, component: <Slideshow.End />, title: 'Obrigado' }
    ];

    const data = pages.find((p) => `${p.id}` === page);
    const totalPages = pages.length;

    if (!data) {
        return null;
    }

    const companyName = currentCompany.value.name;

    return (
        <>
            <Layout.Head title={`Relatório Completo ${companyName}`} />
            <Slideshow.Layout
                companySlug={companySlug}
                page={currentPage}
                title={data.title}
                subtitle={companyName}
                totalPages={totalPages}
            >
                {data.component}
            </Slideshow.Layout>
        </>
    );
}
