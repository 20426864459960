import { getChartColors, chartTheme, formatNumbers } from '../../styles';
import { ResponsiveLine } from '@nivo/line';

const chartColors = getChartColors(3);

const theme = {
    ...chartTheme,
    axis: {
        ...chartTheme.axis,
        ticks: {
            ...chartTheme.axis.ticks,
            text: {
                ...chartTheme.axis.ticks.text,
                fontSize: '10px'
            }
        }
    }
};

const processData = (data) => {
    // Agrupar por data e dispositivo
    const groupedData = [];

    data.forEach((app) => {
        app.downloads.forEach((downloadEntry) => {
            const date = new Date(downloadEntry.date);
            const device = downloadEntry.device;

            // Find groupedData by id attribute
            const index = groupedData.findIndex((item) => item.id === device);

            // If not found, add new item
            if (index === -1) {
                groupedData.push({
                    id: device,
                    data: []
                });
            }

            const newIndex = groupedData.findIndex(
                (item) => item.id === device
            );

            // If found, add new data to item
            groupedData[newIndex].data.push({
                x: date.toLocaleDateString('pt-BR', {
                    month: 'short',
                    day: 'numeric'
                }),
                y: parseInt(downloadEntry.total, 10)
            });
        });
    });

    // Mudar ids para nomes formatados (Android, iPhone, iPad)
    groupedData.forEach((item) => {
        if (item.id === 'android') {
            item.id = 'Android';
        } else if (item.id === 'iphone') {
            item.id = 'iPhone';
        } else if (item.id === 'ipad') {
            item.id = 'iPad';
        }
    });

    return groupedData;
};

export default function ChartDownload({ data }) {
    const chartData = processData(data);

    return (
        <ResponsiveLine
            data={chartData}
            theme={theme}
            keys={['Android', 'iPhone', 'iPad']}
            indexBy="date"
            margin={{ top: 30, right: 30, bottom: 110, left: 70 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={chartColors}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisBottom={{
                tickRotation: -90
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Usuários',
                legendOffset: -50,
                legendPosition: 'middle',
                format: formatNumbers
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 90,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );
}
