/* eslint-disable no-unused-vars */
import { currentCompany, modalCTAReport } from '../../signals';
import { UI, Layout } from '..';

const closeModal = () => {
    modalCTAReport.value = false;
};

export default function Modal() {
    const { name } = currentCompany.value;

    return (
        <Layout.Modal
            showFooter={false}
            show={modalCTAReport.value}
            onClose={closeModal}
        >
            <h2 className="text-2xl font-semibold tracking-tight text-gray-900 sm:text-3xl -mt-6">
                Relatório Completo de Experiência com dados do {name} e mais 25
                instituições.
            </h2>
            <div className="mx-auto mt-10 flex flex-col gap-10 bg-white/5 ring-1 ring-white/10 sm:rounded-3xl lg:flex-row lg:items-center">
                <div>
                    <p className="mt-6 text-xl leading-8 text-gray-600">
                        Informações detalhadas sobre os diferentes fluxos do
                        aplicativo, casos de uso, demografia da pesquisa de
                        mercado e avaliações de usuários.
                    </p>

                    <div className="mt-10">
                        <UI.Button
                            size="xl"
                            className="min-w-64"
                            as="a"
                            href="/contato"
                        >
                            Fale conosco
                        </UI.Button>
                    </div>
                </div>
                <img
                    src="/img/ranking-experiencia-2024-promo.png"
                    alt=""
                    className="aspect-[7/5] w-[20rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                />
            </div>
        </Layout.Modal>
    );
}
