import { useEffect, useCallback } from 'react';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Table from './Table';
import { UI } from '..';

const list = signal([]);
const companies = signal([]);
const expCriterias = signal([]);
const expJourneys = signal([]);

export default function AdminExpScoreList() {
    pageTitle.value = 'Scores';

    const fetchData = useCallback(async () => {
        try {
            list.value = await AdminService.getExpScores();
            companies.value = await AdminService.getCompanies();
            expCriterias.value = await AdminService.getExpCriterias();
            expJourneys.value = await AdminService.getExpJourneys();
            console.log('Company list:', companies.value);
            console.log('Score list:', list.value);
        } catch (error) {
            console.error('Failed to load report list:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const headers = ['ID', 'Company', 'Criteria', 'Journey', 'type', 'value', 'details', ''];

    const rows = list.value.map((item, index) => [
        item.expScoreId,
        (companies.value.find((company) => company.companyId === item.companyId) || [{ name: '...' }]).name,
        (expCriterias.value.find((criteria) => criteria.expCriteriaId === item.expCriteriaId) || [{ name: '...' }]).name,
        (expJourneys.value.find((journey) => journey.expJourneyId === item.expJourneyId) || [{ name: '...' }]).name,
        item.type,
        item.value,
        <div className="truncate w-96" key={index}>
            {JSON.stringify(item.details)}
        </div>,
        <UI.Button as="a" key={index} href={`/admin/exp/scores/${item.expScoreId}`} variant="link" className="text-white hover:text-white">
            Editar
        </UI.Button>
    ]);

    return (
        <div>
            <div className="float-right">
                <UI.Button as="a" href="/admin/exp/scores/create">
                    Adicionar Score
                </UI.Button>
            </div>
            <Table headers={headers} rows={rows} />
        </div>
    );
}
