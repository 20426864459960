import React, { useState, useEffect } from 'react';
import logo from '../../svg/index-logo-color.svg';
import { BiMailSend } from 'react-icons/bi';
import { CiRedo } from 'react-icons/ci';
import { UI } from '../../components';
import { AuthService } from '../../services';

export default function Login() {
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        let intervalId;

        if (counter > 0) {
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [counter]);

    const getEmailFromQueryString = () => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('email');
    };

    const email = getEmailFromQueryString();

    const handleResendClick = () => {
        setCounter(60);
        if (email) {
            AuthService.sendMagicLink(email);
        }
    };

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-16">
                    <img
                        className="mx-auto h-40 w-auto"
                        src={logo}
                        alt="Index by idwall"
                    />
                </div>

                <div className="my-20 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div className="text-center">
                        <BiMailSend className="mx-auto h-12 w-auto text-gray-400" />
                        <h2 className="mt-4 text-xl font-semibold text-gray-600">
                            Confira seu e-mail
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                            Enviamos o link para login
                        </p>

                        <div className="mt-16">
                            <UI.Button
                                size="xl"
                                className="min-w-64"
                                onClick={handleResendClick}
                                disabled={counter > 0}
                            >
                                <CiRedo
                                    className="-ml-0.5 mr-1.5 h-5 w-5"
                                    aria-hidden="true"
                                />
                                {counter > 0
                                    ? `Aguarde ${counter}s`
                                    : 'Enviar novamente'}
                            </UI.Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
