import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { UI, Slideshow } from '..';
import { shareReportModalIsOpen } from '../../signals';
import logo from '../../svg/index-logo-color.svg';

function Pagination({ currentPage, totalPages, urlPrefix }) {
    // Gerando os links para a paginação
    const pageLinks = [];
    for (let i = 1; i <= totalPages; i++) {
        pageLinks.push(i);
    }

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Relatório de Experiência 2024 - idwall
                    </p>
                </div>
                <div>
                    <nav
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                    >
                        <Link
                            to={`${urlPrefix}/${currentPage - 1}`}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            <span className="sr-only">Anterior</span>
                            <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </Link>
                        {pageLinks.map((page) => (
                            <Link
                                key={page}
                                to={`${urlPrefix}/${page}`}
                                className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                                    page === currentPage
                                        ? 'bg-idwallDarkPurple-600 text-white'
                                        : 'text-gray-900'
                                } ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-gray-400 focus:z-20 focus:outline-offset-0`}
                            >
                                {page}
                            </Link>
                        ))}
                        <Link
                            to={`${urlPrefix}/${currentPage + 1}`}
                            disabled={currentPage === totalPages}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            <span className="sr-only">Próxima</span>
                            <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
    );
}

function Header({ title, subtitle }) {
    return (
        <div className="border-b border-gray-200 bg-white px-6 py-4">
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <a href="/" className="flex-none mx-4 p-0">
                    <img
                        className="h-14 -mb-4"
                        src={logo}
                        alt="Index by idwall"
                    />
                </a>
                <div className="ml-4 mt-4 flex-auto">
                    <h3 className="text-xl font-semibold leading-6 text-gray-900">
                        {title}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{subtitle}</p>
                </div>
                <div className="ml-4 mt-4 flex-shrink-0">
                    <UI.Button
                        className="relative z-50"
                        onClick={() => {
                            shareReportModalIsOpen.value = true;
                        }}
                    >
                        Compartilhar
                    </UI.Button>
                </div>
            </div>
        </div>
    );
}

function HoverPanelLeft({ currentPage, totalpages, urlPrefix }) {
    if (currentPage === 1) {
        return null;
    }

    return (
        <div className="fixed top-0 left-0 h-full w-24 py-[90px]">
            <Link
                href="#"
                to={`${urlPrefix}/${currentPage - 1}`}
                className="flex flex-col items-center justify-center h-full opacity-0 hover:opacity-100 z-50"
                style={{
                    background:
                        'radial-gradient(circle, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 100%)'
                }}
            >
                <div className="bg-white rounded-full shadow-md p-2">
                    <ChevronLeftIcon
                        className="h-5 w-5 text-black"
                        aria-hidden="true"
                    />
                </div>
                <p className="text-white mt-4">Anterior</p>
            </Link>
        </div>
    );
}

function HoverPanelRight({ currentPage, totalPages, urlPrefix }) {
    if (currentPage === totalPages) {
        return null;
    }

    return (
        <div className="fixed top-0 right-0 h-full w-24 py-[90px]">
            <Link
                href="#"
                to={`${urlPrefix}/${currentPage + 1}`}
                className="flex flex-col items-center justify-center h-full opacity-0 hover:opacity-100 z-50"
                style={{
                    background:
                        'radial-gradient(circle, rgba(0,0,0,.3) 0%, rgba(0,0,0,0) 100%)'
                }}
            >
                <div className="bg-white rounded-full shadow-md p-2">
                    <ChevronRightIcon
                        className="h-5 w-5 text-black"
                        aria-hidden="true"
                    />
                </div>
                <p className="text-white mt-4">Próxima</p>
            </Link>
        </div>
    );
}

export default function ReportLayout({
    companySlug,
    page,
    title,
    subtitle,
    children,
    totalPages
}) {
    return (
        <>
            <Slideshow.ShareModal />
            <main className="bg-gray-200">
                <HoverPanelLeft
                    currentPage={parseInt(page)}
                    totalPages={totalPages}
                    urlPrefix={`/${companySlug}/report`}
                />
                <HoverPanelRight
                    currentPage={parseInt(page)}
                    totalPages={totalPages}
                    urlPrefix={`/${companySlug}/report`}
                />
                <div className="overflow-hidden bg-white shadow-sm rounded-xl flex flex-col h-screen border-8 border-gray-200">
                    <Header title={title} subtitle={subtitle} />
                    <div className="border-t border-gray-200 flex-1 overflow-y-auto">
                        {children}
                    </div>
                    <Pagination
                        currentPage={parseInt(page)}
                        totalPages={totalPages}
                        urlPrefix={`/${companySlug}/report`}
                    />
                </div>
            </main>
        </>
    );
}
