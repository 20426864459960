import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthService } from '../../services';
import { user } from '../../signals';
import { UI } from '../../components';
import { AiOutlineLoading } from 'react-icons/ai';

export default function Login() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const magicLogin = async (token) => {
        const loggedUser = await AuthService.loginMagic(token);

        console.log('loggedUser', loggedUser);

        if (!loggedUser) {
            navigate('/login');
        } else {
            const userData = loggedUser.user;
            user.value = { ...userData, isLoggedIn: true };
            localStorage.setItem('user', JSON.stringify(user.value));

            // eslint-disable-next-line no-undef
            gtag('event', 'gtag_login');

            navigate('/');
        }
    };

    useEffect(() => {
        if (token) {
            magicLogin(token);
        } else {
            navigate('/login');
        }
    }, [token]);

    // Como o backend não está tratando erro
    // este é um workaround para mostrar uma mensagem de erro
    // se o usuário não for redirecionado em 3 segundos
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8">
            {isLoading && (
                <>
                    <AiOutlineLoading className="animate-spin h-12 w-12 mx-auto text-gray-400" />
                    <div className="text-center">
                        <h2 className="mt-10 text-xl font-semibold text-gray-600">
                            Entrando...
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                            Validando login
                        </p>
                    </div>
                </>
            )}

            {!isLoading && (
                <div className="text-center">
                    <h2 className="mt-4 text-xl font-semibold text-gray-600">
                        Erro ao validar login
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                        Por favor, tente novamente
                    </p>

                    <div className="mt-16">
                        <UI.Button
                            as="a"
                            href="/login"
                            size="xl"
                            className="inline-flex items-center"
                        >
                            Voltar
                        </UI.Button>
                    </div>
                </div>
            )}
        </div>
    );
}
