export default function getFormData(report, companies) {
    return [
        {
            label: 'Company',
            name: 'companyId',
            type: 'select',
            value: report.companyId || '',
            options: companies.map((company) => ({
                key: company.companyId,
                value: company.name
            }))
        },
        {
            label: 'Dados',
            name: 'data',
            type: 'json',
            value: report.data || {}
        },
        {
            label: 'É visível',
            name: 'isVisible',
            type: 'checkbox',
            checked: report.isVisible || false
        }
    ];
}
