import { pageTitle } from '../../signals';
import { UI } from '../../components';

const navigation = [
    { name: 'Usuários', href: '/admin/users' },
    { name: 'Empresas', href: '/admin/companies' },
    { name: 'Relatórios', href: '/admin/reports' },
    { name: 'Experience Score', href: '/admin/exp/scores' },
    { name: 'Instituições BACEN', href: '/admin/bcb/institutions' },
    { name: 'Conglomerados BACEN', href: '/admin/bcb/conglomerates' }
    // { name: 'Upload', href: '/admin/upload' }
];

export default function AdminHome() {
    pageTitle.value = 'Admin';

    return (
        <nav className="flex flex-1 flex-col" aria-label="Sidebar">
            <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                    <li key={item.name}>
                        <UI.Button variant="menu" as="a" href={item.href}>
                            {item.name}
                        </UI.Button>
                    </li>
                ))}
            </ul>
        </nav>
    );
}
