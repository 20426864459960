import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { Layout } from '../components';

const tiers = [
    {
        name: 'Basic',
        id: 'tier-basic',
        href: '/checkout',
        price: { monthly: 'R$ 67', annually: 'R$ 799' },
        description: 'Acesso a informações básicas.',
        features: [
            '5 produtos',
            'Até 1.000 assinantes',
            'Análises básicas',
            'Tempo de resposta de suporte de 48 horas'
        ]
    },
    {
        name: 'Enterprise',
        id: 'tier-essential',
        href: '/checkout',
        price: { monthly: 'R$ 565', annually: 'R$ 6.780' },
        description: 'Acesso a todas as informações.',
        features: [
            '25 produtos',
            'Até 10.000 assinantes',
            'Análises avançadas',
            'Tempo de resposta de suporte de 24 horas',
            'Automações de marketing'
        ]
    },
    {
        name: 'Personalizado',
        id: 'tier-growth',
        href: '/checkout',
        description:
            'Experiência Hands-On com especialistas e relatórios customizados com informações exclusivas.',
        features: [
            'Produtos ilimitados',
            'Assinantes ilimitados',
            'Análises avançadas',
            'Tempo de resposta de suporte dedicado de 1 hora',
            'Automações de marketing',
            'Ferramentas de relatórios personalizados'
        ]
    }
];

export default function Pricing() {
    return (
        <>
            <Layout.Head title="Index+" />
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl sm:text-center">
                        <h2 className="text-base font-semibold leading-7 text-idwallDarkPurple-600">
                            Index+
                        </h2>
                        <p className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                            Amplie sua visão.
                            <br />
                            Melhore sua tomada de decisão.
                        </p>
                    </div>
                    <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 sm:text-center">
                        Transforme a maneira como você compreende o mercado
                        financeiro digital com insights aprofundados e dados
                        confiáveis
                    </p>
                    <div className="mt-20 flow-root">
                        <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
                            {tiers.map((tier) => (
                                <div
                                    key={tier.id}
                                    className="pt-16 lg:px-8 lg:pt-0 xl:px-14"
                                >
                                    <h3
                                        id={tier.id}
                                        className="text-base font-semibold leading-7 text-gray-900"
                                    >
                                        {tier.name}
                                    </h3>
                                    <div className="h-20">
                                        {tier.price && (
                                            <>
                                                <p className="mt-6 flex items-baseline gap-x-1">
                                                    <span className="text-5xl font-semibold tracking-tight text-gray-900">
                                                        {tier.price.annually}
                                                    </span>
                                                    <span className="text-sm font-semibold leading-6 text-gray-600">
                                                        /ano
                                                    </span>
                                                </p>
                                                <p className="mt-3 text-sm leading-6 text-gray-500">
                                                    Equivale a{' '}
                                                    {tier.price.monthly} por mês
                                                </p>
                                            </>
                                        )}
                                        {!tier.price && (
                                            <p className="mt-6 flex items-baseline gap-x-1">
                                                <span className="text-3xl font-semibold tracking-tight text-gray-900 mt-6">
                                                    Entre em contato
                                                </span>
                                            </p>
                                        )}
                                    </div>
                                    <a
                                        href={tier.href}
                                        aria-describedby={tier.id}
                                        className="mt-10 block rounded-md bg-idwallDarkPurple-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-idwallDarkPurple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-idwallDarkPurple-600"
                                    >
                                        Escolher plano
                                    </a>
                                    <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">
                                        {tier.description}
                                    </p>
                                    <ul
                                        role="list"
                                        className="mt-6 space-y-3 text-sm leading-6 text-gray-600"
                                    >
                                        {tier.features.map((feature) => (
                                            <li
                                                key={feature}
                                                className="flex gap-x-3"
                                            >
                                                <CheckCircleIcon
                                                    className="h-6 w-5 flex-none text-idwallDarkPurple-600"
                                                    aria-hidden="true"
                                                />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
