import { useEffect, useCallback } from 'react';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Table from './Table';
import { UI } from '../../components';

const list = signal([]);
const companies = signal([]);

export default function AdminReportList() {
    pageTitle.value = 'Relatórios';

    const fetchData = useCallback(async () => {
        try {
            list.value = await AdminService.getReports();
            companies.value = await AdminService.getCompanies();
            console.log('Company list:', companies.value);
            console.log('Report list:', list.value);
        } catch (error) {
            console.error('Failed to load report list:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const headers = ['ID', 'Company', 'Year', 'Data', 'isVisible', ''];

    const rows = list.value.map((item, index) => [
        item.reportId,
        (companies.value.find((company) => company.companyId === item.companyId) || [{ name: '...' }]).name,
        item.year,
        <div className="truncate w-96" key={index}>
            {JSON.stringify(item.data)}
        </div>,
        item.isVisible ? 'Sim' : 'Não',
        <UI.Button as="a" key={index} href={`/admin/reports/${item.reportId}`} variant="link" className="text-white hover:text-white">
            Editar
        </UI.Button>
    ]);

    return (
        <div>
            <div className="float-right">
                <UI.Button as="a" href="/admin/reports/create">
                    Adicionar relatório
                </UI.Button>
            </div>
            <Table headers={headers} rows={rows} />
        </div>
    );
}
