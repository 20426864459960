export let BASE_URL = '';
if (process.env.NODE_ENV === 'development') {
    BASE_URL = 'http://localhost:4321/api/v1';
} else {
    BASE_URL = 'https://index-backend-179bacd7050f.herokuapp.com/api/v1';
}

export const request =
    (method) =>
    (path, data = null, token = null) =>
        send({ method, path, data, token });

export const get = request('GET');
export const del = request('DELETE');
export const post = request('POST');
export const put = request('PUT');

async function send({ method, path, data, token }) {
    const opts = { method, headers: {}, credentials: 'include' };
    if (data) {
        opts.headers['Content-Type'] = 'application/json';
        opts.body = JSON.stringify(data);
    }
    if (token) {
        opts.headers.Authorization = `Token ${token}`;
    }
    try {
        const response = await fetch(`${BASE_URL}${path}`, opts);

        if (response.status === 401) {
            // TODO: mostrar toast (sessão expirada, faça login novamente)
            window.location.href = '/logout';
            return;
        }

        const json = await response.text();

        // TODO: corrigir para fazer o parse do json aqui
        // O tratamento está sendo feito em todo componente que chama a api
        // return JSON.parse(json);
        return json;
    } catch (err) {
        throw err;
    }
}
