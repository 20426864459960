import { AiOutlineRadarChart, AiOutlineTable } from 'react-icons/ai';
import { LuBarChart4, LuBarChartHorizontal } from 'react-icons/lu';
import { classNames } from '../../helpers';

export default function TabsChartType({
    onClick,
    current,
    selectedDimensions = []
}) {
    const tabs = [
        {
            name: 'Vertical',
            icon: LuBarChart4,
            show: selectedDimensions.length > 0
        },
        {
            name: 'Horizontal',
            icon: LuBarChartHorizontal,
            show: selectedDimensions.length > 0
        },
        {
            name: 'Radar',
            icon: AiOutlineRadarChart,
            show: selectedDimensions.length > 2
        },
        {
            name: 'Table',
            icon: AiOutlineTable,
            show: selectedDimensions.length > 0
        }
    ];

    return (
        <div className="pr-10">
            <nav className="flex flex-row-reverse" aria-label="Tabs">
                {tabs
                    .filter((tab) => tab.show)
                    .map((tab) => (
                        <div
                            key={tab.name}
                            className={classNames(
                                tab.name === current
                                    ? 'bg-gray-100 text-gray-700'
                                    : 'text-gray-500 hover:text-gray-700',
                                'group inline-flex items-center rounded-md px-4 py-2 text-sm font-medium cursor-pointer ml-4'
                            )}
                            onClick={() => {
                                onClick(tab.name);
                            }}
                            aria-current={
                                tab.name === current ? 'page' : undefined
                            }
                        >
                            <tab.icon className="h-5 w-5" />
                        </div>
                    ))}
            </nav>
        </div>
    );
}
