import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';

export default function AdminBreadcrumb() {
    const location = useLocation();

    const pages = location.pathname
        .split('/')
        .filter((path, index) => path !== 'admin' && path !== '')
        .map((path, index, array) => {
            const name = path.charAt(0).toUpperCase() + path.slice(1);
            const href = `/admin/${array.slice(0, index + 1).join('/')}`;
            const current = index === array.length - 1;
            return { name, href, current };
        });

    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                <li>
                    <div>
                        <a href="/admin" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </a>
                    </div>
                </li>
                {pages.map((page) => (
                    <li key={page.name}>
                        <div className="flex items-center">
                            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <a
                                href={page.href}
                                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                aria-current={page.current ? 'page' : undefined}
                            >
                                {page.name}
                            </a>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
}
