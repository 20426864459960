function Icon({ slug, className, size, ...props }) {
    return (
        <div
            className={`rounded company-icon company-icon-${size} company-icon-${slug} ${className}`}
            {...props}
        />
    );
}

export function Icon24({ ...props }) {
    return Icon({ ...props, size: 24 });
}

export function Icon32({ ...props }) {
    return Icon({ ...props, size: 32 });
}

export function Icon48({ ...props }) {
    return Icon({ ...props, size: 48 });
}

export function Icon64({ ...props }) {
    return Icon({ ...props, size: 64 });
}
