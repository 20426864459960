import { ResponsiveLine } from '@nivo/line';
import { getChartColors, formatNumbers } from '../../styles';

const formatQuarter = (item) => {
    const year = item.year.toString().slice(-2);
    return `${item.quarter}º tri ${year}`;
};

const BacenUserCount = ({ data }) => {
    // Recebe os dados no formato: [{"numberOfCustomersCcs": 1830264,"numberOfCustomersScr": 1614567,"year": 2023,"quarter": 3},{"numberOfCustomersCcs": 1834244,"numberOfCustomersScr": 1618743,"year": 2023,"quarter": 4}]
    // Transforma em: [{ id: 'SRC', data: [ { x: '3tri2023', y: 1614567 }, { x: '4tri2023', y: 1618743 },... ] }, { id: 'CCS', data: [ { x: '3tri2023', y: 1830264 }, { x: '4tri2023', y: 1834244 }] }]
    const data2 = [
        {
            id: 'SRC',
            data: data.map((item) => ({
                x: formatQuarter(item),
                y: item.numberOfCustomersScr
            }))
        },
        {
            id: 'CCS',
            data: data.map((item) => ({
                x: formatQuarter(item),
                y: item.numberOfCustomersCcs
            }))
        }
    ];

    return (
        <ResponsiveLine
            colors={getChartColors(2)}
            data={data2}
            margin={{ top: 30, right: 30, bottom: 100, left: 70 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false
            }}
            yFormat={formatNumbers}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Mês',
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Usuários',
                legendOffset: -50,
                legendPosition: 'middle',
                format: formatNumbers
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 70,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );
};

export default BacenUserCount;
