import { UI } from '../../components';
import { JsonEditor } from 'json-edit-react';

export default function Form({ fields, handleSubmit, signal }) {
    const handleChange = (event) => {
        const { target } = event;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        signal.value = { ...signal.value, [target.name]: value };
    };

    const handleChangeJson = (data, name) => {
        const { newData } = data;
        signal.value = { ...signal.value, [name]: newData };
    };

    const Field = ({
        label,
        name,
        type = 'text',
        value,
        checked,
        options = []
    }) => (
        <div key={name}>
            <label
                htmlFor={name}
                className="block text-sm font-medium leading-6 text-gray-100"
            >
                {label}
            </label>
            {type === 'checkbox' ? (
                <div className="flex items-center gap-x-2 text-sm font-medium leading-6 text-gray-100">
                    <input
                        type={type}
                        name={name}
                        id={name}
                        checked={checked}
                        onChange={handleChange}
                        className="rounded border-gray-300 text-idwallDarkPurple-600 focus:ring-idwallDarkPurple-600"
                    />
                    {label}
                </div>
            ) : type === 'textarea' ? (
                <textarea
                    name={name}
                    id={name}
                    value={value}
                    rows={5}
                    onChange={handleChange}
                    className="block w-full rounded-md bg-gray-700 border border-gray-600 text-gray-100 shadow-sm focus:ring-idwallDarkPurple-600"
                />
            ) : type === 'select' ? (
                <select
                    name={name}
                    id={name}
                    value={value}
                    onChange={handleChange}
                    className="block w-full rounded-md bg-gray-700 border border-gray-600 text-gray-100 shadow-sm focus:ring-idwallDarkPurple-600"
                >
                    <option value="">Selecione...</option>
                    {options.map((option) => (
                        <option key={option.key} value={option.key}>
                            {option.value}
                        </option>
                    ))}
                </select>
            ) : type === 'json' ? (
                <JsonEditor
                    rootName=""
                    theme="githubDark"
                    name={name}
                    id={name}
                    data={value}
                    onUpdate={(data) => handleChangeJson(data, name)}
                    className="block w-full rounded-md bg-gray-700 border border-gray-600 text-gray-100 shadow-sm focus:ring-idwallDarkPurple-600"
                />
            ) : (
                <input
                    type={type}
                    name={name}
                    id={name}
                    value={value}
                    onChange={handleChange}
                    className="block w-full rounded-md bg-gray-700 border border-gray-600 text-gray-100 shadow-sm focus:ring-idwallDarkPurple-600"
                />
            )}
        </div>
    );

    return (
        <form onSubmit={handleSubmit}>
            <div className="space-y-6">
                {fields.map((field) => (
                    <Field key={field.name} {...field} />
                ))}
                <div className="flex justify-end">
                    <UI.Button type="submit">Salvar</UI.Button>
                </div>
            </div>
        </form>
    );
}
