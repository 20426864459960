import { IoStar, IoStarOutline } from 'react-icons/io5';
import { Rating } from '../../../components';

function ReviewsCount({ reviews, rating }) {
    if (!reviews) return null;

    const ratingReviews = reviews.filter((review) => review.rating === rating);

    return (
        <div key={rating} className="flex items-center text-sm">
            <dt className="flex flex-1 items-center">
                <p className="w-3 font-medium text-gray-900">{rating}</p>
                <div
                    aria-hidden="true"
                    className="ml-1 flex flex-1 items-center"
                >
                    {ratingReviews.length > 0 && (
                        <IoStar className="h-5 w-5 flex-shrink-0 text-yellow-400" />
                    )}
                    {ratingReviews.length === 0 && (
                        <IoStarOutline className="h-5 w-5 flex-shrink-0 text-gray-300" />
                    )}

                    <div className="relative ml-3 flex-1">
                        <div className="h-3 rounded-full border border-gray-200 bg-gray-100" />
                        {ratingReviews.length > 0 ? (
                            <div
                                className="absolute inset-y-0 rounded-full border border-yellow-400 bg-yellow-400"
                                style={{
                                    width: `calc(${ratingReviews.length} / ${reviews.length} * 100%)`
                                }}
                            />
                        ) : null}
                    </div>
                </div>
            </dt>
            <dd className="ml-3 w-10 text-right text-sm tabular-nums text-gray-900 text-nowrap">
                {Math.round((ratingReviews.length / reviews.length) * 100)}%*
            </dd>
        </div>
    );
}

const EmptyState = () => {
    return (
        <div className="h-96">
            <div className="flex items-center justify-center h-full text-gray-500">
                <p className="text-sm">Nenhuma avaliação.</p>
            </div>
        </div>
    );
};

export default function ReviewsSummary({ reviews }) {
    if (!reviews) return null;

    if (Object.keys(reviews).length === 0) return null;

    if (!reviews.reviews) return null;

    if (reviews.reviews.length === 0) return <EmptyState />;

    return (
        <div className="lg:col-span-4">
            <div className="mt-3 flex items-center">
                <div>
                    <Rating value={reviews.rating} />
                </div>
                <p className="ml-2 text-sm text-gray-900">
                    {parseInt(reviews.reviewsCount).toLocaleString('pt-BR')}{' '}
                    avaliações
                </p>
            </div>

            <div className="mt-6">
                <dl className="space-y-3">
                    {[1, 2, 3, 4, 5].map((rating) => (
                        <ReviewsCount
                            key={rating}
                            reviews={reviews.reviews}
                            rating={rating}
                        />
                    ))}
                </dl>
                <p className="mt-2 text-xs text-right text-gray-900">
                    * amostra de {reviews.total} avaliações
                </p>
            </div>
        </div>
    );
}
