import { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Form from './Form';
import UserFormData from './UserFormData';

const user = signal({});
const companies = signal([]);

export default function AdminCompanyDetails() {
    const { userId } = useParams();

    const fetchData = useCallback(async () => {
        try {
            user.value = await AdminService.getUser(userId);
            companies.value = await AdminService.getCompanies();
            console.log('User details:', user.value);
            pageTitle.value = user.value.email;
        } catch (error) {
            console.error('Failed to load user details:', error);
        }
    }, [userId]);

    useEffect(() => {
        fetchData();
    }, []);

    const updateUser = async (userId, data) => {
        try {
            user.value = await AdminService.updateUser(userId, data);
            console.log('Updated user:', user.value);
            alert('Usuário atualizado com sucesso!');
        } catch (error) {
            console.error(error);
            alert('Erro ao atualizar usuário!');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (user.value.companyId === '') {
            user.value.companyId = null;
        }
        updateUser(userId, user.value);
        console.log('Formulário enviado', user.value);
    };

    const form = UserFormData({ user: user.value, companies: companies.value });

    return <Form fields={form} handleSubmit={handleSubmit} signal={user} />;
}
