import { Layout } from '..';

export default function TermsOfUse() {
    return (
        <>
            <Layout.Head title="Index: Termos de Uso" />

            <div className="bg-white px-6 py-32 lg:px-8">
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                    <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                        Termos de Uso (“Termos”)
                    </h1>{' '}
                    <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                        Index
                    </h1>
                    <p className="mt-6">
                        Última atualização: 06 de março de 2024
                    </p>
                    <p className="mt-4 indent-14">Sejam bem-vindos ao Index!</p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Os presentes Termos são necessários para proteger Você,
                        bem como para tornar nossos serviços possíveis e mais
                        eficientes para todos.
                    </p>
                    <p className="mt-4 indent-14">
                        Em caso de qualquer discordância com este documento,
                        solicitamos que Você se abstenha da utilização dos
                        Serviços.
                    </p>
                    <p className="mt-4 indent-14">
                        Estes Termos devem ser lidos em conjunto com a{' '}
                        <a
                            href="https://idwall.co/politica-de-privacidade/"
                            className="underline"
                        >
                            Política de Privacidade da idwall
                        </a>
                        , e o uso dos Serviços importa a sua imediata aceitação
                        dos Termos e demais documentos relacionados (em conjunto
                        “<span className="underline">Termos Gerais</span>”).
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        1. O Index
                    </h2>
                    <p className="mt-4 indent-14">
                        O Index é uma plataforma online que atua como uma fonte
                        de dados analíticos de diversas instituições do mercado
                        financeiro, a qual garante acesso aos dados do Ranking
                        Idwall, dados de avaliação das instituições pelo órgão
                        regulador financeiro e pelo mercado em geral, bem como
                        referências de conteúdos relevantes (“
                        <span className="underline">Serviços</span>”).
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        O Ranking Idwall é um estudo periódico promovido pela
                        idwall que avalia os fluxos operacionais de diversas
                        instituições financeiras, as quais, após serem
                        verificadas sob diversas perspectivas, são colocadas em
                        ordem numérica de pontuação no Ranking.
                    </p>
                    <p className="mt-4 indent-14">
                        Com o Index Você terá a capacidade de avaliar o impacto
                        dos seus processos e fluxos internos, incluindo mas não
                        limitando-se, a título de exemplo, ao fluxo de de
                        <i>onboarding</i>. Tais fluxos poderão ser analisados,
                        conforme disponibilidade de informações no Index, as
                        quais podem ser alteradas, a qualquer momento.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Com o Index Você terá a capacidade de fazer um
                        comparativo com diversas outras instituições. Além
                        disso, o Index disponibiliza insights sobre o
                        desempenho, tempo de resposta operacional, experiência
                        do usuário e satisfação dos clientes e, também,
                        possibilita a promoção de seu negócio com a constante
                        melhoria de seus processos, a partir de dados empíricos
                        e públicos.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        2. Contrato com Você
                    </h2>
                    <p className="mt-4 indent-14">
                        {' '}
                        O Index é uma solução oferecida pela Idwall Tecnologia
                        Ltda. (“<span className="underline">idwall</span>”),
                        inscrita no CNPJ/ME sob o n° 24.934.106/0001-20, com
                        sede à Avenida Paulista, nº 2537, Bela Vista, São
                        Paulo/SP.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Ao acessar nosso site (<i>index.idwall.co</i>) ou
                        utilizar nossos Serviços, você concorda em estar
                        vinculado aos termos e condições deste documento. Estes
                        Termos constituem um contrato vinculante entre Você e
                        Idwall.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Em caso de acessar nosso site ou utilizar nossos
                        serviços em nome de uma empresa ou outra entidade, Você
                        declara ter a autoridade para obrigá-las a cumprir estes
                        Termos a que ela está totalmente submetida. Nesse caso,
                        o termo “<b>Você</b>” estará se referindo à empresa,
                        entidade, ou suas afiliadas que acesse os Serviços.
                    </p>
                    <p className="mt-4 indent-14">
                        Para fins de esclarecimento, os Serviços do Index não
                        incluem serviços, aplicações, recursos ou componentes
                        disponibilizados na Plataforma Idwall, que é regida
                        pelos seus próprios Termos Gerais.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        3. Do Acesso ao Serviço
                    </h2>
                    <p className="mt-4 indent-14">
                        {' '}
                        Para ter acesso e utilizar os Serviços do Index, você
                        deve primeiramente registrar-se e criar uma conta no
                        Index utilizando seu e-mail corporativo.(“
                        <span className="underline">Conta de Usuário</span>”).
                        Ademais, se disponível no Index e de sua preferência, a
                        sua Conta de Usuário também poderá ser integrada com o
                        Linkedin ou autenticação do MeuID. O cadastro resultará
                        na criação de um <i>login</i> e senha de acesso que o
                        identificará na plataforma <b>Index</b>.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        O Index é destinado a pessoas jurídicas e/ou pessoas
                        físicas maiores de 18 (dezoito) anos plenamente capazes,
                        caso Você não esteja de acordo com este requisito, não
                        deverá prosseguir com o cadastro.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        O login e senha do Index são pessoais e intransferíveis,
                        não podendo ser objeto de qualquer tipo de
                        comercialização, licença ou cessão. Você assume, desde
                        já, a integral e exclusiva responsabilidade pela Conta
                        de Usuário, em qualquer esfera e/ou natureza, sem
                        qualquer solidariedade com a idwall.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Este cadastro dará acesso a todo o conteúdo gratuito da
                        plataforma Index. Se e quando disponível no Index, caso
                        queira ter acesso a conteúdos mais completos e
                        personalizados, Você poderá adquirir, dentro da
                        plataforma, o plano mais adequado para Você (“
                        <span className="underline">Planos Pagos</span>”). Os
                        Plano Pagos do Index, quando disponíveis, terão suas
                        condições disponibilizadas pela idwall para sua
                        validação e aceite.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Uma pessoa que recebe permissão para acessar o conteúdo
                        do Index através de uma Conta de Usuário da qual essa
                        pessoa não é proprietária será denominada de usuário
                        autorizado (“
                        <span className="underline">Usuário Autorizado</span>”).
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Para fins de esclarecimento, qualquer vazamento de
                        dados, causados por uso indevido de login e senha, serão
                        igualmente de sua responsabilidade e não estarão
                        sujeitos à confidencialidade na medida necessária à
                        defesa da <b>idwall</b>. A obrigação quanto à concessão
                        de acessos e exclusão da permissão de acessos de
                        Usuários Autorizados é de sua exclusiva
                        responsabilidade.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Ao realizar o cadastro, Você declara e se compromete,
                        para todos os fins de direito e sob as penas da lei, a
                        informar dados corretos e verdadeiros, sendo de sua
                        exclusiva responsabilidade as consequências civis e
                        penais advindas da prestação de informação incorreta ou
                        falsa, bem como do uso indevido das informações
                        disponibilizadas pelo Index.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        4. Uso e Condições de Acesso
                    </h2>
                    <p className="mt-4 indent-14">
                        Você é responsável pelo uso do serviço de acordo com
                        estes Termos.
                    </p>
                    <p className="mt-4 indent-14">
                        Ao acessar o Index e utilizar os Serviços, Você e seus
                        Usuários Autorizados se obrigam a:
                    </p>
                    <ol
                        style={{ 'list-style-type': 'lower-alpha' }}
                        className="list-inside"
                    >
                        <li className="indent-14 mt-4">
                            Não praticar atos que possam danificar, inutilizar,
                            sobrecarregar, deteriorar ou de qualquer forma
                            modificar a plataforma, dispositivos tecnológicos da{' '}
                            <b>idwall</b> ou de quaisquer terceiros, bem como
                            documentos, arquivos, demais conteúdos são
                            disponibilizados. Além de não praticar atos que
                            possam impedir a normal utilização dos Serviços,
                            incluindo o seu conteúdo, por parte de quaisquer
                            terceiros
                        </li>
                        <li className="indent-14 mt-4">
                            Não utilizar qualquer sistema automatizado,
                            inclusive, mas sem se limitar a “robôs“, “
                            <i>spiders</i>“, “<i>scripts</i>” ou “
                            <i>offline readers</i>,“ que acessem a plataforma
                            Index ou os Serviços e venham a utilizá-los de forma
                            contrária ao previsto nos Termos Gerais.
                        </li>
                        <li className="indent-14 mt-4">
                            Não fazer qualquer uso indevido dos dados e demais
                            informações obtidas através do Index. Você estará
                            autorizado a compartilhar e divulgar os materiais do
                            Index no estado em que estão.
                        </li>
                        <li className="indent-14 mt-4">
                            Não praticar ou fomentar a prática de quaisquer atos
                            ou atividades ilegais, incluindo, mas sem se limitar
                            a exibir, enviar ou de qualquer forma divulgar
                            mensagens, arquivos, fotografias ou quaisquer dados
                            ou materiais: (<b>i</b>) com conteúdo ilegal,
                            violento, difamatório, calunioso, sigiloso, abusivo,
                            perigoso, degradante, pornográfico, discriminatório,
                            racista ou de qualquer modo ilegais ou atentatórios
                            à ordem pública; (<b>ii</b>) com links cujo
                            recebimento possa não ser desejado pelo
                            destinatário, tais como correntes, listas de
                            distribuição, “<i>spamming</i>” e similares; ou (
                            <b>iii</b>) que violem direitos de propriedade
                            intelectual ou qualquer outro direito, seja da{' '}
                            <b>idwall</b> ou de terceiros.
                        </li>
                        <li className="indent-14 mt-4">
                            Indenizar a <b>idwall</b> ou qualquer terceiro em
                            razão de eventuais danos advindos do uso dos
                            Serviços ou do Index, sendo Você, contratante dos
                            Serviços, o único responsável pela utilização que
                            faz dos Serviços oferecidos pela <b>idwall</b>.
                        </li>
                        <li className="indent-14 mt-4">
                            Não transmitir, publicar, divulgar ou criar obras
                            derivadas de qualquer material a que venha a ter
                            acesso em razão da prestação dos Serviços, sem
                            autorização expressa, quando tais materiais forem
                            protegidos por direitos de propriedade intelectual
                            e/ou quando a divulgação de tais materiais não seja
                            autorizada pelo seu autor ou pelo detentor dos seus
                            direitos intelectuais.
                        </li>
                        <li className="indent-14 mt-4">
                            Não fazer uso das marcas, nomes comerciais e sinais
                            distintivos de qualquer espécie, incluindo sua a
                            marca e designação da <b>idwall</b>, sem a obtenção
                            de prévia autorização dos seus titulares da
                            propriedade intelectual por escrito.
                        </li>
                        <li className="indent-14 mt-4">
                            Não se passar por qualquer pessoa ou entidade ou
                            prestar informações falsas sobre os Serviços da
                            Idwall e/ou da plataforma Index, seja direta ou
                            indiretamente, ou de outra forma executar qualquer
                            manipulação para disfarçar a identidade ou a
                            procedência de qualquer mensagem ou protocolo que o
                            usuário enviar pelo Index;
                        </li>
                        <li className="indent-14 mt-4">
                            Não remover, copiar, emular ou alterar quaisquer
                            avisos de direitos autorais, propriedades
                            industriais, software, tecnologias, marcas
                            d&apos;água, restrições de propriedade da idwall; ou
                        </li>
                        <li className="indent-14 mt-4">
                            Não violar, tentar violar ou não cumprir quaisquer
                            Termos do Index ou leis ou exigências aplicáveis ao
                            seu uso dos Serviços do Index.
                        </li>
                    </ol>
                    <p className="mt-4 indent-14">
                        A <b>idwall</b> reserva-se ao direito de:
                    </p>
                    <ol
                        style={{ 'list-style-type': 'lower-alpha' }}
                        className="list-inside"
                    >
                        <li className="indent-14 mt-4">
                            Eliminar qualquer conteúdo: (<b>i</b>) por
                            determinação legal; (<b>ii</b>) por ordem judicial
                            ou autoridade competente; (<b>iii</b>) para evitar
                            ou corrigir quaisquer elementos que, a seu exclusivo
                            critério, possam trazer ou tenham trazido prejuízos
                            ou qualquer tipo de dano à <b>idwall</b> ou a
                            qualquer terceiro; (<b>iv</b>) para identificar,
                            corrigir ou evitar quaisquer problemas técnicos na
                            operação dos Serviços; e (<b>v</b>) quando tais
                            conteúdos estiverem em desacordo com o previsto
                            neste Termo ou na legislação aplicável.
                        </li>
                        <li className="indent-14 mt-4">
                            Suspender, bloquear ou proibir acessos, a seu
                            exclusivo critério, eliminando consequentemente
                            qualquer conteúdo relativo aos perfis e contas.
                        </li>
                    </ol>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        5. Privacidade
                    </h2>
                    <p className="mt-4 indent-14">
                        {' '}
                        A <b>Política de Privacidade da idwall</b> descreve como
                        manuseamos os dados pessoais tratados em decorrência do
                        uso dos Serviços ou da navegação em nosso site. Você
                        entende que, ao utilizar os Serviços, está concordando
                        com o tratamento dos dados pessoais e as condições
                        estabelecidas na referida Política pela Idwall.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        No âmbito da utilização dos Serviços, Você garante que
                        os dados pessoais compartilhados com a <b>idwall</b> são
                        lícitos e estão em conformidade com a legislação
                        aplicável, em especial, a Lei Geral de Proteção de
                        Dados. A <b>idwall</b> envidará seus melhores esforços
                        para, dentro dos padrões recomendados de segurança,
                        garantir que os dados pessoais sejam protegidos e
                        mantidos confidenciais. Todavia, a <b>idwall</b> não
                        garante que a proteção dos dados e sua segurança jamais
                        venham a ser violados.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        6. Exclusão de Garantias
                    </h2>
                    <p className="mt-4 indent-14">
                        {' '}
                        A <b>idwall</b> empregará todos seus esforços para que
                        os Serviços do Index sejam plenamente acessíveis a todo
                        e qualquer tempo. Todavia, não há garantia de que o
                        acesso e a sua utilização se dêem sem qualquer falha ou
                        de forma ininterrupta. A <b>idwall</b> não poderá ser
                        responsabilizada em função do impedimento ou alteração
                        na operação, layout e/ou e na utilização dos Serviços.
                        Os Serviços são prestados na forma em que se encontram.
                        No mais, a <b>idwall</b> garante a integridade das
                        informações disponibilizadas no Index, conforme fontes
                        consultadas para tal análise, não sendo responsável por
                        qualquer veracidade ou validade, nesse sentido.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Você atuará, por sua por sua conta e risco, ao
                        armazenar, reutilizar, transformar, ou usar as
                        informações que lhe forem fornecidas no âmbito dos
                        Serviços para qualquer propósito, inclusive em relação
                        aos Usuários Autorizados, sendo vedado revendê-las para
                        terceiros.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Você aceita que a <b>idwall</b> não poderá ser
                        responsabilizada pela prática não autorizada de atos de
                        terceiros que promovam interceptação, eliminação,
                        alteração, modificação, divulgação ou manipulação dos
                        conteúdos do Index, inclusive quando tais práticas forem
                        decorrentes dos Usuários Autorizados por Você, sendo de
                        sua exclusiva responsabilidade o gerenciamento dos
                        acessos autorizados aos demais usuários no Index.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        A <b>idwall</b> e qualquer responsável pela operação não
                        são responsáveis pela prestação de quaisquer serviços ou
                        bens, vendidos, anunciados ou simplesmente direcionados
                        por meio do Index para sites de anunciantes,
                        patrocinadores ou parceiros.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Você assume integralmente a responsabilidade em caso de
                        qualquer demanda judicial ou arbitral por parte de
                        terceiros que, de qualquer modo, afete a <b>idwall</b>,
                        seus diretores, empregados e colaboradores, com relação
                        ou em decorrência do seu acesso ao Index e utilização
                        dos Serviços em violação aos Termos Gerais e a
                        legislação em vigor. Caberá, igualmente, Você indenizar
                        regressivamente a <b>idwall</b> caso ela venha a ser
                        condenada ao pagamento de indenização ou outro pagamento
                        de natureza distinta em virtude de atos praticados por
                        Você e seus Usuários Autorizados quando do seu acesso ao
                        Index e/ou utilização dos Serviços.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        7. Propriedade Intelectual
                    </h2>
                    <p className="mt-4 indent-14">
                        {' '}
                        Você declara estar ciente de que as marcas, nomes,
                        logotipos, bem como todo e qualquer conteúdo, desenho,
                        inteligência, <i>know how</i>, material, arte ou layout
                        presentes nos sites da <b>idwall</b> e no Index são de
                        propriedade exclusiva da <b>idwall</b>, salvo quando
                        expressamente designado de outra forma.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        São vedados quaisquer atos ou contribuições tendentes à
                        modificação das características, ampliação, alteração ou
                        incorporação de quaisquer programas, materiais ou
                        sistemas da <b>idwall</b>. Toda e qualquer forma de
                        reprodução do Index, total ou parcial, permanente,
                        temporária ou provisória, de forma gratuita ou onerosa,
                        sob quaisquer modalidades, formas ou títulos é
                        expressamente vedada.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        O conteúdo disponibilizado na plataforma Index e/ou
                        acessado em razão do uso dos Serviços caracteriza
                        somente autorização a Você para uso não comercial,
                        pessoal e intransferível, para visualizar os conteúdos
                        presentes no Index como cliente, não implicando qualquer
                        licença, cessão ou transferência de titularidade de
                        direitos da <b>idwall</b>. Em caso de violação, a{' '}
                        <b>idwall</b> se reserva o direito de determinar a
                        imediata remoção do conteúdo, sem prejuízo de outras
                        sanções cíveis e penais estabelecidas na legislação
                        pertinente.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        8. Planos
                    </h2>
                    <p className="mt-4 indent-14">
                        O uso de certos Serviços do Index poderão estar sujeitos
                        ao pagamento de determinados valores, conforme
                        determinado pela <b>idwall</b> a seu critério exclusivo
                        e validados por Você. Todo e qualquer conteúdo pago no
                        Index será informado a Você e somente será cobrado
                        mediante sua autorização expressa neste sentido.
                    </p>
                    <p className="mt-4 indent-14">
                        A <b>idwall</b> reserva o direito de alterar seus Planos
                        a qualquer momento e a seu critério exclusivo, caso
                        eventuais alterações afetem os valores pagos por Você
                        nos Planos Pagos, a <b>idwall</b> se compromete a
                        comunicar previamente, conforme periodicidade
                        determinada de pagamentos sobre os ajustes dos valores.
                    </p>
                    <p className="mt-4 indent-14">
                        Ao adquirir um Plano Pago no Index, Você autoriza a{' '}
                        <b>idwall</b> a solicitar e recolher pagamentos e
                        valores referente aos Serviços do provedor de pagamento
                        indicado por Você. Quando disponível tal funcionalidade,
                        você deverá manter um cartão de crédito salvo no Index
                        para pagar por seus Planos Pagos.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        9. Período de Utilização e Renovação automática
                    </h2>
                    <p className="mt-4 indent-14">
                        Os Serviços poderão ser usados por Você, conforme
                        periodicidade informada pela <b>Idwall</b> ou conforme
                        previsto na Plataforma Index (“Período de Utilização”).
                        Para garantir que Você não experiencie qualquer
                        interrupção ou perda dos Serviços que fazem parte dos
                        Planos Pagos, o Index oferece uma opção de renovação
                        automática por padrão, segundo a qual, a menos que Você
                        desative no menu configurações da conta, o Período de
                        Utilização será renovado automaticamente, salvo, se
                        notificado ao contrário por Você ou a Você pela{' '}
                        <b>idwall</b> com aviso prévio a ser determinado por
                        essa última (“Renovação Automática”). Os Planos Pagos
                        observarão o mesmo modelo de Período de Utilização e
                        Renovação Automática.
                    </p>{' '}
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        10. Cancelamento do Plano
                    </h2>
                    <p className="mt-4 indent-14">
                        Você poderá interromper a utilização dos Serviços e
                        solicitar o cancelamento de sua Conta de Usuário, quando
                        gratuitos, a qualquer momento. Caso queira realizar o
                        cancelamento apenas do Plano Pago e retornar ao acesso
                        gratuito, Você poderá executar o cancelamento da
                        assinatura por meio da plataforma Index no menu “Conta
                        do Usuário”. As assinaturas dos Planos Pagos serão
                        descontinuadas, conforme aviso prévio acordado entre
                        Você e <b>idwall</b>.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        11. Disposições Gerais
                    </h2>
                    <p className="mt-4 indent-14">
                        O presente Termo será regido pelas leis da República
                        Federativa do Brasil, independentemente de qualquer
                        disposição sobre conflito de leis, sendo eleito o Foro
                        Arbitral a ser administrado pela Câmara de Comércio
                        Brasil Canadá (“
                        <span className="underline">Câmara</span>”), para
                        dirimir quaisquer litígios que venham a surgir em
                        relação ao Index, conduzidos de acordo com o seu
                        regulamento vigente na data do pedido de instauração (”
                        <span className="underline">Regulamento</span>”). A
                        Arbitragem será conduzida no idioma português,
                        constituída por 03 (três) árbitros, escolhidos conforme
                        o Regulamento{' '}
                    </p>
                    <p className="mt-4 indent-14">
                        O não exercício pela <b>idwall</b> de quaisquer direitos
                        ou faculdades que lhes sejam conferidos pelos Termos
                        Gerais, bem como a eventual tolerância contra infrações
                        aos dispositivos dos instrumentos legais e contratuais
                        aplicáveis, não importará renúncia aos seus direitos ou
                        novação, podendo a <b>idwall</b> exercer seus direitos a
                        qualquer tempo.
                    </p>
                    <p className="mt-4 indent-14">
                        {' '}
                        Você concorda que nenhuma relação de{' '}
                        <i>joint venture</i>, parceria, controle conjunto ou
                        emprego existe entre Você e a <b>idwall</b> como
                        resultado dos Termos ou do seu uso dos Serviços. Os
                        Termos Gerais constituem o acordo completo entre Você e
                        a <b>idwall</b> com relação ao seu uso dos Serviços e
                        acesso à <b>Plataforma idwall</b>. O não exercício ou
                        imposição da <b>idwall</b> de qualquer direito ou
                        disposição dos Termos não constituirá uma renúncia,
                        novação ou disposição de tal direito.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        12. Atualização
                    </h2>
                    <p className="mt-4 indent-14">
                        A <b>idwall</b> poderá revisar estes Termos a qualquer
                        momento e por qualquer motivo, ao seu exclusivo
                        critério, sendo Você responsável por analisar as
                        alterações realizadas. A continuação do uso dos Serviços
                        implica a sua aceitação da versão mais atualizada dos
                        Termos, que estará sempre disponível em{' '}
                        <a
                            href="https://index.idwall.co/"
                            className="underline"
                        >
                            https://index.idwall.co/
                        </a>{' '}
                        e regerá a relação da <b>idwall</b> com Você.
                    </p>
                    <p className="mt-4 indent-14">
                        Este Termo é celebrado por prazo indeterminado, podendo
                        ser rescindido pela <b>idwall</b> a qualquer momento.
                    </p>
                    <h2 className="mt-10 text-xl font-semibold text-gray-900">
                        13. Contato
                    </h2>
                    <p className="mt-4 indent-14">
                        Se tiver dúvidas sobre estes termos ou sobre os
                        Serviços, entre em contato com a <b>idwall</b> pelos
                        seguintes e-mails &lt;
                        <a href="mailto:hello@idwall.co" className="underline">
                            hello@idwall.co
                        </a>
                        &gt; e &lt;
                        <a
                            href="mailto:suporte@idwall.co"
                            className="underline"
                        >
                            suporte@idwall.co
                        </a>
                        &gt;.
                    </p>
                    <p className="mt-28">
                        Idwall Tecnologia Ltda. <br />
                        Avenida Paulista nº 2537, Bela Vista, CEP 01311-300, São
                        Paulo - SP
                    </p>
                </div>
            </div>
        </>
    );
}
