export default function getFormData(
    score,
    companies,
    expCriterias,
    expJourneys
) {
    return [
        {
            label: 'Tipo',
            name: 'type',
            type: 'select',
            value: score.type || '',
            options: [
                {
                    key: 'score',
                    value: 'Score (número 0-100)'
                },
                {
                    key: 'rating',
                    value: 'Rating (1-5 estrelas)'
                }
            ]
        },
        {
            label: 'Value',
            name: 'value',
            type: 'number',
            value: score.value || 0
        },
        {
            label: 'Company',
            name: 'companyId',
            type: 'select',
            value: score.companyId || '',
            options: companies.map((company) => ({
                key: company.companyId,
                value: company.name
            }))
        },
        {
            label: 'Criteria',
            name: 'expCriteriaId',
            type: 'select',
            value: score.expCriteriaId || '',
            options: expCriterias.map((criteria) => ({
                key: criteria.expCriteriaId,
                value: criteria.name
            }))
        },
        {
            label: 'Journey',
            name: 'expJourneyId',
            type: 'select',
            value: score.expJourneyId || '',
            options: expJourneys.map((journey) => ({
                key: journey.expJourneyId,
                value: journey.name
            }))
        },
        {
            label: 'Dados',
            name: 'details',
            type: 'json',
            value: score.details || {}
        }
    ];
}
