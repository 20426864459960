// src/components/ErrorBoundary.jsx
import React from 'react'
import Error from './Error'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Atualizar o estado para que a próxima renderização mostre a UI de fallback
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // Você pode registrar o erro em um serviço de relatório de erros
  }

  render() {
    if (this.state.hasError) {
      // Você pode renderizar qualquer UI de fallback
      return <Error />
    }

    return this.props.children
  }
}

export default ErrorBoundary
