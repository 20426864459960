import { CheckBadgeIcon } from '@heroicons/react/24/solid'

export default function BadgeRanking({ size = 6, className }) {
  const iconSizeClass = `w-${size} h-${size}`

  return (
    <span title="Empresa consta no estudo de experiência de 2024">
      <CheckBadgeIcon className={`ml-2 ${iconSizeClass} ${className} text-idwallDarkPurple`} />
    </span>
  )
}
