import { CheckCircleIcon } from '@heroicons/react/20/solid';
import Payment from './Payment';
import { UI } from '../../components';

const features = [
    'Acesso a dados exclusivos de apps financeiros e fintechs',
    'Análises detalhadas do mercado financeiro digital',
    'Ferramentas de inteligência competitiva',
    'Relatórios personalizados de tendências de mercado',
    'Dados atualizados e confiáveis para tomada de decisão',
    'Suporte especializado para suas necessidades de negócio'
];

const Content = () => {
    return (
        <div className="relative isolate">
            <div className="mx-auto max-w-7xl">
                <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                    <div className="w-full flex-auto">
                        <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                            Amplie sua visão.
                            <br />
                            Melhore sua tomada de decisão.
                        </h2>
                        <p className="mt-6 text-lg leading-8 text-gray-700">
                            Transforme a maneira como você compreende o mercado financeiro digital com insights aprofundados e dados confiáveis.
                        </p>
                        <ul role="list" className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-gray-900">
                            {features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckCircleIcon className="h-7 w-5 flex-none" aria-hidden="true" />
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="absolute inset-x-0 -top-16 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl" aria-hidden="true">
                <div
                    className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-r from-[#00CEFF] to-[#5500FF] opacity-25"
                    style={{
                        clipPath:
                            'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)'
                    }}
                />
            </div>
        </div>
    );
};

export default function Example() {
    return (
        <div className="bg-gray-50">
            <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                    <div className="mt-36 space-y-12">
                        <UI.H2>Checkout</UI.H2>
                        <Payment />
                    </div>
                    <Content />
                </div>
            </div>
        </div>
    );
}
