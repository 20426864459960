import { currentCompany } from '../../signals';
import { getCompanyColor, primaryColor } from '../../styles';

function StatsBox({ stat }) {
    return (
        <div className="flex flex-col bg-gray-200 p-5">
            <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.name}</dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
        </div>
    );
}

function TitleBox({ title, bgColor = primaryColor }) {
    return (
        <div key="title" className="flex flex-col bg-idwallDarkPurple p-5" style={{ backgroundColor: bgColor }}>
            <dt className="order-first text-3xl font-semibold tracking-tight text-white">{title}</dt>
        </div>
    );
}

export default function ReportComparison({ data }) {
    const company = currentCompany.value;

    return (
        <div className="bg-white py-10 sm:py-10">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-none">
                    <div className="text-center">
                        <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">Comparativo dos indicadores</h2>
                        <p className="mt-4 text-lg leading-8 text-gray-600">{`${company.name} em relação ao mercado.`}</p>
                    </div>
                    <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-6">
                        <TitleBox title={company.name} bgColor={getCompanyColor(company.name, [null])} />
                        {data.stats.map((stat) => (
                            <StatsBox key={stat.id} stat={stat} />
                        ))}
                    </dl>
                    <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-6">
                        <TitleBox title="Bancos Digitais" />
                        {data.stats.map((stat) => (
                            <StatsBox key={stat.id} stat={stat} />
                        ))}
                    </dl>
                    <dl className="mt-8 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-6">
                        <TitleBox title="Todos os Bancos" />
                        {data.stats.map((stat) => (
                            <StatsBox key={stat.id} stat={stat} />
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
}
