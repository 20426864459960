import { useNavigate } from 'react-router-dom';

import { AuthService } from '../../services';

const Logout = () => {
    const navigate = useNavigate();

    const performLogout = async () => {
        await AuthService.logout(navigate);
        navigate('/');
    };

    performLogout();

    return (
        <div className="flex justify-center items-center h-screen">
            <p>Realizando logout...</p>
        </div>
    );
};

export default Logout;
