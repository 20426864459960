import { useNavigate } from 'react-router-dom';
import { FavoritesService } from '../../services';
import { favoritesIds, companiesList, isLoading, user } from '../../signals';

import {
    ChevronDownIcon,
    ChevronUpIcon,
    BookmarkIcon
} from '@heroicons/react/20/solid';
import { BookmarkIcon as OutlineBookmarkIcon } from '@heroicons/react/24/outline';

import { BadgeRanking, Rating, UI } from '../../components';

const trStyle = 'cursor-pointer even:bg-gray-50 hover:bg-gray-100 ';
const trStyleDisabled = 'even:bg-gray-50';
const tdStyle = 'whitespace-nowrap px-3 py-4 text-sm text-gray-900 ';

const TableRowSpan = ({ children }) => {
    return (
        <tr className={trStyleDisabled}>
            <td colSpan={5} className={tdStyle + 'py-10 text-center'}>
                {children}
            </td>
        </tr>
    );
};

const GetAppRating = ({ company, type }) => {
    const { apps } = company;

    if (apps.length === 0) {
        return <span className="text-gray-400">N/A</span>;
    }

    const app = apps.find((app) => app.type === type);

    if (!app) {
        return <span className="text-gray-400">N/A</span>;
    }

    return <Rating value={app.rating} />;
};

const TableRowDefault = ({ company }) => {
    const navigate = useNavigate();

    const { isLoggedIn } = user.value;

    const handleRowClick = (event) => {
        event.preventDefault();

        // eslint-disable-next-line no-undef
        gtag('event', 'gtag_company_details', { company: company.name });

        // Verifica se a tecla Ctrl (para Windows/Linux) ou Command (para Mac) está pressionada
        if (event.ctrlKey || event.metaKey) {
            window.open(`/${company.slug}`, '_blank');
        } else {
            navigate(`/${company.slug}`);
        }
    };

    return (
        <tr onClick={(event) => handleRowClick(event)} className={trStyle}>
            <td className={tdStyle + 'pl-6 pr-3 font-medium'}>
                <div className="flex">
                    <UI.Icon24
                        slug={company.slug}
                        className="inline-block mr-4"
                    />
                    <span>{company.name}</span>
                    {company.verified && (
                        <BadgeRanking size={5} className="inline-block ml-2" />
                    )}{' '}
                </div>
            </td>
            <td className={tdStyle}>{company.type}</td>
            <td className={tdStyle}>
                <GetAppRating company={company} type="android" />
            </td>
            <td className={tdStyle}>
                <GetAppRating company={company} type="ios" />
            </td>
            {isLoggedIn && (
                <td className={tdStyle}>
                    <button
                        onClick={(e) => {
                            e.preventDefault(); // Prevenir a ação padrão do link
                            e.stopPropagation(); // Impedir que o evento se propague para a linha da tabela
                            FavoritesService.toggleFavorite(company.companyId);
                        }}
                        className="text-idwallDarkPurple-600 hover:text-idwallDarkPurple-900"
                    >
                        {favoritesIds.value.includes(company.companyId) ? (
                            <BookmarkIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        ) : (
                            <OutlineBookmarkIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        )}
                    </button>
                </td>
            )}
        </tr>
    );
};

const OrderArrow = (field) => {
    const { sort, order } = companiesList.value;

    if (!order) return null;

    if (sort !== field) {
        return (
            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                <ChevronDownIcon
                    className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                    aria-hidden="true"
                />
            </span>
        );
    }

    return (
        <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
            {order === 'asc' && (
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            )}
            {order === 'desc' && (
                <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
            )}
        </span>
    );
};

const TableHeader = ({ children, field, onSortChange }) => {
    return (
        <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            onClick={() => {
                if (field) {
                    onSortChange(field);
                }
            }}
        >
            <a href="#" className="group inline-flex">
                {children}
                {field && OrderArrow(field)}
            </a>
        </th>
    );
};

export default function CompaniesTable({ onSortChange }) {
    const companyList = companiesList.value.companies || [];
    const { isLoggedIn } = user.value;

    // prettier-ignore
    return (
        <table className="w-full divide-y divide-gray-300">
            <thead>
                <tr>
                    <TableHeader onSortChange={onSortChange} field={'name'}>Nome</TableHeader>
                    <TableHeader onSortChange={onSortChange} field={'type'}>Categoria</TableHeader>
                    <TableHeader onSortChange={onSortChange}>Android</TableHeader>
                    <TableHeader onSortChange={onSortChange}>iOS</TableHeader>
                    {isLoggedIn && (
                        <TableHeader onSortChange={onSortChange}>Favoritos</TableHeader>
                    )}
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {isLoading.value ? (
                    <TableRowSpan>Carregando..</TableRowSpan>
                ) : companyList.length === 0 ? (
                    <TableRowSpan>
                        <div className='my-6 space-y-6'>
                            <div>
                                Nenhuma instituição com os filtros selecionados.
                            </div>
                            <div>
                                Não encontrou alguma instituição no Index?{' '}
                                <a href="mailto:index@idwall.co" className="ml-2 underline">
                                    Entre em contato conosco.
                                </a>
                            </div>
                        </div>
                    </TableRowSpan>
                ) : (
                    companyList.map((company) => (
                        <TableRowDefault
                            key={company.companyId}
                            company={company}
                        />
                    ))
                )}
            </tbody>
        </table>
    );
}
