import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Form from './Form';

const bcbInstitution = signal({});
const bcbConglomerates = signal([]);

export default function AdminBcbDetailsDetails() {
    const { bcbInstitutionId } = useParams();

    const fetchData = useCallback(async () => {
        try {
            bcbInstitution.value = await AdminService.getBcbInstitution(
                bcbInstitutionId
            );
            bcbConglomerates.value = await AdminService.getBcbConglomerates();
            console.log('details:', bcbInstitution.value);
            pageTitle.value = bcbInstitution.value.name;
        } catch (error) {
            console.error(error);
        }
    }, [bcbInstitutionId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const updateBcbInstitution = async (bcbInstitutionId, data) => {
        try {
            bcbInstitution.value = await AdminService.updateBcbInstitution(
                bcbInstitutionId,
                data
            );
            console.log('Updated:', bcbInstitution.value);
            alert('Instituição atualizada com sucesso!');
        } catch (error) {
            console.error(error);
            alert('Erro ao atualizar instituição!');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (bcbInstitution.value.bcbConglomerateId === '') {
            bcbInstitution.value.bcbConglomerateId = null;
        }
        updateBcbInstitution(bcbInstitutionId, bcbInstitution.value);
    };

    const formFields = [
        {
            label: 'Nome',
            name: 'name',
            value: bcbInstitution.value.name || ''
        },
        {
            label: 'Code',
            name: 'code',
            value: bcbInstitution.value.code || ''
        },
        {
            label: 'Conglomerate',
            name: 'bcbConglomerateId',
            type: 'select',
            value: bcbInstitution.value.bcbConglomerateId || '',
            options: bcbConglomerates.value
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((i) => ({
                    key: i.bcbConglomerateId,
                    value: i.name
                }))
        },
        {
            label: 'TCB',
            name: 'tcb',
            value: bcbInstitution.value.tcb || ''
        },
        {
            label: 'TC',
            name: 'tc',
            value: bcbInstitution.value.tc || ''
        },
        {
            label: 'TI',
            name: 'ti',
            value: bcbInstitution.value.ti || ''
        },
        {
            label: 'City',
            name: 'city',
            value: bcbInstitution.value.city || ''
        },
        {
            label: 'State',
            name: 'state',
            value: bcbInstitution.value.state || ''
        },
        {
            label: 'Year',
            name: 'year',
            value: bcbInstitution.value.year || ''
        },
        {
            label: 'Quarter',
            name: 'quarter',
            value: bcbInstitution.value.quarter || ''
        },
        {
            label: 'Total Assets',
            name: 'totalAssets',
            value: bcbInstitution.value.totalAssets || ''
        },
        {
            label: 'Number of Service Stations',
            name: 'numberOfServiceStations',
            value: bcbInstitution.value.numberOfServiceStations || ''
        },
        {
            label: 'Number of Branches',
            name: 'numberOfBranches',
            value: bcbInstitution.value.numberOfBranches || ''
        }
    ];

    return (
        <Form
            fields={formFields}
            handleSubmit={handleSubmit}
            signal={bcbInstitution}
        />
    );
}
