import { user } from '../../signals';
import { CRMService } from '../../services';
import logo from '../../svg/index-logo-color.svg';
import { useNavigate } from 'react-router-dom';
import { UI } from '..';
import { useEffect } from 'react';

export default function CTAConclusion() {
    const navigate = useNavigate();
    const { firstName, email } = user.value;

    useEffect(() => {
        CRMService.registerCRMLead({ email });
    }, []);

    return (
        <div className="relative isolate overflow-hidden bg-white">
            <svg
                className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
            >
                <defs>
                    <pattern
                        id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
                        width={200}
                        height={200}
                        x="50%"
                        y={-1}
                        patternUnits="userSpaceOnUse"
                    >
                        <path d="M.5 200V.5H200" fill="none" />
                    </pattern>
                </defs>
                <rect
                    width="100%"
                    height="100%"
                    strokeWidth={0}
                    fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
                />
            </svg>
            <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
                    <img className="h-18" src={logo} alt="Index by idwall" />
                    <h1 className="mt-24 text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl">
                        Entraremos em contato, {firstName}
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Parabéns! Você está no caminho certo para conseguir
                        insights valiosos e tomar decisões mais acertadas no
                        setor financeiro e impulsionar o sucesso da sua empresa.
                    </p>
                    <div className="mt-10 flex items-center gap-x-6">
                        <UI.Button onClick={() => navigate(-1)}>
                            Voltar
                        </UI.Button>
                    </div>
                </div>
                <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
                    <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                        <img
                            src="/img/cta-report.jpg"
                            alt=""
                            width={2432}
                            height={1442}
                            className="w-[76rem] rounded-2xl shadow-2xl ring-1 ring-gray-900/10"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
