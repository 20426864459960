import { signal, computed } from '@preact/signals-react';

export const user = signal({
    favorites: []
});

export const settings = signal({
    showCompaniesListFilter: true
});

export const favoritesIds = computed(() => {
    const favorites = user.value.favorites;
    return favorites.map((f) => f.companyId) || [];
});
