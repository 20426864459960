import { useEffect, useCallback } from 'react';
import { UI, Rating, Layout } from '..';
import { modalCTAComparison, currentCompany, user } from '../../signals';
import mock from './mock.json';

const closeModal = () => {
    modalCTAComparison.value = false;
};

let cta = {};

export default function CTAComparisonModal() {
    const checkCTA = useCallback(async () => {
        const userCompanyId = parseInt(user.value.companyId);
        const currentCompanyId = parseInt(currentCompany.value.companyId);

        mock.forEach((item) => {
            if (
                item.when.currentCompany === currentCompanyId &&
                item.when.userCompany === userCompanyId
            ) {
                cta = item;
                modalCTAComparison.value = true;
            }
        });
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            checkCTA();
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Layout.Modal
            showFooter={false}
            show={modalCTAComparison.value}
            onClose={closeModal}
        >
            <Content />
        </Layout.Modal>
    );
}

function TableRow({ row }) {
    return (
        <tr>
            <th
                scope="row"
                className="py-4 text-sm font-normal leading-6 text-gray-900"
            >
                {row.title}
                <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
            </th>

            {/* Current Company */}
            {row.type === 'rating' && (
                <td className="py-4">
                    <div className="text-center pl-16 text-sm leading-6 text-gray-500">
                        <Rating value={row.competitorCompany} />
                    </div>
                </td>
            )}
            {row.type === 'string' && (
                <td className="px-6 py-4 xl:px-8">
                    <div className="text-center text-lg leading-6 text-gray-900">
                        {row.competitorCompany}
                    </div>
                </td>
            )}
            {row.type === 'text' && (
                <td className="py-4">
                    <div className="text-center italic text-sm text-gray-500">
                        {row.competitorCompany}
                    </div>
                </td>
            )}

            {/* User Company */}
            {row.type === 'rating' && (
                <td className="py-4">
                    <div className="text-center pl-14 text-sm leading-6 text-gray-500">
                        <Rating value={row.competitorCompany} />
                    </div>
                </td>
            )}
            {row.type === 'string' && (
                <td className="px-6 py-4 xl:px-8">
                    <div className="text-center text-lg leading-6 text-gray-900">
                        {row.competitorCompany}
                    </div>
                </td>
            )}
            {row.type === 'text' && (
                <td className="py-4">
                    <div className="text-center italic text-sm text-gray-500">
                        {row.competitorCompany}
                    </div>
                </td>
            )}
        </tr>
    );
}

function Content() {
    return (
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
                <h2 className="text-base font-semibold leading-7 text-idwallDarkPurple-600">
                    {cta.subtitle}
                </h2>
                <p className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                    {cta.title}
                </p>
            </div>

            <div className="isolate mt-10">
                <div className="relative -mx-8">
                    <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                        <div
                            className="flex w-1/3 px-4"
                            aria-hidden="true"
                            style={{ marginLeft: '32%' }}
                        >
                            <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                        </div>
                    </div>
                    <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
                        <colgroup>
                            <col className="w-1/3" />
                            <col className="w-1/3" />
                            <col className="w-1/3" />
                        </colgroup>
                        <thead>
                            <tr>
                                <td />
                                <th scope="col" className="pt-8 text-center">
                                    <UI.Icon48
                                        slug={cta.competitorCompanySlug}
                                        className="w-12 h-12 mx-auto"
                                    />
                                    <div className="text-sm font-semibold leading-7 text-gray-900">
                                        {cta.competitorCompanyName}
                                    </div>
                                </th>
                                <th scope="col" className="pt-8 text-center">
                                    <UI.Icon48
                                        slug={cta.userCompanySlug}
                                        className="w-12 h-12 mx-auto"
                                    />
                                    <div className="text-sm font-semibold leading-7 text-gray-900">
                                        {cta.userCompanyName}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row"></th>
                                <td className="pt-2 text-center">
                                    <div className="text-4xl font-semibold text-gray-900">
                                        {cta.competitorCompanyPosition}º
                                    </div>
                                </td>
                                <td className="pt-2 text-center">
                                    <div className="text-4xl font-semibold text-gray-700">
                                        {cta.userCompanyPosition}º
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th
                                    scope="colgroup"
                                    colSpan={4}
                                    className="pt-8 pb-4 text-sm font-semibold leading-6 text-gray-900"
                                >
                                    {cta.dataTitle}
                                    <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                                </th>
                            </tr>
                            {cta.dataTable.map((row, index) => (
                                <TableRow key={index} row={row} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <p className="mx-auto my-10 max-w-2xl text-center text-lg leading-8 text-gray-600">
                Acesse <strong>mais de 100 critérios comparativos</strong> e
                insights dos maiores players do mercado
            </p>
            <div className="mb-4 px-10 flex">
                <UI.Button as="a" href="/contato" className="w-full" size="xl">
                    Fale conosco
                </UI.Button>
            </div>
        </div>
    );
}
