const { post } = require('../utils/api/api_caller_utils');

export const registerCRMLead = async (data) => {
    try {
        const response = await post('/crm/register', data);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching reviews', error);
    }
};
