import { IoMdThumbsUp, IoMdThumbsDown } from 'react-icons/io';

export default function ReportTestimonials({ data }) {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-xl text-center">
                    <p className="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">A voz dos usuários</p>
                </div>
                <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
                    <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-2">
                        {data.map((testimonial, index) => (
                            <div key={index} className="pt-8 sm:inline-block sm:w-full sm:px-4">
                                <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                                    <blockquote className="text-gray-900">
                                        <p>{`“${testimonial.body}”`}</p>
                                    </blockquote>
                                    <figcaption className="mt-6 flex items-center gap-x-4">
                                        {testimonial.positive ? (
                                            <IoMdThumbsUp className="h-8 w-8 text-green-500" />
                                        ) : (
                                            <IoMdThumbsDown className="h-8 w-8 text-red-500" />
                                        )}
                                        <div className="text-gray-600">
                                            <div>{testimonial.author.age}</div>
                                            <div>{testimonial.author.segment}</div>
                                        </div>
                                    </figcaption>
                                </figure>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
