import { primaryColor } from '../styles';

function CircleScore({
    score = 0,
    radius = 25,
    strokeWidth = 6,
    fontSize = 'text-lg',
    className
}) {
    // Configurações do círculo
    const circumference = 2 * Math.PI * radius;
    const dashOffset = ((100 - score) / 100) * circumference;

    return (
        <div className={`relative ${className}`}>
            <svg
                width={(radius + strokeWidth) * 2}
                height={(radius + strokeWidth) * 2}
                className="mx-auto"
            >
                {/* Círculo de fundo */}
                <circle
                    r={radius}
                    cx={radius + strokeWidth}
                    cy={radius + strokeWidth}
                    fill="none"
                    stroke="#d2d6dc" // cinza claro
                    strokeWidth={strokeWidth}
                />
                {/* Círculo do score */}
                <circle
                    r={radius}
                    cx={radius + strokeWidth}
                    cy={radius + strokeWidth}
                    fill="none"
                    stroke={primaryColor}
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    strokeDasharray={circumference}
                    strokeDashoffset={dashOffset}
                />
            </svg>
            {/* Número do score no centro */}
            <span
                className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semibold ${fontSize}`}
            >
                {score}
            </span>
        </div>
    );
}

export default CircleScore;
