import { useEffect, useCallback } from 'react';
import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { pageTitle } from '../../signals';
import Table from './Table';
import { UI } from '..';

const list = signal([]);

export default function AdminBcbInstitutionsList() {
    pageTitle.value = 'Instituições BACEN';

    const fetchData = useCallback(async () => {
        try {
            list.value = await AdminService.getBcbInstitutions();
            console.log('list:', list.value);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const headers = ['ID', 'Nome', 'Código', 'Conglomerado', ''];

    const rows = list.value.map((item, index) => [
        item.bcbInstitutionId,
        item.name,
        item.code,
        item.bcbConglomerateName ? item.bcbConglomerateName.name : '',
        <UI.Button
            as="a"
            key={index}
            href={`/admin/bcb/institutions/${item.bcbInstitutionId}`}
            variant="link"
            className="text-white hover:text-white"
        >
            Editar
        </UI.Button>
    ]);

    return <Table headers={headers} rows={rows} />;
}
