import { user, autocompleteList } from './signals';
import React, { useState } from 'react';

export default function DebugBox() {
    const [isContentVisible, setIsContentVisible] = useState(false);

    const debugBoxStyle = {
        color: 'white',
        position: 'fixed',
        bottom: '0px',
        right: '0px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        padding: '10px',
        zIndex: '9999',
        fontSize: '14px',
        width: '200px'
    };

    const handleUserClick = () => {
        console.log(user.value);
    };

    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };

    const { isSubscriber, hasAccessReport, isLoggedIn } = user.value;
    const companies = autocompleteList.value;

    const toggleIsSubscriber = (e) => {
        const checked = e.target.checked;
        user.value = {
            ...user.value,
            isSubscriber: checked
        };
    };

    const toggleHasAccessReport = (e) => {
        const checked = e.target.checked;
        user.value = {
            ...user.value,
            hasAccessReport: checked
        };
    };

    const changeCompany = (e) => {
        const companyId = e.target.value;
        user.value = {
            ...user.value,
            companyId
        };
    };

    return (
        <div style={debugBoxStyle}>
            <b onClick={toggleContentVisibility} style={{ cursor: 'pointer' }}>
                Debug
            </b>
            {isContentVisible && (
                <>
                    <br />
                    <br />
                    {isLoggedIn && (
                        <>
                            <label>
                                <input
                                    type="checkbox"
                                    name="isSubscriber"
                                    checked={isSubscriber}
                                    onChange={toggleIsSubscriber}
                                />{' '}
                                isSubscriber
                            </label>
                            <br />
                            <br />
                            <label>
                                <input
                                    type="checkbox"
                                    name="hasAccessReport"
                                    checked={hasAccessReport}
                                    onChange={toggleHasAccessReport}
                                />{' '}
                                hasAccessReport
                            </label>
                            <br />
                            <br />
                            <select
                                style={{
                                    fontSize: '12px',
                                    maxWidth: '180px',
                                    color: 'black'
                                }}
                                onChange={changeCompany}
                                value={user.value.companyId}
                            >
                                <option value="">User&apos;s company...</option>
                                {companies
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map((item) => (
                                        <option
                                            key={item.companyId}
                                            value={item.companyId}
                                        >
                                            {item.name}
                                        </option>
                                    ))}
                            </select>
                            <br />
                            <br />
                            <a
                                href="/logout"
                                style={{ textDecoration: 'underline' }}
                            >
                                Logout
                            </a>
                            <br />
                            <br />
                        </>
                    )}
                    {!isLoggedIn && (
                        <>
                            <a
                                href="/login"
                                style={{ textDecoration: 'underline' }}
                            >
                                Login
                            </a>
                            <br />
                            <br />
                        </>
                    )}
                    <a
                        href="#"
                        onClick={handleUserClick}
                        style={{ textDecoration: 'underline' }}
                    >
                        console.log(user.value)
                    </a>
                </>
            )}
        </div>
    );
}
