import { modalCTAReport, user } from './signals';
import { Layout, Slideshow, CTA } from './components';
import DebugBox from './DebugBox';

export default function LayoutTemplate({ children, page }) {
    return (
        <>
            {modalCTAReport.value && <Slideshow.CTAModal />}
            <CTA.PurchaseModal />
            <div>
                {/* TODO: TROCAR QUANDO ENTRAR EM PROD */}
                {/* <Layout.NavbarComingSoon page={page} /> */}
                <Layout.Navbar page={page} />
                <main>{children}</main>
                {/* TODO: TROCAR QUANDO ENTRAR EM PROD */}
                <Layout.Footer />
                {/* <Layout.FooterComingSoon /> */}
                {user.value.type === 'admin' && <DebugBox />}
            </div>
        </>
    );
}
