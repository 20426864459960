import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import { useCallback, useEffect } from 'react';
import Form from './Form';
import ReportFormData from './ReportFormData';

const report = signal({});
const companies = signal([]);

export default function AdminReportCreate() {
    const fetchData = useCallback(async () => {
        try {
            companies.value = await AdminService.getCompanies();
        } catch (error) {
            console.error('Failed to load companies:', error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const createReport = async (data) => {
        try {
            await AdminService.createReport(data);
            window.location.href = '/admin/reports';
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        createReport(report.value);
    };

    const form = ReportFormData(report.value);

    return <Form fields={form} handleSubmit={handleSubmit} signal={report} />;
}
