import { Link } from 'react-router-dom';

const getButtonStyle = (variant) => {
    switch (variant) {
        case 'white':
            return 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50';
        case 'primary':
            return 'bg-idwallDarkPurple-500 text-white hover:bg-idwallDarkPurple-600 disabled:bg-idwallDarkPurple-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-idwallDarkPurple-500 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50';
        case 'outline':
            return 'border border-gray-300 text-gray-900 hover:bg-gray-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-idwallDarkPurple-500 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-50';
        case 'menu':
            return 'text-gray-300 hover:bg-gray-700 hover:text-white';
        case 'menu-active':
            return 'bg-gray-900 text-white';
        case 'link':
            return 'text-idwallDarkPurple-600 hover:text-idwallDarkPurple-500 hover:underline';
        default:
            return '';
    }
};

const getDefaultStyle = (classNames) => {
    const defaultStyle =
        'inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 font-semibold shadow-sm ';

    if (!classNames.includes('justify-')) {
        return defaultStyle + 'justify-center ' + classNames;
    } else {
        return defaultStyle + classNames;
    }
};

export default function Button({
    as = 'button',
    variant = 'primary',
    type = 'button',
    to = '#',
    href = '#',
    size = 'sm',
    onClick,
    className = '',
    children,
    ...props
}) {
    const Element = as === 'Link' ? Link : as;
    const buttonStyle = `text-${size} ${getDefaultStyle(
        className
    )} ${getButtonStyle(variant)}`;

    const extraProps =
        Element === Link
            ? { to, ...props }
            : Element === 'a'
            ? { href, ...props }
            : { type, ...props };

    return (
        <Element onClick={onClick} className={buttonStyle} {...extraProps}>
            {children}
        </Element>
    );
}
