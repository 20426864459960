import { Layout } from './components';
import DebugBox from './DebugBox';
import { user } from './signals';

export default function LayoutTemplate({ children, page }) {
    return (
        <>
            <div>
                <main>{children}</main>
                <Layout.Footer />
                {user.value.type === 'admin' && <DebugBox />}
            </div>
        </>
    );
}
