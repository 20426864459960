const { get } = require('../utils/api/api_caller_utils');

export const getExperience = async (companyId) => {
    try {
        const response = await get(`/companies/${companyId}/experience`);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error getting experience:', error);
    }
};
