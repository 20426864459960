const { get, put, post } = require('../utils/api/api_caller_utils');

// BcbConglomerates -----------------------------------------------------------
export const getBcbConglomerates = async () => {
    try {
        const response = await get('/admin/bcb/conglomerates');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching conglomerates', error);
    }
};

// BcbInstitutions -------------------------------------------------------------
export const getBcbInstitutions = async () => {
    try {
        const response = await get('/admin/bcb/institutions');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching institutions', error);
    }
};

export const getBcbInstitution = async (bcbInstitutionId) => {
    try {
        const response = await get(
            '/admin/bcb/institutions/' + bcbInstitutionId
        );
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching institution', error);
    }
};

export const updateBcbInstitution = async (bcbInstitutionId, data) => {
    try {
        const response = await put(
            '/admin/bcb/institutions/' + bcbInstitutionId,
            data
        );
        return JSON.parse(response);
    } catch (error) {
        console.error('Error updating institution', error);
    }
};

// ExpJourney -----------------------------------------------------------------
export const getExpJourneys = async () => {
    try {
        const response = await get('/admin/exp/journeys');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching journeys', error);
    }
};

// ExpCriteria -----------------------------------------------------------------
export const getExpCriterias = async () => {
    try {
        const response = await get('/admin/exp/criterias');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching criterias', error);
    }
};

// ExpScore ----------------------------------------------------------------------
export const getExpScores = async () => {
    try {
        const response = await get('/admin/exp/scores');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching scores', error);
    }
};

export const getExpScore = async (scoreId) => {
    try {
        const response = await get('/admin/exp/scores/' + scoreId);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching score', error);
    }
};

export const updateExpScore = async (scoreId, data) => {
    try {
        const response = await put('/admin/exp/scores/' + scoreId, data);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error updating score', error);
    }
};

export const createExpScore = async (data) => {
    try {
        const response = await post('/admin/exp/scores', data);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error creating score', error);
    }
};

// Report ---------------------------------------------------------------------

export const getReports = async () => {
    try {
        const response = await get('/admin/reports');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching reports', error);
    }
};

export const getReport = async (reportId) => {
    try {
        const response = await get('/admin/reports/' + reportId);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching report', error);
    }
};

export const updateReport = async (reportId, data) => {
    try {
        const response = await put('/admin/reports/' + reportId, data);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error updating report', error);
    }
};

export const createReport = async (data) => {
    try {
        const response = await post('/admin/reports', data);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error creating report', error);
    }
};

// Company --------------------------------------------------------------------
export const getCompanies = async () => {
    try {
        const response = await get('/admin/companies');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching companies', error);
    }
};

export const getCompany = async (companyId) => {
    try {
        const response = await get('/admin/companies/' + companyId);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching company', error);
    }
};

export const updateCompany = async (companyId, data) => {
    try {
        const response = await put('/admin/companies/' + companyId, data);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error updating company', error);
    }
};

// User -----------------------------------------------------------------------
export const getUsers = async () => {
    try {
        const response = await get('/admin/users');
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching users', error);
    }
};

export const getUser = async (userId) => {
    try {
        const response = await get('/admin/users/' + userId);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error fetching user', error);
    }
};

export const updateUser = async (userId, data) => {
    try {
        const response = await put('/admin/users/' + userId, data);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error updating user', error);
    }
};

export const createUser = async (data) => {
    try {
        const response = await post('/admin/users', data);
        return JSON.parse(response);
    } catch (error) {
        console.error('Error creating user', error);
    }
};
