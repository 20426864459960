import {
    ArrowTopRightOnSquareIcon,
    BuildingLibraryIcon,
    SparklesIcon,
    DevicePhoneMobileIcon,
    BookmarkIcon
} from '@heroicons/react/20/solid';
import { BookmarkIcon as OutlineBookmarkIcon } from '@heroicons/react/24/outline';

import { BadgeRanking, UI } from '../../components';
import {
    currentCompany,
    favoritesIds,
    user,
    modalCTAReport
} from '../../signals';
import { FavoritesService } from '../../services';

const CompanyTitle = ({ url, name, verified, slug }) => (
    <div className="min-w-0 flex-1 flex items-center mb-4">
        <UI.Icon48 slug={slug} className="inline-block w-12 h-12 mr-4" />
        <h2 className="text-2xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {name}
        </h2>
        {verified && <BadgeRanking />}{' '}
    </div>
);

const CompanyDetails = ({ url, type, bcbInstitution }) => (
    <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
        <div className="mt-2 flex items-center text-sm text-gray-500">
            <DevicePhoneMobileIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
            />
            {type}
        </div>
        {bcbInstitution && bcbInstitution.bcbConglomerate && (
            <div className="mt-2 flex items-center text-sm text-gray-500">
                <BuildingLibraryIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                />
                {bcbInstitution.bcbConglomerate.name || bcbInstitution.name}
            </div>
        )}
        <a
            href={url}
            target="_blank"
            className="mt-2 flex items-center text-sm text-gray-500"
            rel="noreferrer"
        >
            <ArrowTopRightOnSquareIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
            />
            {url}
        </a>
    </div>
);

const CompanyButtons = ({ companyId, companySlug, verified }) => {
    const { hasAccessReport } = user.value;

    const openReport = () => {
        if (hasAccessReport) {
            window.open(`/${companySlug}/report`, '_blank');
            return;
        }

        modalCTAReport.value = true;
    };

    return (
        <div className="mt-5 flex xl:ml-4 xl:mt-0 space-x-4">
            {verified && (
                <UI.Button onClick={openReport}>
                    {hasAccessReport && (
                        <ArrowTopRightOnSquareIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    )}
                    {!hasAccessReport && (
                        <SparklesIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                    Relatório Completo
                </UI.Button>
            )}
            {user.value.isLoggedIn && companyId && (
                <UI.Button
                    variant="white"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        FavoritesService.toggleFavorite(companyId);
                    }}
                >
                    {favoritesIds.value.includes(companyId) ? (
                        <>
                            <BookmarkIcon
                                className="h-5 w-5 text-idwallDarkPurple-500"
                                aria-hidden="true"
                            />
                            <span className="ml-1.5 text-sm font-semibold">
                                Remover dos Favoritos
                            </span>
                        </>
                    ) : (
                        <>
                            <OutlineBookmarkIcon
                                className="h-5 w-5 text-idwallDarkPurple-500"
                                aria-hidden="true"
                            />
                            <span className="ml-1.5 text-sm font-semibold">
                                Adicionar aos favoritos
                            </span>
                        </>
                    )}
                </UI.Button>
            )}
        </div>
    );
};

export default function CompanyHeader() {
    const { url, name, slug, verified, type, bcbInstitution, companyId } =
        currentCompany.value;

    return (
        <header className="bg-gray-50 py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
                <div className="flex-1">
                    <CompanyTitle
                        url={url}
                        name={name}
                        verified={verified}
                        slug={slug}
                    />
                    <CompanyDetails
                        url={url}
                        type={type}
                        bcbInstitution={bcbInstitution}
                    />
                </div>
                <CompanyButtons
                    companyId={companyId}
                    companySlug={slug}
                    verified={verified}
                />
            </div>
        </header>
    );
}
