import { Charts } from '..';

export default function ReportChart({ data }) {
    return (
        <div className="px-4 sm:px-6 lg:px-12">
            <div className="mt-8 grid grid-cols-3 gap-4">
                <div className="col-span-2" style={{ height: 'calc(100vh - 200px)' }}>
                    <Charts.ReportEffortPerception data={data.data} />
                </div>
                <div className="col-span-1">
                    <div className="sm:flex mt-10">
                        <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                            <span className="font-semibold text-6xl text-idwallDarkPurple-600">Q:</span>
                        </div>
                        <div>
                            <p className="mt-1 text-xl">Qual foi a sua percepção de esforço ao realizar esse processo de cadastro?</p>
                        </div>
                    </div>
                    <div className="relative my-10">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                    </div>
                    <div className="grid gap-6 grid-cols-1">
                        <p className="text-xl mt-10">
                            A alta taxa de percepção neutra pode indicar que os demais players no espaço estão alcançando as melhorias pioneiradas pelo Nubank.
                        </p>
                        <p className="text-xl">
                            Além disso, usam bem o conceito de smart friction para guiar o usuário da melhor forma possível, com explicações claras diretas, que
                            minimizam o esforço mental do cliente.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
