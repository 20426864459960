import React, { useState, useEffect, Fragment } from 'react';
import { useParams, Link } from 'react-router-dom';
import { TrophyIcon } from '@heroicons/react/20/solid';
import { Rating, Company, Charts, Layout } from '../../../components';
import { currentCompany } from '../../../signals';
import { preetyAppType } from '../../../helpers';
import { AppService } from '../../../services';
import ranking from '../../../data/ranking';

const CardMAU = ({ data }) => {
    return (
        <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Usuários ativos mensalmente por dispositivo
                </h3>
            </div>
            <div className="h-[450px]">
                <Charts.AppMAU data={data} />
            </div>
        </div>
    );
};

const CardDownloads = ({ data }) => {
    return (
        <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Downloads por dispositivo
                </h3>
            </div>
            <div className="h-[450px]">
                <Charts.AppDownloads data={data} />
            </div>
        </div>
    );
};

const CardMAUEmpty = () => {
    return (
        <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Usuários ativos mensalmente por dispositivo
                </h3>
            </div>
            <div className="h-[450px]">
                <div className="flex items-center justify-center h-full text-gray-500">
                    <p className="text-sm">Sem dados de usuários ativos.</p>
                </div>
            </div>
        </div>
    );
};

const CardDownloadsEmpty = () => {
    return (
        <div className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Downloads por dispositivo
                </h3>
            </div>
            <div className="h-[450px]">
                <div className="flex items-center justify-center h-full text-gray-500">
                    <div className="flex items-center justify-center h-full text-gray-500">
                        <p className="text-sm"> Sem dados de download.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CardReviews = ({ slug, apps }) => {
    return (
        <>
            <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
                <dl className="flex flex-wrap">
                    <div className="flex-auto p-6">
                        <dt className="text-sm font-semibold leading-6 text-gray-900">
                            App Reviews
                        </dt>
                    </div>
                    {apps.map((app) => (
                        <Link
                            key={app.appId}
                            className="flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 py-6 hover:bg-gray-100"
                            to={`/${slug}/avaliacoes/${app.appId}`}
                        >
                            <dt className="flex-none">
                                <img
                                    src={
                                        app.iconLowURL ||
                                        `/img/store-placeholder-${app.type}.png`
                                    }
                                    alt=""
                                    className="h-6 w-6 rounded-md"
                                />
                            </dt>
                            <dd className="text-sm font-medium leading-6 text-gray-900">
                                {preetyAppType(app.type)}
                            </dd>
                            <dd className="ml-auto">
                                <Rating value={app.rating} />
                            </dd>
                        </Link>
                    ))}
                </dl>
            </div>
        </>
    );
};

const CardRanking = ({ companyName, companyId }) => {
    const RankingItem = ({ title, position }) => {
        return (
            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt className="flex-none w-12 text-right">
                    {position && (
                        <span className="rounded-full bg-gray-400 px-3 py-1 font-medium text-white text-sm">
                            {position}º
                        </span>
                    )}
                    {!position && (
                        <span className="rounded-full bg-gray-400 px-3 py-1 font-medium text-white text-sm">
                            -
                        </span>
                    )}
                </dt>
                <dd className="text-sm font-medium leading-6 text-gray-900">
                    {title}
                </dd>
                <dd className="ml-auto">
                    {[1, 2, 3].includes(position) && (
                        <TrophyIcon
                            className="h-6 w-5 text-idwallDarkPurple-500"
                            aria-hidden="true"
                        />
                    )}
                </dd>
            </div>
        );
    };

    const RankingItemEmpty = () => {
        return (
            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt className="flex-none w-12 text-right">
                    <span className="rounded-full bg-gray-400 px-3 py-1 font-medium text-white text-sm">
                        &nbsp;
                    </span>
                </dt>
                <dd className="text-sm leading-6 text-gray-900">
                    Nenhuma premiação
                </dd>
            </div>
        );
    };

    const rankingDataItem =
        ranking.general.find(
            (item) => item.companyId === parseInt(companyId)
        ) || {};

    const rankings = rankingDataItem.awards.map((award) => {
        const match = award.match(/^(\d+)º lugar (.+)/);

        if (match) {
            return { position: parseInt(match[1], 10), title: award };
        } else {
            return { position: 1, title: award };
        }
    });

    return (
        <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
            <dl className="flex flex-wrap">
                <div className="flex-auto pl-6 pt-6">
                    <dt className="text-sm font-semibold leading-6 text-gray-900">{`${companyName} no Ranking 2024`}</dt>
                </div>
                {rankings.map((ranking, index) => (
                    <RankingItem
                        key={index}
                        title={ranking.title}
                        position={ranking.position}
                    />
                ))}
                {rankings.length === 0 && <RankingItemEmpty />}
            </dl>
            <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                <Link
                    to="/2024#ranking"
                    target="_blank"
                    className="text-sm font-semibold leading-6 text-gray-900"
                >
                    Ver Relatório 2024 <span aria-hidden="true">&rarr;</span>
                </Link>
            </div>
        </div>
    );
};

export default function CompanyAbout() {
    const company = currentCompany.value;
    const [isLoading, setIsLoading] = useState(false);
    const [mauData, setMauData] = useState(null);
    const [downloadsData, setDownloadsData] = useState(null);
    const { appId } = useParams();
    const { companyId, slug } = company;

    useEffect(() => {
        const loadReviews = async () => {
            if (!companyId) return;
            setIsLoading(true);
            try {
                const mau = await AppService.fetchMAU({ companyId });
                const downloads = await AppService.fetchDownloads({
                    companyId
                });
                setMauData(mau);
                setDownloadsData(downloads);
            } catch (error) {
                console.error('Failed to load company details:', error);
            } finally {
                setIsLoading(false);
            }
        };
        loadReviews();
    }, [companyId, appId]);

    if (isLoading || !mauData || !downloadsData) {
        return <Company.StateLoading />;
    }

    return (
        <>
            <Layout.Head title={`Index - ${company.name}: Visão Geral`} />
            <main>
                <div className="mx-auto max-w-7xl py-8 px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        <div className="lg:col-span-2 space-y-8">
                            {company.description ? (
                                <div className="sm:pr-4 shadow-sm ring-1 ring-gray-900/5 sm:mx-0 sm:rounded-lg p-8">
                                    <p className="text-sm">
                                        {company.description}
                                    </p>
                                </div>
                            ) : null}
                            {mauData.length && mauData[0].mau.length > 0 ? (
                                <CardMAU data={mauData} />
                            ) : (
                                <CardMAUEmpty />
                            )}
                            {downloadsData.length > 0 ? (
                                <CardDownloads data={downloadsData} />
                            ) : (
                                <CardDownloadsEmpty />
                            )}
                        </div>
                        <div className="lg:col-start-3 space-y-8">
                            {company.verified && (
                                <CardRanking
                                    companyName={company.name}
                                    companyId={companyId}
                                />
                            )}
                            {company.apps.length > 0 && (
                                <div>
                                    <CardReviews
                                        slug={slug}
                                        apps={company.apps}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
