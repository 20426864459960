import logo from '../../svg/logo-color.svg';

const stats = [
    { label: 'Fundada em', value: '2016' },
    { label: 'Clientes', value: '+500' },
    { label: 'Fraudes evitadas', value: '12M' },
    { label: 'Captados', value: 'R$300M' }
];

const BackgroundEffect2 = () => (
    <>
        <div className="absolute inset-0 bg-gray-900 mix-blend-multiply" />
        <div className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl" aria-hidden="true">
            <div
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#00CEFF] to-[#5500FF] opacity-40"
                style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
                }}
            />
        </div>
    </>
);

export default function ReportEnd() {
    return (
        <div className="bg-white py-10 sm:py-12">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-4">
                        <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                            <BackgroundEffect2 />
                            <figure className="relative isolate">
                                <div className="mt-6 text-xl font-semibold leading-8 text-white">
                                    <h1 className="text-3xl font-semibold text-white sm:text-4xl">ISO 27001</h1>
                                    <p className="mt-10">
                                        Amet amet eget scelerisque tellus sit neque faucibus non eleifend. Integer eu praesent at a. Ornare arcu gravida natoque
                                        erat et cursus tortor.
                                    </p>
                                </div>
                            </figure>
                        </div>
                    </div>
                    <div>
                        <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                            <div className="mb-6 flex gap-4 items-center">
                                <img className="h-20 w-auto" src={logo} alt="idwall" />
                            </div>
                            <h1 className="mt-10 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">Revolucionando a confiança</h1>
                            <div className="max-w-xl">
                                <p className="mt-6">
                                    Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id.
                                    Id dolor praesent donec est.
                                </p>
                            </div>
                        </div>
                        <dl className="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4">
                            {stats.map((stat, statIdx) => (
                                <div key={statIdx}>
                                    <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.label}</dt>
                                    <dd className="mt-2 text-3xl font-semibold leading-10 tracking-tight text-gray-900">{stat.value}</dd>
                                </div>
                            ))}
                        </dl>
                        <div className="mt-10 flex">
                            <a
                                href="https://idwall.co/"
                                className="text-base font-semibold leading-7 text-idwallDarkPurple-600"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Conheça a nossa plataforma <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
