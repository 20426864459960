import { getChartColors, chartTheme, formatNumbers } from '../../styles';
import { ResponsiveBar } from '@nivo/bar';

const chartColors = getChartColors(3);

const processData = (data) => {
    const monthNames = [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro'
    ];

    // Agrupar por data e dispositivo
    const groupedData = {};
    data.forEach((app) => {
        app.mau.forEach((mauEntry) => {
            const date = new Date(mauEntry.date);
            const monthYear = `${
                monthNames[date.getMonth()]
            } de ${date.getFullYear()}`;
            const device = mauEntry.device;

            if (!groupedData[monthYear]) {
                groupedData[monthYear] = {};
            }
            if (!groupedData[monthYear][device]) {
                groupedData[monthYear][device] = 0;
            }
            groupedData[monthYear][device] += parseInt(mauEntry.total, 10);
        });
    });

    // Converter para array de objetos
    const objectsArray = Object.keys(groupedData).map((date) => ({
        date,
        ...groupedData[date]
    }));

    // Alterar nome das propriedades
    objectsArray.forEach((item) => {
        if (item.android) {
            item.Android = item.android;
            delete item.android;
        }
        if (item.iphone) {
            item.iPhone = item.iphone;
            delete item.iphone;
        }
        if (item.ipad) {
            item.iPad = item.ipad;
            delete item.ipad;
        }
    });

    return objectsArray;
};

export default function ChartMAU({ data }) {
    const chartData = processData(data);

    return (
        <ResponsiveBar
            data={chartData}
            theme={chartTheme}
            labelTextColor="white"
            keys={['Android', 'iPhone', 'iPad']}
            indexBy="date"
            margin={{ top: 30, right: 30, bottom: 100, left: 70 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={chartColors}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            valueFormat={(value) => `${value.toLocaleString('pt-BR')}`}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Usuários',
                legendOffset: -50,
                legendPosition: 'middle',
                format: formatNumbers
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 70,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );
}
