import { primaryColor } from '../../../styles';
import { TagCloud } from 'react-tagcloud';

const colorOptions = {
    luminosity: 'random',
    hue: primaryColor
};

const customRenderer = (tag, size, color) => {
    return (
        <span
            key={tag.value}
            style={{ color, fontSize: size }}
            className="inline-block px-3 align-middle font-semibold cursor-pointer"
        >
            {tag.value}
        </span>
    );
};

export default function WordCloud({ data }) {
    return (
        <TagCloud
            minSize={10}
            maxSize={60}
            colorOptions={colorOptions}
            tags={data}
            renderer={customRenderer}
        />
    );
}
