import { useState, useEffect } from 'react';
import { FunnelIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../helpers';
import { BadgeRanking } from '../../components';
import { settings } from '../../signals';
import { signal } from '@preact/signals-react';
import { CompanyService } from '../../services';

const category = signal([]);

const loadCompanyTypes = async () => {
    try {
        const types = await CompanyService.fetchCompanyTypes();
        category.value = types.map((type) => ({
            value: type,
            label: type
        }));
    } catch (error) {}
};

loadCompanyTypes();

export default function CompaniesListFilter({
    onChangeType,
    onChangeName,
    onChangeVerified
}) {
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const showVerifiedInput = document.getElementById('showVerified');
    const showNotVerifiedInput = document.getElementById('showNotVerified');

    const handleFilterChange = (filterValue) => {
        setSelectedFilters((currentFilters) =>
            currentFilters.includes(filterValue)
                ? currentFilters.filter((filter) => filter !== filterValue)
                : [...currentFilters, filterValue]
        );
    };

    const getVerified = () => {
        if (!showVerifiedInput || !showNotVerifiedInput) {
            return null;
        }

        const showVerified = showVerifiedInput.checked;
        const showNotVerified = showNotVerifiedInput.checked;

        if (showVerified && !showNotVerified) {
            return true;
        }

        if (!showVerified && showNotVerified) {
            return false;
        }

        return null;
    };

    const handleVerifiedChange = () => {
        onChangeVerified(getVerified());
    };

    useEffect(() => {
        onChangeType(selectedFilters);
    }, [selectedFilters, onChangeType]);

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    };

    const handleCompanyNameSubmit = () => {
        onChangeName(companyName);
    };

    const setCompaniesListFilter = (value) => {
        settings.value = {
            ...settings.value,
            showCompaniesListFilter: value
        };
    };

    const handleClearFilters = () => {
        setSelectedFilters([]);
        setCompanyName('');
        onChangeType([]);
        onChangeName('');
        onChangeVerified(null);
        showVerifiedInput.checked = false;
        showNotVerifiedInput.checked = false;
        setCompaniesListFilter(false);
    };

    const filtersCount =
        selectedFilters.length +
        (companyName.length >= 1 ? 1 : 0) +
        (getVerified() !== null ? 1 : 0);

    // Split categoria in 2
    const categoryA = category.value.slice(
        0,
        Math.ceil(category.value.length / 2)
    );
    const categoryB = category.value.slice(
        Math.ceil(category.value.length / 2),
        category.value.length
    );

    return (
        <div className="bg-white">
            <section
                aria-labelledby="filter-heading"
                className="grid items-center border-b border-t border-gray-200"
            >
                <div className="relative col-start-1 row-start-1 py-4">
                    <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
                        <div>
                            <button
                                className="group flex items-center font-medium text-gray-700"
                                onClick={() =>
                                    setCompaniesListFilter(
                                        !settings.value.showCompaniesListFilter
                                    )
                                }
                            >
                                <FunnelIcon
                                    className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                                Filtros
                                {filtersCount > 0 && (
                                    <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                                        {filtersCount}
                                    </span>
                                )}
                            </button>
                        </div>
                        {filtersCount > 0 && (
                            <div className="pl-6">
                                <button
                                    type="button"
                                    onClick={handleClearFilters}
                                    className="text-gray-500"
                                >
                                    Limpar filtros
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={classNames(
                        settings.value.showCompaniesListFilter
                            ? 'block'
                            : 'hidden',
                        'border-t border-gray-200 py-10 bg-gray-100'
                    )}
                >
                    <div className="mx-auto grid max-w-7xl grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
                        <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                            <fieldset>
                                <legend className="block font-medium">
                                    Nome
                                </legend>
                                <div className="mt-4">
                                    <label
                                        htmlFor="company-name"
                                        className="sr-only"
                                    >
                                        Nome da Empresa
                                    </label>
                                    <input
                                        id="company-name"
                                        type="text"
                                        name="companyName"
                                        value={companyName}
                                        onChange={handleCompanyNameChange}
                                        onBlur={handleCompanyNameSubmit}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                handleCompanyNameSubmit();
                                            }
                                        }}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-idwallDarkPurple-600 sm:text-sm sm:leading-6"
                                        placeholder="Digite o nome da empresa"
                                    />
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend className="block font-medium">
                                    Categoria
                                </legend>
                                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                    {categoryA.map((option, optionIdx) => (
                                        <div
                                            key={option.value}
                                            className="flex items-center text-base sm:text-sm"
                                        >
                                            <input
                                                id={`categoryA-${optionIdx}`}
                                                name="category[]"
                                                value={option.value}
                                                onChange={() =>
                                                    handleFilterChange(
                                                        option.value
                                                    )
                                                }
                                                type="checkbox"
                                                className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-idwallDarkPurple-600 focus:ring-idwallDarkPurple-500"
                                                defaultChecked={option.checked}
                                            />
                                            <label
                                                htmlFor={`categoryA-${optionIdx}`}
                                                className="ml-3 min-w-0 flex-1 text-gray-600"
                                            >
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        </div>
                        <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-6">
                            <fieldset>
                                <legend className="block font-medium">
                                    &nbsp;
                                </legend>
                                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                    {categoryB.map((option, optionIdx) => (
                                        <div
                                            key={option.value}
                                            className="flex items-center text-base sm:text-sm"
                                        >
                                            <input
                                                id={`categoryB-${optionIdx}`}
                                                name="category[]"
                                                value={option.value}
                                                onChange={() =>
                                                    handleFilterChange(
                                                        option.value
                                                    )
                                                }
                                                type="checkbox"
                                                className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-idwallDarkPurple-600 focus:ring-idwallDarkPurple-500"
                                                defaultChecked={option.checked}
                                            />
                                            <label
                                                htmlFor={`categoryB-${optionIdx}`}
                                                className="ml-3 min-w-0 flex-1 text-gray-600"
                                            >
                                                {option.label}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                            <fieldset>
                                <legend className="block font-medium">
                                    Participantes do Ranking 2024
                                </legend>
                                <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                                    <div className="flex items-center text-base sm:text-sm">
                                        <input
                                            id="showVerified"
                                            name="showVerified"
                                            onChange={handleVerifiedChange}
                                            type="checkbox"
                                            className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-idwallDarkPurple-600 focus:ring-idwallDarkPurple-500"
                                        />
                                        <label
                                            htmlFor="showVerified"
                                            className="ml-3 min-w-0 flex-1 text-gray-600"
                                        >
                                            Sim{' '}
                                            <BadgeRanking
                                                size={5}
                                                className="inline-block ml-0"
                                            />
                                        </label>
                                    </div>
                                    <div className="flex items-center text-base sm:text-sm">
                                        <input
                                            id="showNotVerified"
                                            name="showNotVerified"
                                            onChange={handleVerifiedChange}
                                            type="checkbox"
                                            className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-idwallDarkPurple-600 focus:ring-idwallDarkPurple-500"
                                        />
                                        <label
                                            htmlFor="showNotVerified"
                                            className="ml-3 min-w-0 flex-1 text-gray-600"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
