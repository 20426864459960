import { signal } from '@preact/signals-react';
import { AdminService } from '../../services';
import Form from './Form';
import UserFormData from './UserFormData';
import { useEffect, useCallback } from 'react';

const user = signal({});
const companies = signal([]);

export default function AdminUserCreate() {
    const fetchData = useCallback(async () => {
        try {
            companies.value = await AdminService.getCompanies();
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const createUser = async (data) => {
        try {
            await AdminService.createUser(data);
            window.location.href = '/admin/users';
        } catch (error) {
            console.error(error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        createUser(user.value);
    };

    const form = UserFormData({ user: user.value, companies: companies.value });

    form.push({
        label: 'Password',
        name: 'password',
        value: user.value.password || ''
    });

    return <Form fields={form} handleSubmit={handleSubmit} signal={user} />;
}
